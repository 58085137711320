// 余料数据类型
export interface ISurplusType {
  amount: string
  area: number
  branch_name: string
  branch_no: string
  color: string
  create_time: string
  ggid: string
  id: number
  isSelected: boolean
  is_high_gloss_plank: boolean
  long: number
  min_long: number
  min_width: number
  name: string
  remark: string
  shape: IsShape
  showAmount: string | number
  spec: string
  thick: string
  type: string
  uid: number
  update_time: string
  user_num: string
  width: number
}

// 是否是异形
export enum IsShape {
  IS_SHAPE = 1,
  NOT_SHAPE = 0,
}
