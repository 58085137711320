<template>
  <div
    class="save-paiban-page"
    v-loading="isLoading"
    :element-loading-text="loadingText"
  >
    <div class="wrap">
      <div class="filter-box">
        <div class="left-btns">
          <a-button
            class="delete-btn"
            :disabled="selectionArr.length == 0"
            @click="openDeleteHistoryModal()"
            id="history_batch_delete_btn"
          >
            <span class="iconfont icon-delete"></span>
            <span>{{ $t('common.delete') }}</span>
          </a-button>
          <a-button
            :class="['down-btn', selectionArr.length == 0 ? 'disable-btn' : '']"
            :disabled="selectionArr.length == 0"
            @click="downloadMore"
            id="history_batch_download_btn"
          >
            <span class="iconfont icon-download"></span>
            <span
              class="ellipsis"
              :title="translateLang('historyPage.download')"
              >{{ $t('historyPage.download') }}</span
            >
          </a-button>
          <span class="fs14 ml9 pt10" style="color: red">{{
            $t('materialPage.save.subTitle')
          }}</span>
        </div>
        <div
          class="right-filter w0 flex-box--1 flex flex-main--right flex-cross--center flex-wrap--wrap"
        >
          <a-select
            v-model="autoSave"
            showArrow
            @change="handleAutoSaveChange"
            id="history_autoSave_select"
          >
            <a-select-option
              v-for="item in autoSaveList"
              :key="item.id"
              :value="item.value"
              :id="`history_autoSave_option_${item.value}`"
              >{{ translateLang(item.label) }}</a-select-option
            >
          </a-select>
          <!-- <a-select
            v-model="historyType"
            @change="filterList"
            showArrow
            id="history_type_select"
          >
            <a-select-option value="all" :id="`history_type_all_item`">{{
              $t('historyPage.allArrange')
            }}</a-select-option>
            <a-select-option value="paiban" :id="`history_type_paiban_item`">{{
              $t('taskPage.filter.arranged')
            }}</a-select-option>
            <a-select-option
              value="material"
              :id="`history_type_material_item`"
              >{{ $t('taskPage.filter.unArrange') }}</a-select-option
            >
          </a-select> -->
          <m-cascader
            :value="historyType"
            :options="filterOptions"
            @onChange="onFilterChange"
          ></m-cascader>
          <el-date-picker
            v-model="filterTime"
            type="daterange"
            align="right"
            unlink-panels
            :range-separator="translateLang('common.to')"
            :start-placeholder="translateLang('historyPage.startDate')"
            :end-placeholder="translateLang('historyPage.endDate')"
            :picker-options="pickerOptions"
            prefix-icon="iconfont icon-time"
            value-format="yyyy-MM-dd"
            @change="chnageFilterTime"
          >
          </el-date-picker>
          <div class="search-box">
            <a-input-search
              :placeholder="translateLang('historyPage.searchPlaceholder')"
              v-model="searchWord"
              id="history_search_input"
              @search="searchHistory"
            >
              <a-button
                icon="search"
                slot="enterButton"
                id="history_search_btn"
                :title="translateLang('common.check')"
                class="flex flex-cross--center"
              >
                <span class="w100 ellipsis block">{{
                  $t('common.check')
                }}</span>
              </a-button>
            </a-input-search>
          </div>
        </div>
      </div>
      <div class="main-table">
        <!-- 试生产 -->
        <MTryProduction :trailProductState="trailProductState" />
        <div
          v-for="(item, index) in historyList"
          :key="index"
          class="history-item"
        >
          <div class="left-info">
            <a-checkbox
              v-model="item.isSelected"
              :id="`history_list_item_${index}_checkbox`"
              @change="handleJudgeCancelAllSelect"
            ></a-checkbox>
            <div v-if="item.process_setting_id != -1" class="used-rate">
              <!-- <span
                >{{
                  item.plank_info.used_rate >= 1
                    ? (100).toFixed(2)
                    : (item.plank_info.used_rate * 100).toFixed(2)
                }}%</span
              > -->
            </div>
            <div class="plank-info">
              <div class="plank-basic-info">
                <div class="flex flex-cross--center">
                  <a-tooltip placement="top">
                    <template #title>
                      <span>{{ item.name }}</span>
                    </template>
                    <span
                      class="history-name"
                      @dblclick="handleDblclickName(item)"
                      :id="`history_list_item_${index}_history_name`"
                      >{{ item.name }}</span
                    >
                  </a-tooltip>

                  <span
                    class="line-name ml10"
                    :class="{
                      'pre-layout':
                        item.plank_info.process_setting_id &&
                        item.plank_info.process_setting_id == -2,
                    }"
                    v-if="item.process_setting_id != -1"
                    >{{ dealLineName(item) }}</span
                  >
                  <span class="save-status ml10" v-if="!item.auto_save">{{
                    $t('historyPage.handleSave')
                  }}</span>
                </div>
                <span class="save-time"
                  >{{ $t('taskPage.record.saveDate') }}:
                  {{ item.create_time }}</span
                >
              </div>
              <div class="plank-info-list" v-if="item.hasPlankList">
                <div
                  v-for="(plank, indexs) in item.plank_info.plankShowList"
                  :key="indexs"
                  class="plank-item"
                >
                  <span class="plank-thick-matcode"
                    >{{ plank.thick }}mm{{ plank.matCode }}</span
                  >
                  <span class="plank-num-texture"
                    >{{ plank.num }}{{ $t('common.plankUnit') }}
                    {{ plank.texture }}</span
                  >
                </div>
                <div
                  class="show-all-plank"
                  v-show="
                    !item.showMore && item.plank_info.plankMoreList.length > 0
                  "
                  @click="item.showMore = true"
                >
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
                </div>
                <div
                  v-for="(plank, index) in item.plank_info.plankMoreList"
                  :key="'more-' + index"
                  class="plank-item"
                  v-show="item.showMore"
                >
                  <span class="plank-thick-matcode"
                    >{{ plank.thick }}mm{{ plank.matCode }}</span
                  >
                  <span class="plank-num-texture"
                    >{{ plank.num }}{{ $t('common.plankUnit') }}
                    {{ plank.texture }}</span
                  >
                </div>
              </div>
              <div
                v-if="
                  item.process_setting_id != -1 && !!item.plank_info.used_rate
                "
                class="used-rate fs14 color-9"
              >
                <div>{{ $t('common.rate') }}</div>
                <div class="use-rate-info fs16 color-3">
                  {{
                    item.plank_info.used_rate >= 1
                      ? (100).toFixed(2)
                      : (item.plank_info.used_rate * 100).toFixed(2)
                  }}%
                </div>
              </div>
              <div
                v-if="item.process_setting_id != -1 && !!item.layout_batch"
                class="used-rate fs14 color-9"
              >
                <div>{{ $t('common.paibanBatch') }}</div>
                <div class="use-rate-info fs16 color-3">
                  {{ item.layout_batch }}
                </div>
              </div>
            </div>
          </div>
          <div class="right-operation">
            <span
              class="mb10"
              @click="gotoMaterialList(item)"
              :id="`history_${index}_materiallist_btn`"
              :title="translateLang('main.sideBar.materialList')"
              >{{ $t('main.sideBar.materialList') }}</span
            >
            <img
              v-if="item.save_node == 3"
              :src="tagStatus"
              alt="已打印标签"
              class="tag-status"
            />
            <template>
              <span
                class="mb10"
                :id="`history_${index}_detail_btn`"
                :class="
                  item.file_type == 'material' || !item.plank_info.used_rate
                    ? 'disabled-btn'
                    : ''
                "
                @click="
                  !(
                    item.file_type == 'material' || !item.plank_info.used_rate
                  ) && gotoPaiban(item)
                "
                :title="translateLang('main.sideBar.paibanResult')"
                >{{ $t('main.sideBar.paibanResult') }}
              </span>
              <span
                class="mb10"
                :id="`history_${index}_download_btn`"
                @click="downloadData(item)"
                :title="translateLang('historyPage.download')"
                >{{ $t('historyPage.download') }}</span
              >
              <!-- <span
                class="mb10"
                id="history_printTag_btn"
                :class="item.save_node < 3 ? 'disabled-btn' : ''"
                @click="item.save_node == 3 && handleGotoTagLabel(item)"
                >打印标签</span
              > -->
            </template>
            <span
              class="mb10"
              :id="`history_${index}_rename_btn`"
              @click="handleDblclickName(item)"
              :title="translateLang('common.reName')"
              >{{ $t('common.reName') }}</span
            >
            <span
              class="mb10"
              :id="`history_${index}_delete_btn`"
              @click="openDeleteHistoryModal(item, index)"
              :title="translateLang('common.delete')"
              >{{ $t('common.delete') }}</span
            >
            <span class="mb10"></span>
          </div>
        </div>
      </div>
      <div class="page-toggle">
        <!-- 全选功能 -->
        <div class="all-select">
          <a-checkbox @change="handleSelectAll" v-model="allSelected">{{
            $t('materialPage.filter.all')
          }}</a-checkbox>
        </div>
        <div>
          <a-pagination
            v-model="currentPage"
            :page-size-options="pageSizeOptions"
            :total="totalCount"
            show-size-changer
            :page-size="pageSize"
            @showSizeChange="onShowSizeChange"
            @change="changeCurrentPage"
            show-quick-jumper
            :show-total="
              (totalCount) =>
                $t('common.paginationTotalTip', {
                  current: (currentPage - 1) * pageSize + 1,
                  page: currentPage * pageSize,
                  total: totalCount,
                })
            "
          >
            <template slot="buildOptionText" slot-scope="props">
              <span
                v-if="props.value != totalCount"
                :id="`history_change_size_item_${pageSizeOptions.indexOf(
                  props.value
                )}`"
                >{{ props.value }}{{ $t('common.strip') }}/{{
                  $t('common.page')
                }}</span
              >
              <span v-if="props.value == totalCount">{{
                $t('common.all')
              }}</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </div>
    <!-- <a-modal title="温馨提示" ok-text="确认删除" cancel-text="取消" centered>
      <p>是否确认删除以下记录？</p>
      
    </a-modal> -->
    <g-base-modal
      :visible="isVisible"
      v-if="isVisible"
      :title="$t('common.warmTip')"
      :cancelText="$t('common.cancel')"
      :okText="$t('common.confirmDelete')"
      :contain="translateLang('historyPage.deleteTip')"
      @ok="deleteHistory(needDeleteOfIds)"
      @cancel="isVisible = false"
    >
      <p v-for="(item, idx) in needDeleteNames" :key="idx">{{ item }}</p>
    </g-base-modal>
    <!-- 重命名弹框 -->
    <a-modal
      :title="translateLang('common.reName')"
      centered
      class="rename-modal"
      :width="480"
      :maskClosable="false"
      :visible="isShowRename"
      @ok="handleOkRename"
      @cancel="isShowRename = false"
    >
      <div class="flex flex-cross--center">
        <label
          for="renameInput"
          class="fs14 color-o-9 align-right"
          style="width: 107px; margin-right: 24px"
          >{{ $t('materialPage.save.name') }}</label
        >
        <!-- 失去焦点的时候判断输入内容是否全部为空格 -->
        <a-input
          id="renameInput"
          v-model="renameValue"
          :placeholder="translateLang('arrangedPage.historyNameErr')"
          :maxLength="100"
        />
      </div>
      <template #closeIcon>
        <a-icon id="history_rename_close_btn" type="close" />
      </template>
      <template #cancelText>
        <span id="history_rename_cancel_btn">{{ $t('common.cancel') }}</span>
      </template>
      <template #okText>
        <span id="history_rename_confirm_btn">{{ $t('common.confirm') }}</span>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { getProductionNcSetting } from '@/apis/equipment'
import {
  getPreferencesSetting,
  savePreferencesSetting,
} from '@/apis/materialList'
import { getMaterialData, getProductionLineSetting } from '@/apis/paibanHistory'
import { getPrelayoutSetting } from '@/apis/preLayoutSetting'
import { httpRequest } from '@/apis/request'
import tagStatus from '@/assets/tag_status.png'
import GBaseModal from '@/components/g-base-modal.vue'
import { EventBusKey } from '@/util/bus'
import { translate } from '@/util/commonFun'
import {
  buryPointApi,
  checkPlankDataIsCorrect,
  generateSimplePlankNum,
} from '@/util/commonFuncs'
import {
  dealPlankListLabelID,
  dealPreLayoutDataLabelID,
} from '@/util/dealPaibanData'
import JsZip, { file } from '@/util/jszip'
import { clearObjAttr, genPartUniqueId } from '@/util/plankCommonFuncs'
import axios from 'axios'
import FileSaver from 'file-saver'
import { mapMutations, mapState } from 'vuex'

import { editHistoryName } from '../../apis/paiban/index'
import { dealPreLayoutSetting } from '../materialsList/util/preLayoutDeal'
import { dealStandardPlank } from '../newPaiban/util/layoutDataDeal'
import MCascader from './components/m-cascader/index.vue'
import MTryProduction from './components/m-try-production'

export default {
  components: { MTryProduction, GBaseModal, MCascader },
  data() {
    return {
      allSelected: false,
      autoSave: '2',
      tagStatus,
      // 偏好设置
      preferencesSetting: {},
      pickerOptions: {
        shortcuts: [
          {
            text: this.$t('historyPage.lastWeek'),
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$t('historyPage.lastMonth'),
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$t('historyPage.lastSeason'),
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      filterTime: '',
      searchWord: '',
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      currentPage: 1,
      pageSize: 10,
      totalCount: 50,
      historyList: [],
      produceLineList: [],
      // 记录筛选的历史状态
      historyType: ['all'],
      isVisible: false, // 开启删除弹窗
      needDeleteOfIds: [], // 保存需要删除的ID
      needDeleteNames: [], // 保存需要删除的name
      // 是否正在加载
      isLoading: false,
      loadingText: `${translate('lineManager.lineLoading')}...`,
      isShowRename: false,
      renameValue: '',
      renameItem: {},
      autoSaveList: [
        {
          label: 'historyPage.allType',
          value: '2',
          id: 'history_autoSave_select_option_2',
        },
        {
          label: 'historyPage.handleSave',
          value: '0',
          id: 'history_autoSave_select_option_0',
        },
        {
          label: 'historyPage.autoSave',
          value: '1',
          id: 'history_autoSave_select_option_1',
        },
      ],
      trailProductState: 0,
      // 筛选条件
      filterOptions: [
        {
          value: 'all',
          label: '全部',
          key: 'common.all',
        },
        {
          value: 'material',
          label: '未排版',
          key: 'taskPage.filter.unArrange',
        },
        {
          value: 'paiban',
          label: '已排版',
          key: 'taskPage.filter.arranged',
          children: [
            {
              value: 'paiban',
              label: '全部已排版',
              key: 'taskPage.filter.allArranged',
            },
            { value: -2, label: '预排版', key: 'taskPage.filter.preLayout' },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState([
      'preLayoutData',
      'ncSetting',
      'sampleTableData',
      'isLinkLogin',
      'userInfo',
    ]),
    selectionArr() {
      let arr = []
      for (let i = 0; i < this.historyList.length; ++i) {
        if (this.historyList[i].isSelected) {
          arr.push({
            url: this.historyList[i].plank_info.historyUrl,
            name: this.historyList[i].name,
          })
        }
      }
      return arr
    },
  },
  methods: {
    ...mapMutations([
      'changeHistoryStatus',
      'setNcSetting',
      'setPreLayoutData',
      'setPaibanData',
      'setOrderInfo',
      'setFinalDrawData',
      'setRecodeSelectPart',
      'setRecodeSelectSurplus',
      'setThinkerxMaterialKeys',
      'setPaibanInfo',
      'setPaibanKey',
      'setSampleTableData',
      'setIsShowSample',
      'setMaterialList',
      'setFilterMaterialList',
      'setFilterObject',
      'setBeforeSearchMaterialList',
      'setBeforeSizeFilterList',
      'setIsTrialProduct',
      'setHistoryOrder',
      'setHasPrintTag',
      'setIsSubPrintTag',
      'setHasSendToTask',
      'setHistoryPlankEdgeOff',
      'setHistorySecondTrimValue',
      'setIsNewPaiban',
      'setPreLayoutSetting',
      'setOriLayoutSetting',
      'setCustomPlankOrder',
      'setPlankOrderList',
    ]),
    translateLang(key) {
      return translate(key)
    },
    dealLineName(lineItem) {
      const id = lineItem.plank_info.process_setting_id
      if (id) {
        if (id == -2) {
          return '预排版'
        } else {
          const lineName = this.line(id)
          return lineName ? lineName : lineItem.plank_info.process_setting_name
        }
      } else {
        const lineName = this.line(lineItem.process_setting_id)

        return lineName ? lineName : lineItem.plank_info.process_setting_name
      }
    },
    chnageFilterTime(val) {
      this.getHistoryList(1)
    },
    // 搜索
    searchHistory() {
      this.getHistoryList(1)
    },
    // 筛选清单和排版数据
    filterList() {
      this.getHistoryList()
    },
    async getHistoryList(page = 1, fileType = '') {
      // 请求接口携带的部分参数
      let obj = {
        page: page,
        limit: this.pageSize,
        auto_save: Number(this.autoSave),
        plate_from: 'bluen',
        // generate_batch: true,
      }
      // 每次切换都会回到第一页，参数也是请求的第一页
      this.$nextTick(() => {
        this.currentPage = page
      })
      if (this.searchWord != '') {
        obj.search = this.searchWord
      }
      if (this.filterTime) {
        obj.start = this.filterTime[0]
        // 获取日期的后一天
        let endDate = new Date(
          new Date(this.filterTime[1]).getTime() + 24 * 60 * 60 * 1000
        )
          .toLocaleDateString()
          .replace(/\//g, '-')
        let dateList = endDate.split('-').map((item) => {
          if (item.length < 2) {
            // 在前面补0
            item = '0' + item
          }
          return item
        })
        obj.end = dateList.join('-')
      }
      const hisType = this.historyType[0]
      if (hisType != 'all') {
        obj.file_type = hisType
      }
      if (
        this.historyType.length > 1 &&
        this.historyType.slice(-1)[0] !== 'paiban'
      ) {
        obj.process_setting_id = this.historyType.slice(-1)[0]
      }
      // 请求接口获取数据
      const res = await getMaterialData(obj)
      let data
      if (res.status == 1) {
        data = res.data.material_list
        for (let i = 0; i < data.length; ++i) {
          data[i].isSelected = false
          if (data[i].plank_info.plankTypeList) {
            if (
              data[i].plank_info.plankTypeList != null ||
              data[i].plank_info.plankTypeList.length > 0
            ) {
              // 默认展示三个大板信息
              data[i].plank_info.plankShowList = data[
                i
              ].plank_info.plankTypeList.slice(0, 3)
              data[i].plank_info.plankMoreList =
                data[i].plank_info.plankTypeList.slice(3)
              data[i].showMore = false
              data[i].hasPlankList = true
            } else {
              data[i].hasPlankList = false
            }
          } else {
            data[i].hasPlankList = false
          }
        }
        // 对url编码，防止出现特殊字符服务器处理错误
        data.forEach((item) => {
          let url = item.plank_info.historyUrl
          url = url.replace(
            /(http|https):\/\/eggrj.oss-cn-hangzhou.aliyuncs.com\//,
            ''
          )
          item.plank_info.historyUrl = `https://eggrj.oss-cn-hangzhou.aliyuncs.com/${encodeURIComponent(
            url
          )}`
        })
        this.totalCount = res.data.total
      }
      // 页面上渲染的数据赋值
      this.historyList = JSON.parse(JSON.stringify(data))
        .filter((item) => !item.name.includes('批量生产_WebBluen'))
        .map((item) => {
          if (item.name.includes('FromWebPage')) {
            const nameList = item.name.split('_')
            const nameListLen = nameList.length
            const dealName = nameList.slice(0, nameListLen - 2).join('_')
            item.name = dealName
          }
          if (item.name.includes('WebBluen')) {
            const nameList = item.name.split('_')
            const nameListLen = nameList.length
            const dealName = nameList.slice(0, nameListLen - 2).join('_')
            item.name = dealName
          }
          return item
        })
      this.isLoading = false
    },
    line(id) {
      let name = ''
      if (id != -1) {
        for (let i = 0; i < this.produceLineList.length; ++i) {
          if (id == this.produceLineList[i].id) {
            name = this.produceLineList[i].name
            break
          }
        }
      }
      return name
    },
    async getProduceLine() {
      this.isLoading = true
      const res = await getProductionLineSetting()
      if (res.status == 1) {
        this.produceLineList = res.data
        this.$store.commit('setProLineList', res.data)
        this.filterOptions.forEach((item) => {
          if (item.value == 'paiban') {
            item.children = item.children.concat(
              res.data.map((item) => {
                return {
                  label: item.name,
                  value: item.id,
                }
              })
            )
          }
        })
      } else if (res.result) {
        this.$message({
          type: 'info',
          message: '身份信息已过期, 请重新从柜柜打开此页面!',
          duration: 1500,
          offset: 70,
        })
        this.isLoading = true
      }
    },
    onShowSizeChange(val, pageSize) {
      this.pageSize = pageSize
      this.getHistoryList(1)
    },
    // 切换当前所在页的时候触发
    changeCurrentPage(val, pageSize) {
      this.getHistoryList(val)
    },
    // 前往料单前的统一处理
    goToBeforeMaterialDeal(data, item) {
      data.sampleFormData
        ? this.setIsShowSample(true)
        : this.setIsShowSample(false)

      // 已在排版页面的待排版库板件
      const participationPaibanPlanks = []
      // 已在料单页面的待排版库id
      const materialSelectIds = []
      data.paibanData.forEach((plank) => {
        checkPlankDataIsCorrect(plank)
        plank.parts.forEach((part) => {
          // 生成唯一id
          genPartUniqueId(part)
          generateSimplePlankNum(part)
          const layoutSamePart = data.layoutData.find(
            (item) =>
              item.plankID === part.plankID &&
              item.partName === part.partName &&
              item.partUniqueId === part.partUniqueId
          )
          part.srcTexDir = layoutSamePart?.texDir
          if (part._isAwaitStorePlank) {
            participationPaibanPlanks.push(part.partUniqueId)
          }
        })
      })

      data.layoutData.forEach((part) => {
        // 生成唯一id
        genPartUniqueId(part)
        part.srcTexDir = part.texDir
        if (part._isAwaitStorePlank && part._awaitStoreId) {
          materialSelectIds.push(part._awaitStoreId)
        }
      })
      this.setPreLayoutData(dealPreLayoutDataLabelID(data.layoutData))
      if (data.thinkerxMaterialKeys) {
        this.setThinkerxMaterialKeys(data.thinkerxMaterialKeys)
      }
      let orderInfo = {
        order_address: translate('common.history'),
        order_ids: item.order_list ? item.order_list.join(',') : '',
        order_codes: item.plank_info.order_codes,
        needPaibanRoomIds: item.room_ids ? item.room_ids : [],
        room_ids: [],
        batch: item.plank_info.batch,
      }
      this.setOrderInfo(orderInfo)
      const sampleData =
        data.sampleFormData ?? clearObjAttr(this.sampleTableData)
      this.setSampleTableData(sampleData)
      // 当是第三方直接进入，并且没有样表信息的不显示样表头
      if (this.isLinkLogin && !data.sampleFormData) {
        this.setIsShowSample(false)
      }
      // 跳转前清空远程缓存的待排版库数据，从保存历史拿到的本地数据是对应历史的待排版库数据
      this.$store.commit('awaitPaibanStore/historyClear')
      // 清除记录的待排版库到料单的id，使其可以正常匹配板件
      this.$store.commit('awaitPaibanStore/clearInMaterialPageIds')
      // 重置待排版库未入库提示
      this.$store.commit('awaitPaibanStore/setIsNoPromptPushStore', false)
      // 更新已在排版页的待排版库partuniqueId
      this.$store.commit(
        'awaitPaibanStore/setParticipationPaibanPlank',
        participationPaibanPlanks
      )
      // 更新已在排版页的待排版库Id
      this.$store.commit(
        'awaitPaibanStore/setMaterialSelectIds',
        materialSelectIds
      )
    },
    // 前往排版
    gotoPaiban(item) {
      // 如果没有进行过排版, 则不允许前往排版
      const settingName = this.dealLineName(item)
      this.$store.commit(
        'setRecodrProductionLineChoose',
        item.process_setting_id == -2
          ? this.$t('preLayoutSetting.preWayEmpty')
          : settingName
      )
      if (item.process_setting_id == -1) {
        this.$message({
          message: translate('historyPage.notArrangedTip'),
          type: 'error',
          duration: 1500,
          offset: 60,
        })
        return
      }
      this.isLoading = true
      this.loadingText = `${translate('historyPage.toArrangePage')}...`
      sessionStorage.setItem('isImportOrder', false)
      this.setFilterMaterialList(null)
      this.setBeforeSearchMaterialList(null)
      this.setBeforeSizeFilterList(null)
      this.setFilterObject(item.plank_info.filterObj)
      let orderInfo = {
        order_address: translate('common.history'),
        order_ids: item.order_list ? item.order_list.join(',') : '',
        order_codes: item.plank_info.order_codes,
        needPaibanRoomIds: item.room_ids ? item.room_ids : [],
        room_ids: [],
        batch: item.plank_info.batch,
      }
      this.setOrderInfo(orderInfo)
      // 清空收集的多排版方案
      this.$store.commit('paibanStore/setPaibanDataCollect', [])
      this.$store.commit('paibanStore/clearAlreadyDealDrawData')
      // 非试生产
      this.setIsTrialProduct(false)
      // 先获取生产线设置, 再进行跳转
      axios.get(item.plank_info.historyUrl).then(async (res) => {
        let params = {
          setting_id: item.process_setting_id,
        }
        if (window.sessionStorage.getItem('thinkerx_material')) {
          params.production_from = 'guimen'
          buryPointApi('lbl', 'layout_record')
        }
        if (res.data && res.data.paibanInfo) {
          this.setCustomPlankOrder(res.data.paibanInfo.customPlankOrder)
          this.setPlankOrderList(res.data.paibanInfo.plankOrderList)
        }

        if (
          res.data.filterPlankList &&
          !Array.isArray(res.data.filterPlankList[0])
        ) {
          const plankList = dealPlankListLabelID(res.data.filterPlankList)
          this.setFilterMaterialList(plankList)
          this.setBeforeSearchMaterialList(plankList)
          this.setBeforeSizeFilterList(plankList)
        }
        // 设置待排版库本地数据
        this.$store.commit(
          'awaitPaibanStore/reSetLocalPlankStore',
          res.data?.localPlankStore ?? []
        )
        let result = null
        if (item.plank_info.process_setting_id == -2) {
          try {
            // const newSetting = await getPrelayoutSetting()
            const preToSetting = dealPreLayoutSetting(
              res.data.preLayoutSetting.setting_value
            )
            result = { data: preToSetting, status: 1 }
            this.setOriLayoutSetting(res.data.preLayoutSetting)
            this.setPreLayoutSetting(res.data.preLayoutSetting)
            // this.setPreLayoutSetting(newSetting.data)
          } catch (error) {
            throw error
          }
        } else {
          const ncRes = await getProductionNcSetting(params)
          if (ncRes.status !== 1) {
            this.$message({
              message: translate('materialPage.noProductionLine'),
              type: 'error',
              duration: 3000,
              offset: 60,
            })
            this.setPreLayoutData(dealPreLayoutDataLabelID(res.data.layoutData))
            this.goToBeforeMaterialDeal(res.data, item)
            this.$router.push('/materialList')
          } else {
            result = ncRes
          }
        }
        if (result) {
          this.setHasSendToTask(false)
          if (res.data.paibanData) {
            const bigplank = res.data.paibanData.find(
              (data) => !data.surplusInfo
            )
            let isXYReverse = false
            if (item.process_setting_id == -2) {
              const preSetting = res.data.preLayoutSetting.setting_value
              isXYReverse = preSetting.countertopSetting.xyReverse
            } else {
              isXYReverse = result.data.xyReverse
            }
            // xy轴开启时，需要把所匹配的大板长宽对调
            let params =
              isXYReverse && bigplank
                ? {
                    ...bigplank,
                    plankWidth: bigplank.plankHeight,
                    plankHeight: bigplank.plankWidth,
                  }
                : bigplank
            let standardPlank = await dealStandardPlank(
              params,
              sessionStorage.getItem('thinkerx_material')
                ? { uid: this.userInfo.id, from: 'guimen' }
                : { uid: this.userInfo.id }
            )
            this.$store.commit('setSelectStandardPlank', {
              plankWidth: standardPlank.plankWidth,
              plankHeight: standardPlank.plankHeight,
              plankEdgeOff: standardPlank.plankEdgeOff,
              standardPlankId: standardPlank.standardPlankId,
            })
          }
          if (res.data.paibanInfo) {
            this.setHistoryPlankEdgeOff(res.data.paibanInfo.historyPlankEdgeOff)
            this.setHistorySecondTrimValue(
              res.data.paibanInfo.historySecondTrimValue
            )
            this.setFilterMaterialList(
              res.data.paibanInfo.filterPlankList ?? null
            )
          } else {
            this.setFilterMaterialList(null)
          }
          // 老板良采购单接口需要传
          this.setPaibanKey(res.data.paibanKey)
          // 预排版数据添加labelID,为分文件夹做准备
          this.setPreLayoutData(dealPreLayoutDataLabelID(res.data.layoutData))
          // 去开料清单前的处理
          this.goToBeforeMaterialDeal(res.data, item)
          // 保存历史记录时存的paibanInfo可以在这设置为后续的使用做准备
          this.setPaibanInfo(res.data.paibanInfo ?? {})
          let standardPlank = result.data.panelSize
          if (res.data.paibanData.length > 0) {
            this.setPaibanData(res.data.paibanData)
            this.setFinalDrawData([])
            const notOtherPlatePlank = res.data.paibanData.find(
              (item) => !item.otherPlate && !item.surplusInfo
            )
            if (notOtherPlatePlank) {
              standardPlank = notOtherPlatePlank
            }
          }
          result.data.drawPlankWidth = result.data.xyReverse
            ? standardPlank.plankHeight
            : standardPlank.plankWidth
          result.data.drawPlankHeight = result.data.xyReverse
            ? standardPlank.plankWidth
            : standardPlank.plankHeight
          if (res.data.paibanInfo?.historyPlankEdgeOff) {
            result.data.drawPlankEdgeOff =
              res.data.paibanInfo?.historyPlankEdgeOff
          }
          let obj = Object.assign(result.data, {
            process_setting_id: item.process_setting_id,
            process_setting_name: item.plank_info.process_setting_name,
            isPreLayout: item.process_setting_id == -2 ? true : undefined,
          })
          this.setNcSetting(obj)
          this.changeHistoryStatus(true)
          window.sessionStorage.setItem('isHistory', 1)
          this.$router.push({
            path: '/newPaiban',
            query: {
              from: 'history_paiabn',
              setting_id: item.process_setting_id,
            },
          })
        }
      })
      this.setHistoryOrder(item)
      this.setRecodeSelectPart({ selects: [], isHistory: true })
      this.setRecodeSelectSurplus({ selects: [], isHistory: true })
      if (item.save_node === 3) {
        this.setHasPrintTag(true)
      } else {
        this.setHasPrintTag(false)
      }
    },
    // 前往开料清单
    gotoMaterialList(item) {
      this.setHistoryOrder(item)
      this.isLoading = true
      this.setHasSendToTask(false)
      this.setIsNewPaiban(false)
      this.loadingText = `${translate('historyPage.toMaterialPage')}...`
      sessionStorage.setItem('isImportOrder', false)
      this.setMaterialList([])
      this.setFilterMaterialList([])
      this.setBeforeSearchMaterialList([])
      this.setBeforeSizeFilterList([])
      this.setFilterObject(item.plank_info.filterObj)
      // 非试生产
      this.setIsTrialProduct(false)
      axios.get(item.plank_info.historyUrl).then(async (res) => {
        let params = {
          setting_id: item.process_setting_id,
        }
        if (res.data && res.data.paibanInfo) {
          this.setCustomPlankOrder(res.data.paibanInfo.customPlankOrder)
          this.setPlankOrderList(res.data.paibanInfo.plankOrderList)
        }
        if (window.sessionStorage.getItem('thinkerx_material')) {
          params.production_from = 'guimen'
        }
        this.setFilterMaterialList(res.data.filterPlankList)
        this.setBeforeSearchMaterialList(res.data.filterPlankList)
        this.setBeforeSizeFilterList(res.data.filterPlankList)
        if (params.setting_id && params.setting_id !== -1) {
          const settingName = this.dealLineName(item)
          this.$store.commit(
            'setRecodrProductionLineChoose',
            item.process_setting_id == -2
              ? this.$t('preLayoutSetting.preWayEmpty')
              : settingName
          )
          let result
          if (params.setting_id == -2) {
            // const newSetting = await getPrelayoutSetting()
            const preToSetting = dealPreLayoutSetting(
              res.data.preLayoutSetting.setting_value
            )
            result = { data: preToSetting, status: 1 }
            this.setOriLayoutSetting(res.data.preLayoutSetting)
            this.setPreLayoutSetting(res.data.preLayoutSetting)
            // this.setPreLayoutSetting(newSetting.data)
          } else {
            result = await getProductionNcSetting(params)
          }

          if (!result || result.status !== 1) {
            this.$message({
              message: translate('materialPage.noProductionLine'),
              type: 'info',
              duration: 3000,
              offset: 60,
            })
            this.setPreLayoutData(dealPreLayoutDataLabelID(res.data.layoutData))
            this.setPaibanData([])
            this.goToBeforeMaterialDeal(res.data, item)
            window.sessionStorage.setItem('isHistory', 1)
            this.$router.push('/materialList')
          } else {
            if (res.data.paibanData) {
              const bigplank = res.data.paibanData.find(
                (data) => !data.surplusInfo
              )
              if (item.process_setting_id == -2) {
                const preSetting = res.data.preLayoutSetting.setting_value
                // xy轴开启时，需要把所匹配的大板长宽对调
                if (preSetting.countertopSetting.xyReverse && bigplank) {
                  Object.assign(bigplank, {
                    plankWidth: bigplank.plankHeight,
                    plankHeight: bigplank.plankWidth,
                  })
                }
              }
              let standardPlank = await dealStandardPlank(
                bigplank,
                sessionStorage.getItem('thinkerx_material')
                  ? { uid: this.userInfo.id, from: 'guimen' }
                  : { uid: this.userInfo.id }
              )
              this.$store.commit('setSelectStandardPlank', {
                plankWidth: standardPlank.plankWidth,
                plankHeight: standardPlank.plankHeight,
                plankEdgeOff: standardPlank.plankEdgeOff,
                standardPlankId: standardPlank.standardPlankId,
              })
            }
            if (res.data.paibanInfo) {
              this.setFilterMaterialList(
                res.data.paibanInfo.filterPlankList ?? null
              )
            } else {
              this.setFilterMaterialList(null)
            }
            this.setPaibanKey(res.data.paibanKey)
            this.setPreLayoutData(dealPreLayoutDataLabelID(res.data.layoutData))
            this.goToBeforeMaterialDeal(res.data, item)
            this.setPaibanInfo(res.data.paibanInfo ?? {})
            let standardPlank = result.data.panelSize
            if (res.data.paibanData.length > 0) {
              res.data.paibanData.forEach((plank) => {
                checkPlankDataIsCorrect(plank)
              })
              this.setPaibanData(res.data.paibanData)
              this.setFinalDrawData([])
              if (res.data.thinkerxMaterialKeys) {
                this.setThinkerxMaterialKeys(res.data.thinkerxMaterialKeys)
              }
              let orderInfo = {
                order_address: translate('common.history'),
                order_ids: item.order_list ? item.order_list.join(',') : '',
                order_codes: item.plank_info.order_codes,
                needPaibanRoomIds: item.room_ids ? item.room_ids : [],
                room_ids: [],
                batch: item.plank_info.batch,
              }
              this.setOrderInfo(orderInfo)
              const notOtherPlatePlank = res.data.paibanData.find(
                (item) => !item.otherPlate && !item.surplusInfo
              )
              if (notOtherPlatePlank) {
                standardPlank = notOtherPlatePlank
              }
            }
            result.data.drawPlankWidth = result.data.xyReverse
              ? standardPlank.plankHeight
              : standardPlank.plankWidth
            result.data.drawPlankHeight = result.data.xyReverse
              ? standardPlank.plankWidth
              : standardPlank.plankHeight
            if (res.data.paibanInfo?.historyPlankEdgeOff) {
              result.data.drawPlankEdgeOff =
                res.data.paibanInfo?.historyPlankEdgeOff
            }
            let obj = Object.assign(result.data, {
              process_setting_id: item.process_setting_id,
              process_setting_name: item.plank_info.process_setting_name,
              isPreLayout: item.process_setting_id == -2 ? true : undefined,
            })
            this.setNcSetting(obj)
            this.changeHistoryStatus(true)
            // 清空收集的多排版方案
            this.$store.commit('paibanStore/setPaibanDataCollect', [])
            this.$store.commit('paibanStore/clearAlreadyDealDrawData')
            window.sessionStorage.setItem('isHistory', 1)
            this.$router.push('/materialList')
          }
        } else {
          this.setPreLayoutData(dealPreLayoutDataLabelID(res.data.layoutData))
          this.setPaibanData([])
          this.goToBeforeMaterialDeal(res.data, item)
          window.sessionStorage.setItem('isHistory', 1)

          this.$router.push('/materialList')
        }
      })
      this.setRecodeSelectPart({ selects: [], isHistory: true })
      this.setRecodeSelectSurplus({ selects: [], isHistory: true })
      this.dealPaibanData(item, 'materialList')
      if (item.save_node === 3) {
        this.setHasPrintTag(true)
      } else {
        this.setHasPrintTag(false)
      }
      this.setPaibanData([])
    },

    // 下载数据到本地
    downloadData(item) {
      axios.get(item.plank_info.historyUrl).then((res) => {
        let data = res.data
        let material = new Blob([JSON.stringify(data)], { type: '' })
        FileSaver.saveAs(material, `${item.name}_历史数据.json`)
      })
    },
    downloadMore() {
      if (this.selectionArr.length == 0) {
        this.$message({
          message: translate('historyPage.lessSelectTip'),
          type: 'info',
        })
        return
      }
      const zip = new JsZip()
      const cache = {}
      const promises = []
      let name = 'ceshi'

      this.selectionArr.forEach((item, index) => {
        item.count = 1
        if (
          item.name ===
          this.selectionArr[(index - 1) % this.selectionArr.length]?.name
        ) {
          item.count =
            this.selectionArr[(index - 1) % this.selectionArr.length]?.count + 1
        }
        const promise = this.getFile(item.url).then((res) => {
          let filename =
            item.count > 1
              ? `${item.name} (${item.count - 1}).json`
              : `${item.name}.json`
          zip.file(filename, res, { binary: true }) // 逐个添加文件
          cache[filename] = res
        })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          // 生成二进制流
          FileSaver.saveAs(content, `${name}.zip`) // 利用file-saver保存文件
        })
      })
    },
    str2ab(arr) {
      return new Promise((r1, r2) => {
        let s = JSON.stringify(arr)
        var b = new Blob([s], { type: 'text/plain' })
        var r = new FileReader()
        let result = ''
        r.readAsArrayBuffer(b)
        r.onload = () => {
          r1(r.result)
        }
      })
    },
    // 获取文件流
    getFile(url) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url,
          responseType: 'arraybuffer',
        })
          .then((data) => {
            resolve(data.data)
          })
          .catch((error) => {
            reject(error.toString())
          })
      })
    },
    // 删除保存历史
    deleteHistory(ids) {
      this.$token('/delete_material_data', { ids }, (res) => {
        if (res.status == 1) {
          this.$message({
            message: translate('common.deleteSuccess'),
            type: 'success',
            duration: 1500,
            offset: 60,
          })
          if (
            (this.totalCount % this.pageSize == 1 &&
              this.currentPage > this.totalCount / this.pageSize) ||
            (this.totalCount % this.pageSize !== 1 &&
              this.totalCount % this.pageSize == ids.length &&
              this.currentPage > this.totalCount / this.pageSize)
          ) {
            this.currentPage = this.currentPage - 1
            this.getHistoryList(this.currentPage)
          }
          this.getHistoryList(this.currentPage)
          this.isVisible = false
        } else {
          this.$message({
            message: translate('common.deleteFail'),
            type: 'error',
            duration: 1500,
            offset: 60,
          })
        }
      })
    },
    // 删除
    openDeleteHistoryModal(item) {
      let ids = []
      if (item && item.id) {
        //单个删除
        ids = [item.id]
      } else {
        // 批量删除
        for (let i = this.historyList.length - 1; i >= 0; --i) {
          if (this.historyList[i].isSelected) {
            ids.push(this.historyList[i].id)
          }
        }
      }
      if (ids.length == 0) {
        this.$message({
          message: translate('historyPage.lessSelectTip'),
          type: 'info',
          duration: 1500,
          offset: 60,
        })
        return
      }
      this.needDeleteNames = this.historyList.reduce((pre, cut) => {
        ids.includes(cut.id) && pre.push(cut.name)
        return pre
      }, [])
      this.needDeleteOfIds = ids
      this.isVisible = true
    },
    // 双击名字
    handleDblclickName(item) {
      this.isShowRename = true
      this.renameValue = item.name
      this.renameItem = item
    },
    handleOkRename() {
      if (!this.renameValue.trim()) {
        this.renameValue = ''
      }
      const params = {
        id: this.renameItem.id,
        name: this.renameValue,
      }
      editHistoryName(params).then((res) => {
        if (res.status) {
          this.getHistoryList(this.currentPage)
          this.isShowRename = false
        }
      })
    },
    handleGotoTagLabel(item) {
      this.setIsSubPrintTag(false)
      this.dealPaibanData(item, 'printTag', true)
      this.setHistoryOrder(item)
      this.changeHistoryStatus(true)
    },
    handleAutoSaveChange() {
      this.savePreferences()
      this.getHistoryList()
    },
    // 保存偏好设置
    savePreferences() {
      this.preferencesSetting.autoSave = this.autoSave
      savePreferencesSetting(this.preferencesSetting)
    },
    async getPreferences() {
      // 加载偏好设置
      const res = await getPreferencesSetting()
      if (res.status === 1) {
        this.preferencesSetting = res.data
        if (this.preferencesSetting.autoSave) {
          this.autoSave = this.preferencesSetting.autoSave
        }
      }
    },
    async getInitData() {
      await this.getProduceLine()
      await this.getPreferences()
      await this.getHistoryList()
    },
    dealPaibanData(item, path, gotoTag = false) {
      axios.get(item.plank_info.historyUrl).then((res) => {
        let params = {
          setting_id: item.process_setting_id,
        }
        if (window.sessionStorage.getItem('thinkerx_material')) {
          params.production_from = 'guimen'
        }
        // this.setFilterMaterialList(res.data.filterPlankList)
        // this.setBeforeSearchMaterialList(res.data.filterPlankList)
        // this.setBeforeSizeFilterList(res.data.filterPlankList)
        if (params.setting_id && params.setting_id !== -1) {
          this.$getByToken('/get_production_nc_setting', params, (result) => {
            if (result.status !== 1) {
              this.$message({
                message: translate('materialPage.noProductionLine'),
                type: 'error',
                duration: 3000,
                offset: 60,
              })
              // this.setPreLayoutData(res.data.layoutData)
              this.setPaibanData([])
              this.goToBeforeMaterialDeal(res.data, item)
              window.sessionStorage.setItem('isHistory', 1)
              this.$router.push('/materialList')
            } else {
              if (res.data.paibanInfo) {
                this.setFilterMaterialList(
                  res.data.paibanInfo.filterPlankList ?? null
                )
              } else {
                this.setFilterMaterialList(null)
              }
              this.setPaibanKey(res.data.paibanKey)
              this.setPreLayoutData(res.data.layoutData)
              this.goToBeforeMaterialDeal(res.data, item)
              this.setPaibanInfo(res.data.paibanInfo ?? {})
              let standardPlank = result.data.panelSize
              if (res.data.paibanData.length) {
                res.data.paibanData.forEach((plank) => {
                  checkPlankDataIsCorrect(plank)
                })
                this.setPaibanData(res.data.paibanData)
                this.setFinalDrawData([])
                if (res.data.thinkerxMaterialKeys) {
                  this.setThinkerxMaterialKeys(res.data.thinkerxMaterialKeys)
                }
                let orderInfo = {
                  order_address: translate('common.history'),
                  order_ids: item.order_list ? item.order_list.join(',') : '',
                  order_codes: item.plank_info.order_codes,
                  needPaibanRoomIds: item.room_ids ? item.room_ids : [],
                  room_ids: [],
                  batch: item.plank_info.batch,
                }
                this.setOrderInfo(orderInfo)
                const notOtherPlatePlank = res.data.paibanData.find(
                  (item) => !item.otherPlate && !item.surplusInfo
                )
                if (notOtherPlatePlank) {
                  standardPlank = notOtherPlatePlank
                }
              }
              result.data.drawPlankWidth = result.data.xyReverse
                ? standardPlank.plankHeight
                : standardPlank.plankWidth
              result.data.drawPlankHeight = result.data.xyReverse
                ? standardPlank.plankWidth
                : standardPlank.plankHeight
              if (res.data.paibanInfo?.historyPlankEdgeOff) {
                result.data.drawPlankEdgeOff =
                  res.data.paibanInfo?.historyPlankEdgeOff
              }
              let obj = Object.assign(result.data, {
                process_setting_id: item.process_setting_id,
                process_setting_name: item.plank_info.process_setting_name,
                isPreLayout: item.process_setting_id == -2 ? true : undefined,
              })
              this.setNcSetting(obj)
              this.changeHistoryStatus(true)
              // 清空收集的多排版方案
              this.$store.commit('paibanStore/setPaibanDataCollect', [])
              this.$store.commit('paibanStore/clearAlreadyDealDrawData')
              window.sessionStorage.setItem('isHistory', 1)
              this.$router.push(path)
            }
          })
        } else {
          // this.setPreLayoutData(res.data.layoutData)
          this.setPaibanData([])
          this.goToBeforeMaterialDeal(res.data, item)
          window.sessionStorage.setItem('isHistory', 1)
          this.$router.push(path)
        }
      })
      gotoTag && this.setHasPrintTag(true)
      this.setRecodeSelectPart({ selects: [], isHistory: true })
      this.setRecodeSelectSurplus({ selects: [], isHistory: true })
    },
    // 全选功能
    handleSelectAll(e) {
      // historyList
      for (let item of this.historyList) {
        if (e.target.checked) {
          item.isSelected = true
        } else {
          item.isSelected = false
        }
      }
    },
    // 判断是否需要勾选全选框
    handleJudgeCancelAllSelect() {
      let unselected = ''
      unselected = this.historyList.find((item) => {
        return !item.isSelected
      })
      if (!unselected) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }
    },
    // 排版筛选变更
    onFilterChange(value) {
      this.historyType = value
      this.filterList()
    },
    updateTrans() {
      this.filterOptions = this.filterOptions.map((item) => ({
        ...item,
        label: translate(item.key),
        children: item.children
          ? item.children.map((i) => ({
              ...i,
              label: i.key ? translate(i.key) : i.label,
            }))
          : [],
      }))
    },
  },
  watch: {
    historyList: {
      handler(newVal, oldVal) {
        // 监听到数据改变，取消全选
        this.allSelected = false
      },
    },
  },
  created() {
    this.getInitData()
  },
  mounted() {
    this.$getByToken(
      '/production/trial_room',
      { is_get_layout_data: 0 },
      (res) => {
        if (res.status === 1) {
          this.trailProductState = res.data.state
        }
      }
    )
    this.updateTrans()
    this.$bus.$on(EventBusKey.TRANSLATE, this.updateTrans)
  },
  unmounted() {
    this.$bus.$off(EventBusKey.TRANSLATE, this.updateTrans)
  },
}
</script>

<style scoped lang="less">
.save-paiban-page {
  margin: 16px;
  // overflow-x: auto;
  .wrap {
    position: relative;
    height: calc(100vh - 109px);
    padding: 24px;
    background: #fff;
  }
  .filter-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .left-btns {
      display: flex;
    }
    .delete-btn {
      margin-right: 8px;
    }
    .down-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 32px;
      color: #fff;
      background: #18a8c7;
      border-radius: 4px;
    }
    .disable-btn {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
    }
    .ant-select {
      width: 160px;
      height: 32px;
      margin-right: 8px;
    }
    .search-box {
      display: flex;
      width: 310px;
      .ant-input-search-button {
        width: 84px;
        color: #fff;
        background: #18a8c7;
        border: 1px solid #18a8c7;
        outline: none;
      }
      .ant-input {
        width: 226px;
      }
    }
    .search-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 84px;
      height: 32px;
      color: #fff;
      background: #18a8c7;
      border-radius: 4px;
    }
  }
  .main-table {
    max-height: calc(100vh - 114px - 24px - 24px - 16px - 91px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.05);
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
    &::-webkit-scrollbar-track {
      background-color: white;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track:hover {
      background-color: rgba(0, 0, 0, 0.01);
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .history-item {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 24px;
      padding: 24px;
      background: #fff;
      border: 1px solid #e5e5e5;
    }
    .used-rate {
      margin-left: 25px;
      .use-rate-info {
        font-family: PingFangSC-Medium, PingFang SC;
        cursor: default;
      }
    }
    .left-info {
      display: flex;
      align-items: center;
    }
    .plank-info {
      display: flex;
      align-items: center;
      margin-left: 25px;
      .plank-basic-info {
        flex-shrink: 0;
      }
      .history-name {
        display: inline-block;
        font-size: 18px;
        max-width: 600px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .line-name {
        display: inline-block;
        padding: 1px 8px;
        color: #18a8c7;
        background: #e9f8fb;
        border: 1px solid #88def0;
        border-radius: 2px;
      }
      .pre-layout {
        color: #f0c649;
        background: rgba(#f9dbb2, 0.2);
        border-color: #f0c649;
      }
      .save-status {
        display: inline-block;
        padding: 1px 8px;
        border: 1px solid #dcdfe6;
        border-radius: 2px;
      }
      .save-time {
        color: #999;
        font-size: 14px;
      }
      .plank-info-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      .show-all-plank {
        display: flex;
        align-items: center;
        height: 45px;
        margin-left: 27px;
        cursor: pointer;
        &:hover .circle {
          background: #18a8c7;
        }
        .circle {
          display: block;
          width: 4px;
          height: 4px;
          margin-right: 5px;
          background: #e3e3e3;
          border-radius: 50%;
        }
      }
      .plank-item {
        margin-left: 32px;
      }
      .plank-thick-matcode {
        display: block;
        color: #999;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        cursor: default;
      }
      .plank-num-texture {
        color: #333;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        cursor: default;
      }
    }
    .right-operation {
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 204px;
      span {
        display: inline-block;
        width: 68px;
        color: #18a8c7;
        font-size: 14px;
        text-align: left;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }
  }
  .page-toggle {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    padding: 29px 24px;
    border-top: 1px solid #ddd;
    .all-select {
      margin-left: 25px;
    }
    & > div {
      display: flex;
      align-items: center;
    }
    .jump-to-page {
      display: flex;
      align-items: center;
      .ant-input {
        width: 48px;
      }
      span:first-child {
        margin-right: 8px;
      }
      span:last-child {
        margin-left: 8px;
      }
    }
  }
}
.rename-modal {
  /deep/.ant-modal {
    padding-bottom: 0;
  }
  :deep(.ant-modal-content) {
    height: 204px;

    .ant-modal-title {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
    }

    .ant-input {
      color: rgba(0, 0, 0, 0.9);
      padding: 4px 8px;
    }

    .ant-modal-footer {
      height: 68px;
      line-height: 68px;
      padding: 0;
      padding-right: 24px;
    }
  }
}
</style>

<style lang="less">
.disabled-btn {
  color: #c3c3c3 !important;
  cursor: not-allowed !important;
}
.save-paiban-page {
  .el-date-editor {
    width: 380px;
    height: 32px;
    margin-right: 8px;
    padding: 0 5px;
  }
  .el-date-editor.el-range-editor .el-range-separator {
    width: 6%;
  }
}
.tag-status {
  position: absolute;
  top: 16px;
  right: 240px;
  z-index: 999;
}
</style>
