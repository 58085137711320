<template>
  <div
    class="bread-crumb relative"
    ref="breadCrumb"
    v-loading.body.lock="isLoading"
    :element-loading-text="`${$t('lineManager.lineLoading')}...`"
  >
    <div class="wrap" v-if="produceLine.length">
      <div class="add-produce-line flex">
        <div class="relative">
          <a-button id="equipment_addLine_btn" @click="addNewLine"
            ><a-icon type="save" />{{ $t('lineManager.add') }}</a-button
          >
          <a-button
            id="equipment_trail_btn"
            @click="handleGoGuiguiTrail"
            class="ml20"
            style="width: 120px"
            v-if="isQT"
            ><span
              class="block ellipsis w100"
              :title="translateLang('historyPage.trailProduction.title')"
              >{{ $t('historyPage.trailProduction.title') }}</span
            ></a-button
          >
          <span
            class="trail-mark absolute color-f fs12 br4 align-center"
            v-if="isQT"
            >{{ $t('historyPage.trailProduction.start') }}</span
          >
        </div>
        <div class="lock-icon" @click="handleOpenUnlock">
          <a-icon
            type="lock"
            theme="filled"
            style="color: #18a8c7"
            v-if="tempStatus === 1 || tempStatus === 3"
          />
          <a-icon type="unlock" theme="filled" style="color: #898989" v-else />
        </div>
      </div>
      <!-- line_item produceLine是生产线数据 -->
      <div
        class="produce-line"
        v-for="(line_item, line_index) in produceLine"
        :key="line_index + '2'"
      >
        <div>
          <div class="produce-setting">
            <span class="line-name" v-if="nowLine != line_index">{{
              line_item.name
            }}</span>
            <a-input
              ref="line_name"
              v-model="line_item.name"
              @pressEnter="confirmLineName(line_item, line_index)"
              @blur.native="confirmLineName(line_item, line_index)"
              :id="`equipment_line_${line_index}_rename_input`"
              v-else
            ></a-input>
            <div class="produce-line-icon">
              <a-button
                @click="modifyLineName(line_item, line_index)"
                v-if="line_index != 0"
                :id="`equipment_line_${line_index}_rename_btn`"
                >{{ $t('common.reName') }}</a-button
              >
              <a-button
                :id="`equipment_line_${line_index}_delete_btn`"
                @click="deleteLine(line_item)"
                v-if="line_index != 0"
                >{{ $t('common.delete') }}</a-button
              >
              <a-button
                :id="`equipment_line_${line_index}_save_btn`"
                @click="saveLine(line_item, line_index)"
                ><a-icon type="save" style="margin-right: 0" />{{
                  $t('common.save')
                }}</a-button
              >
            </div>
          </div>
          <div class="content">
            <div>
              <div
                v-for="(cut_item, cut_index) in line_item.cutList"
                :key="cut_index + '3'"
              >
                <div class="cutting common-setting" v-if="cut_item.show">
                  <span class="common-setting-title">{{
                    $t('lineManager.engraving')
                  }}</span>
                  <a-icon
                    type="left"
                    :id="`equipment_line_${line_index}_pre_btn`"
                    @click="
                      changeNowLineSetting(
                        line_item,
                        cut_item.index,
                        'last',
                        'cut'
                      )
                    "
                  />
                  <div
                    class="common-content"
                    @click="gotoSetting(cut_item, line_item)"
                    :id="`equipment_line_${line_index}_toSetting_btn`"
                  >
                    <div>
                      <img :src="require('../../' + cut_item.img)" alt="" />
                    </div>
                    <span>{{ translateLang(cut_item.name) }}</span>
                  </div>
                  <a-icon
                    type="right"
                    :id="`equipment_line_${line_index}_next_btn`"
                    @click="
                      changeNowLineSetting(
                        line_item,
                        cut_item.index,
                        'next',
                        'cut'
                      )
                    "
                  />
                </div>
              </div>
              <div
                v-for="(cure_item, cure_index) in line_item.cureList"
                :key="cure_index + '4'"
              >
                <div
                  class="holes-equipment common-setting"
                  v-if="
                    cure_item.recombination &&
                    line_item.activeCutEquip !== 'glassEquip'
                  "
                >
                  <span class="common-setting-title">{{
                    $t('lineManager.holeSlotEquip')
                  }}</span>
                  <a-icon
                    type="left"
                    :id="`equipment_line_${line_index}_holeSlot_pre_btn`"
                    @click="
                      changeNowLineSetting(
                        line_item,
                        cure_item.index,
                        'last',
                        'cure'
                      )
                    "
                  />
                  <div
                    class="common-content"
                    :id="`equipment_line_${line_index}_holeSlot_toSetting_btn`"
                    @click="gotoSetting(cure_item, line_item)"
                  >
                    <div>
                      <img
                        :src="
                          line_item.haveSetted
                            ? require('../../' + cure_item.img)
                            : require('@/assets/icon/noSetted.svg')
                        "
                        alt=""
                      />
                    </div>
                    <span>{{
                      line_item.haveSetted
                        ? translateLang(cure_item.name)
                        : $t('lineManager.noUse')
                    }}</span>
                  </div>
                  <a-icon
                    type="right"
                    :id="`equipment_line_${line_index}_holeSlot_next_btn`"
                    @click="
                      changeNowLineSetting(
                        line_item,
                        cure_item.index,
                        'next',
                        'cure'
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="cutting-setting"
            v-if="
              line_item.showExtraSetting &&
              !line_item.showCombinationSetting &&
              line_item.activeCutEquip !== 'glassEquip'
              // &&
              // !line_item.cureList.find((i) => i.value == 'side').show
            "
          >
            <div
              :id="`equipment_line_${line_index}_engraving_setting_btn`"
              class="cutting-setting-title"
              @click="cureSetting(line_item)"
            >
              <a-icon
                :type="line_item.showCure ? 'caret-down' : 'caret-right'"
              />
              <span>{{ $t('lineManager.workSetting') }}</span>
            </div>
            <div class="cutting-checkbox" v-if="line_item.showCure">
              <div class="tip mb16">
                {{ $t('lineManager.warningTip') }}
              </div>
              <div class="cure-setting-box">
                <div
                  class="cure-label bold ellipsis"
                  :title="translateLang('lineManager.engravingSetting')"
                >
                  {{ $t('lineManager.engravingSetting') }}：
                </div>
                <div class="cure-box mt5 mb20 pt5 pb5 pr0 pl0">
                  <span class="ml20 mr5">{{ $t('lineManager.workWay') }}:</span>
                  <a-radio-group
                    v-model="line_item.nowCure"
                    v-if="userInfo.edition_id != 4"
                  >
                    <a-radio
                      v-for="(cure, index) in cureCheckList"
                      :id="`equipment_line_${line_index}_engraving_setting_${cure.value}_radio`"
                      :key="index + '2'"
                      :value="cure.value"
                      :disabled="
                        (line_item.activeCureEquip == 'side' &&
                          (cure.value == 'noHoleSlot' ||
                            cure.value == 'frontHoleSlot')) ||
                        line_item.aio_hole_slot_process
                          .enable_integration_device
                      "
                      @change="handleChangeWay(cure.value, line_index)"
                      ><t-text
                        :text="translateLang(cure.name)"
                        width="170px"
                      ></t-text>
                      <!-- <span
                        class="ellipsis inline-block"
                        style="max-width: 150px"
                        :id="`equipment_line_${line_index}_engraving_setting_${cure.value}_radio_label`"
                        ></span
                      > -->
                    </a-radio>
                  </a-radio-group>
                  <a-radio-group
                    v-model="line_item.nowCure"
                    v-if="userInfo.edition_id == 4"
                  >
                    <a-radio
                      v-for="(cure, index) in cureCheckList"
                      :id="`equipment_line_${line_index}_engraving_setting_${cure.value}_radio`"
                      :key="index + '2'"
                      :value="cure.value"
                      :disabled="
                        cure.value == 'doubleHoleSlot' ||
                        cure.value == 'frontHoleSlot' ||
                        line_item.aio_hole_slot_process
                          .enable_integration_device
                      "
                      ><t-text
                        :text="translateLang(cure.name)"
                        width="170px"
                      ></t-text>
                      <!-- <span
                        :id="`equipment_line_${line_index}_engraving_setting_${cure.value}_radio_label`"
                        >{{ translateLang(cure.name) }}</span
                      > -->
                    </a-radio>
                  </a-radio-group>
                  <span
                    v-if="
                      line_item.aio_hole_slot_process.enable_integration_device
                    "
                    class="aio-tip"
                  >
                    <!-- 您已启用"一体机"设备，加工方式默认为加工板件正反面孔槽 -->
                    <!-- {{ $t('lineManager.aioProcessTip') }} -->
                    <t-text
                      :text="$t('lineManager.aioProcessTip')"
                      width="900px"
                      class="ml20"
                    ></t-text>
                  </span>
                  <div
                    class="cure-setting-box flex mt15"
                    v-if="
                      line_item.nowCure != 'doubleHoleSlot' &&
                      userInfo.edition_id != 4 &&
                      isShowHoleShunt(line_item) &&
                      !line_item.aio_hole_slot_process.enable_integration_device
                    "
                  >
                    <!-- isShowHoleShunt(line_item) -->
                    <div
                      class="cure-label ml20 flex"
                      :title="translateLang('lineManager.cutedSpecial')"
                    >
                      <t-text
                        :text="$t('lineManager.cutedSpecial')"
                        width="115px"
                      ></t-text>
                    </div>
                    <div class="cure-box pt5 pb5 pr0 pl0" style="border: 0px">
                      <div class="hole-slot_setting">
                        <div
                          v-for="setItem in holeSlotKeys"
                          :key="setItem.key"
                          class="tick-box relative"
                        >
                          <div
                            v-if="
                              (setItem.key === 'holes_in_yixing' ||
                                setItem.key === 'slots_in_yixing') &&
                              line_item.activeCureEquip === 'fiveSix'
                            "
                            class="toast-message absolute"
                            :id="`toast_${setItem.key}_${line_item.id}`"
                          >
                            <t-text
                              :text="$t('lineManager.noWork')"
                              width="250px"
                            ></t-text>
                          </div>
                          <a-checkbox
                            v-model="
                              line_item.hole_slot_engraving_process
                                .special_hole_slot[setItem.key]
                            "
                            :id="`equipment_line${line_index}_specialHoleSlot_${setItem.key}_checkbox`"
                            @change="handleHoleSlotChange(setItem, line_item)"
                            ><span
                              :id="`equipment_line${line_index}_specialHoleSlot_${setItem.key}_checkbox_label`"
                              ><t-text
                                :text="translateLang(setItem.label)"
                                width="100px"
                              ></t-text></span
                          ></a-checkbox>
                          <a-select
                            showArrow
                            id="special_setting_select"
                            class="special-way mb5"
                            v-if="setItem.key == 'HINGESCREW'"
                            v-model="
                              line_item.hole_slot_engraving_process
                                .special_hole_slot_way[`HINGE_way`]
                            "
                            @change="
                              handleHoleSlotChange(setItem, line_item, true)
                            "
                          >
                            <template v-for="item in specialSettingOptions">
                              <a-select-option
                                v-if="
                                  line_item.nowCure != 'frontHoleSlot' ||
                                  (line_item.nowCure == 'frontHoleSlot' &&
                                    item.value != 'back')
                                "
                                :value="item.value"
                                :key="item.value"
                                ><t-text
                                  :text="translateLang(item.lable)"
                                  width="140px"
                                ></t-text
                              ></a-select-option>
                            </template>
                          </a-select>
                          <a-select
                            showArrow
                            id="special_setting_select"
                            class="special-way mb5"
                            v-else-if="
                              setItem.key != 'holes_in_yixing' &&
                              setItem.key != 'slots_in_yixing' &&
                              setItem.key != 'bull_horn_handle'
                            "
                            v-model="
                              line_item.hole_slot_engraving_process
                                .special_hole_slot_way[`${setItem.key}_way`]
                            "
                            @change="
                              handleHoleSlotChange(setItem, line_item, true)
                            "
                          >
                            <template v-for="item in specialSettingOptions">
                              <a-select-option
                                v-if="
                                  line_item.nowCure != 'frontHoleSlot' ||
                                  (line_item.nowCure == 'frontHoleSlot' &&
                                    item.value != 'back')
                                "
                                :value="item.value"
                                :key="item.value"
                                ><t-text
                                  :text="translateLang(item.lable)"
                                  width="140px"
                                ></t-text
                              ></a-select-option>
                            </template>
                          </a-select>
                        </div>
                        <a-checkbox
                          v-if="line_item.activeCureEquip != 'ptp'"
                          class="mt0"
                          v-model="
                            line_item.hole_slot_engraving_process
                              .engraving_process_setting
                              .non_hole_slot_of_special_shape
                          "
                          :id="`equipment_line_${line_index}_engraving_setting_process_non_hole_slot_of_special_shape_checkbox`"
                          ><span>{{
                            dealKeyName(
                              'engraving_process_setting',
                              'non_hole_slot_of_special_shape'
                            )
                          }}</span></a-checkbox
                        >
                      </div>
                      <div class="short-side-setting mt15">
                        <a-checkbox
                          v-model="
                            line_item.hole_slot_engraving_process
                              .special_hole_slot.in_range_all
                          "
                        >
                          {{ $t('lineManager.minSize') }}
                        </a-checkbox>
                        <el-input
                          size="mini"
                          class="mh5"
                          v-model="
                            line_item.hole_slot_engraving_process
                              .special_hole_slot.short_edge_range
                          "
                          @input="
                            verifyInput(
                              $event,
                              line_item.hole_slot_engraving_process
                                .special_hole_slot,
                              ['short_edge_range'],
                              '3-2',
                              false,
                              '',
                              true
                            )
                          "
                        ></el-input>
                        <span class="fs15"
                          >mm{{ $t('lineManager.maxSize') }}</span
                        >
                        <el-input
                          size="mini"
                          class="mh5"
                          v-model="
                            line_item.hole_slot_engraving_process
                              .special_hole_slot.long_edge_range
                          "
                          @input="
                            verifyInput(
                              $event,
                              line_item.hole_slot_engraving_process
                                .special_hole_slot,
                              ['long_edge_range'],
                              '4-2',
                              false,
                              '',
                              true
                            )
                          "
                        ></el-input>
                        <span class="fs15">{{
                          $t('lineManager.minSize2')
                        }}</span>
                      </div>
                      <div class="pt15">
                        <span class="mr8">单孔：</span>
                        <a-select
                          showArrow
                          id="special_setting_select"
                          class="special-way mb5"
                          v-model="
                            line_item.hole_slot_engraving_process
                              .special_hole_slot_way[
                              `engraving_holes_range_way`
                            ]
                          "
                        >
                          <template v-for="item in specialSettingOptions">
                            <a-select-option
                              v-if="
                                line_item.nowCure != 'frontHoleSlot' ||
                                (line_item.nowCure == 'frontHoleSlot' &&
                                  item.value != 'back')
                              "
                              :value="item.value"
                              :key="item.value"
                              ><t-text
                                :text="translateLang(item.lable)"
                                width="140px"
                              ></t-text
                            ></a-select-option>
                          </template>
                        </a-select>
                      </div>
                      <div
                        v-for="(item, idx) in line_item
                          .hole_slot_engraving_process.engraving_holes_range"
                        :key="idx + 'hole'"
                        class="hole hole-slot"
                      >
                        {{ $t('lineManager.singleHole') }}{{ idx + 1 }}
                        <div>
                          {{ $t('lineManager.diameterRange') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.hole_slot_engraving_process
                                .engraving_holes_range[idx].minDiameter
                            "
                            :id="`equipment_line_${line_index}_engraving_setting_hole_diameter_${idx}_start_input`"
                            @input="
                              verifyInput(
                                $event,
                                line_item.hole_slot_engraving_process
                                  .engraving_holes_range[idx],
                                ['minDiameter'],
                                '3-2'
                              )
                            "
                          ></el-input>
                          {{ $t('lineManager.to') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.hole_slot_engraving_process
                                .engraving_holes_range[idx].maxDiameter
                            "
                            :id="`equipment_line_${line_index}_engraving_setting_hole_diameter_${idx}_end_input`"
                            @input="
                              verifyInput(
                                $event,
                                line_item.hole_slot_engraving_process
                                  .engraving_holes_range[idx],
                                ['maxDiameter'],
                                '3-2'
                              )
                            "
                          ></el-input>
                        </div>
                        <div>
                          {{ $t('lineManager.hDeepRange') }}
                          <el-input
                            size="mini"
                            :id="`equipment_line_${line_index}_engraving_setting_hole_deep_${idx}_start_input`"
                            v-model="
                              line_item.hole_slot_engraving_process
                                .engraving_holes_range[idx].minDepth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.hole_slot_engraving_process
                                  .engraving_holes_range[idx],
                                ['minDepth'],
                                '2-3'
                              )
                            "
                          ></el-input>
                          {{ $t('lineManager.to') }}
                          <el-input
                            size="mini"
                            :id="`equipment_line_${line_index}_engraving_setting_hole_deep_${idx}_end_input`"
                            v-model="
                              line_item.hole_slot_engraving_process
                                .engraving_holes_range[idx].maxDepth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.hole_slot_engraving_process
                                  .engraving_holes_range[idx],
                                ['maxDepth'],
                                '2-3'
                              )
                            "
                          ></el-input>
                        </div>
                        <span
                          class="add"
                          v-if="
                            idx ===
                            line_item.hole_slot_engraving_process
                              .engraving_holes_range.length -
                              1
                          "
                          @click="
                            addHoleSlot(
                              line_item.hole_slot_engraving_process,
                              'engraving_holes_range'
                            )
                          "
                          :id="`equipment_line_${line_index}_engraving_setting_hole_add_btn`"
                          >{{ $t('common.add') }}</span
                        >
                        <span
                          class="delete"
                          @click="
                            deleteHoleSlot(
                              line_item.hole_slot_engraving_process,
                              'engraving_holes_range',
                              idx
                            )
                          "
                          :id="`equipment_line_${line_index}_engraving_setting_hole_${idx}_delete_btn`"
                          >{{ $t('common.delete') }}</span
                        >
                      </div>
                      <div class="pt15">
                        <span class="mr8">单槽：</span>
                        <a-select
                          showArrow
                          id="special_setting_select"
                          class="special-way mb5"
                          v-model="
                            line_item.hole_slot_engraving_process
                              .special_hole_slot_way[
                              `engraving_slots_range_way`
                            ]
                          "
                        >
                          <template v-for="item in specialSettingOptions">
                            <a-select-option
                              v-if="
                                line_item.nowCure != 'frontHoleSlot' ||
                                (line_item.nowCure == 'frontHoleSlot' &&
                                  item.value != 'back')
                              "
                              :value="item.value"
                              :key="item.value"
                              ><t-text
                                :text="translateLang(item.lable)"
                                width="140px"
                              ></t-text
                            ></a-select-option>
                          </template>
                        </a-select>
                      </div>
                      <div
                        v-for="(item, idx) in line_item
                          .hole_slot_engraving_process.engraving_slots_range"
                        :key="idx + 'slot'"
                        class="slot hole-slot"
                      >
                        {{ $t('lineManager.singleSlot') }}{{ idx + 1 }}
                        <div>
                          {{ $t('lineManager.widthRange') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.hole_slot_engraving_process
                                .engraving_slots_range[idx].minWidth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.hole_slot_engraving_process
                                  .engraving_slots_range[idx],
                                ['minWidth'],
                                '3-2'
                              )
                            "
                            :id="`equipment_line_${line_index}_engraving_setting_slot_width_${idx}_start_input`"
                          ></el-input>
                          {{ $t('lineManager.to') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.hole_slot_engraving_process
                                .engraving_slots_range[idx].maxWidth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.hole_slot_engraving_process
                                  .engraving_slots_range[idx],
                                ['maxWidth'],
                                '3-2'
                              )
                            "
                            :id="`equipment_line_${line_index}_engraving_setting_slot_width_${idx}_end_input`"
                          ></el-input>
                        </div>
                        <div>
                          {{ $t('lineManager.sDeepRange') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.hole_slot_engraving_process
                                .engraving_slots_range[idx].minDepth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.hole_slot_engraving_process
                                  .engraving_slots_range[idx],
                                ['minDepth'],
                                '2-3'
                              )
                            "
                            :id="`equipment_line_${line_index}_engraving_setting_slot_width_${idx}_start_input`"
                          ></el-input>
                          {{ $t('lineManager.to') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.hole_slot_engraving_process
                                .engraving_slots_range[idx].maxDepth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.hole_slot_engraving_process
                                  .engraving_slots_range[idx],
                                ['maxDepth'],
                                '2-3'
                              )
                            "
                            :id="`equipment_line_${line_index}_engraving_setting_slot_deep_${idx}_end_input`"
                          ></el-input>
                        </div>

                        <span
                          class="add"
                          v-if="
                            idx ===
                            line_item.hole_slot_engraving_process
                              .engraving_slots_range.length -
                              1
                          "
                          @click="
                            addHoleSlot(
                              line_item.hole_slot_engraving_process,
                              'engraving_slots_range'
                            )
                          "
                          :id="`equipment_line_${line_index}_engraving_setting_slot_add_btn`"
                          >{{ $t('common.add') }}</span
                        >
                        <span
                          class="delete"
                          @click="
                            deleteHoleSlot(
                              line_item.hole_slot_engraving_process,
                              'engraving_slots_range',
                              idx
                            )
                          "
                          :id="`equipment_line_${line_index}_engraving_setting_slot_${idx}_delete_btn`"
                          >{{ $t('common.delete') }}</span
                        >
                      </div>
                      <p class="tip mt15 mb0">
                        <!-- 注意：使用以上“加工的特殊孔槽”的功能时请将“通孔打孔方式”和“通槽打穿方式”设置为
                        一次打穿 -->
                        <t-text
                          :text="$t('lineManager.specialHoleTip')"
                          width="900px"
                        ></t-text>
                      </p>
                    </div>
                  </div>
                  <div
                    class="cure-setting-box mt15"
                    v-if="
                      line_item.aio_hole_slot_process.enable_integration_device
                    "
                  >
                    <div class="cure-label ml20">
                      <span class="mr20">
                        <!-- 启用一体机后在雕刻机部分加工的特殊孔槽 -->
                        <t-text
                          :text="$t('lineManager.aioProcessTip2')"
                          width="280px"
                        ></t-text>
                      </span>
                      <span class="tip ml20">
                        <!-- 注意：该功能不兼容启用XY轴互换的雕刻机 请谨慎使用 -->
                        <t-text
                          :text="$t('lineManager.aioProcessTip3')"
                          width="680px"
                        ></t-text>
                      </span>
                    </div>
                    <div
                      class="cure-box pt5 pb5 pr0 pl0 aio-item"
                      style="border: 0px"
                    >
                      <div class="hole-slot_setting">
                        <div
                          v-for="setItem in aioHoleSlotKeys"
                          :key="setItem.key"
                          class="tick-box relative"
                        >
                          <div
                            class="toast-message absolute"
                            :id="`aio_toast_${setItem.key}_${line_item.id}`"
                          >
                            <!-- 此选项对五/六面钻默认模板不生效 -->
                            <t-text
                              :text="$t('lineManager.fiveSixNotTake')"
                              width="250px"
                            ></t-text>
                          </div>
                          <a-checkbox
                            v-model="
                              line_item.aio_hole_slot_process
                                .aio_hole_slot_shunt[setItem.key]
                            "
                            :id="`equipment_line${line_index}_aio_specialHoleSlot_${setItem.key}_checkbox`"
                            @change="handleHoleSlotChange(setItem, line_item)"
                            ><span
                              :id="`equipment_line${line_index}_aio_specialHoleSlot_${setItem.key}_checkbox_label`"
                              ><t-text
                                :text="translateLang(setItem.label)"
                                width="100px"
                              ></t-text></span
                          ></a-checkbox>
                          <a-select
                            showArrow
                            id="aio_special_setting_select"
                            class="special-way mb5"
                            v-if="setItem.key == 'HINGESCREW'"
                            v-model="
                              line_item.aio_hole_slot_process
                                .aio_hole_slot_shunt_way[`HINGE_way`]
                            "
                            @change="
                              handleHoleSlotChange(setItem, line_item, true)
                            "
                          >
                            <template v-for="item in aioSpecialSettingOptions">
                              <a-select-option
                                v-if="
                                  line_item.nowCure != 'frontHoleSlot' ||
                                  (line_item.nowCure == 'frontHoleSlot' &&
                                    item.value != 'back')
                                "
                                :value="item.value"
                                :key="item.value"
                                ><t-text
                                  :text="translateLang(item.lable)"
                                  width="140px"
                                ></t-text
                              ></a-select-option>
                            </template>
                          </a-select>
                          <a-select
                            showArrow
                            id="aio_special_setting_select"
                            class="special-way mb5"
                            v-else-if="
                              setItem.key != 'holes_in_yixing' &&
                              setItem.key != 'slots_in_yixing' &&
                              setItem.key != 'bull_horn_handle'
                            "
                            v-model="
                              line_item.aio_hole_slot_process
                                .aio_hole_slot_shunt_way[`${setItem.key}_way`]
                            "
                            @change="
                              handleHoleSlotChange(setItem, line_item, true)
                            "
                          >
                            <template v-for="item in aioSpecialSettingOptions">
                              <a-select-option
                                v-if="
                                  line_item.nowCure != 'frontHoleSlot' ||
                                  (line_item.nowCure == 'frontHoleSlot' &&
                                    item.value != 'back')
                                "
                                :value="item.value"
                                :key="item.value"
                                ><t-text
                                  :text="translateLang(item.lable)"
                                  width="140px"
                                ></t-text
                              ></a-select-option>
                            </template>
                          </a-select>
                        </div>
                      </div>
                      <div class="pt15">
                        <span class="mr8">
                          <!-- 单孔 -->
                          {{ $t('lineManager.singleHole') }}：
                        </span>
                        <a-select
                          showArrow
                          id="aio_special_setting_select"
                          class="special-way mb5"
                          v-model="
                            line_item.aio_hole_slot_process
                              .aio_hole_slot_shunt_way[`aio_holes_range_way`]
                          "
                        >
                          <template v-for="item in aioSpecialSettingOptions">
                            <a-select-option
                              v-if="
                                line_item.nowCure != 'frontHoleSlot' ||
                                (line_item.nowCure == 'frontHoleSlot' &&
                                  item.value != 'back')
                              "
                              :value="item.value"
                              :key="item.value"
                              ><t-text
                                :text="translateLang(item.lable)"
                                width="140px"
                              ></t-text
                            ></a-select-option>
                          </template>
                        </a-select>
                      </div>
                      <div
                        v-for="(item, idx) in line_item.aio_hole_slot_process
                          .aio_holes_range"
                        :key="idx + 'hole'"
                        class="hole hole-slot"
                      >
                        <!-- 单孔 -->
                        {{ $t('lineManager.singleHole') }}{{ idx + 1 }}
                        <div>
                          <!-- 孔直径范围 -->
                          {{ $t('lineManager.diameterRange') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.aio_hole_slot_process.aio_holes_range[
                                idx
                              ].minDiameter
                            "
                            :id="`equipment_line_${line_index}_aio_setting_hole_diameter_${idx}_start_input`"
                            @input="
                              verifyInput(
                                $event,
                                line_item.aio_hole_slot_process.aio_holes_range[
                                  idx
                                ],
                                ['minDiameter'],
                                '3-2'
                              )
                            "
                          ></el-input>
                          <!-- 到 -->
                          {{ $t('lineManager.to') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.aio_hole_slot_process.aio_holes_range[
                                idx
                              ].maxDiameter
                            "
                            :id="`equipment_line_${line_index}_aio_setting_hole_diameter_${idx}_end_input`"
                            @input="
                              verifyInput(
                                $event,
                                line_item.aio_hole_slot_process.aio_holes_range[
                                  idx
                                ],
                                ['maxDiameter'],
                                '3-2'
                              )
                            "
                          ></el-input>
                        </div>
                        <div>
                          <!-- 孔深度范围 -->
                          {{ $t('lineManager.hDeepRange') }}
                          <el-input
                            size="mini"
                            :id="`equipment_line_${line_index}_aio_setting_hole_deep_${idx}_start_input`"
                            v-model="
                              line_item.aio_hole_slot_process.aio_holes_range[
                                idx
                              ].minDepth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.aio_hole_slot_process.aio_holes_range[
                                  idx
                                ],
                                ['minDepth'],
                                '2-3'
                              )
                            "
                          ></el-input>
                          <!-- 到 -->
                          {{ $t('lineManager.to') }}
                          <el-input
                            size="mini"
                            :id="`equipment_line_${line_index}_aio_setting_hole_deep_${idx}_end_input`"
                            v-model="
                              line_item.aio_hole_slot_process.aio_holes_range[
                                idx
                              ].maxDepth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.aio_hole_slot_process.aio_holes_range[
                                  idx
                                ],
                                ['maxDepth'],
                                '2-3'
                              )
                            "
                          ></el-input>
                        </div>
                        <span
                          class="add"
                          v-if="
                            idx ===
                            line_item.aio_hole_slot_process.aio_holes_range
                              .length -
                              1
                          "
                          @click="
                            addHoleSlot(
                              line_item.aio_hole_slot_process,
                              'aio_holes_range'
                            )
                          "
                          :id="`equipment_line_${line_index}_aio_setting_hole_add_btn`"
                        >
                          <!-- 添加 -->
                          {{ $t('common.add') }}
                        </span>
                        <span
                          class="delete"
                          @click="
                            deleteHoleSlot(
                              line_item.aio_hole_slot_process,
                              'aio_holes_range',
                              idx
                            )
                          "
                          :id="`equipment_line_${line_index}_aio_setting_hole_${idx}_delete_btn`"
                        >
                          <!-- 删除 -->
                          {{ $t('common.delete') }}
                        </span>
                      </div>
                      <div class="pt15">
                        <span class="mr8">
                          <!-- 单槽 -->
                          {{ $t('lineManager.singleSlot') }}：
                        </span>
                        <a-select
                          showArrow
                          id="aio_special_setting_select"
                          class="special-way mb5"
                          v-model="
                            line_item.aio_hole_slot_process
                              .aio_hole_slot_shunt_way[`aio_slots_range_way`]
                          "
                        >
                          <template v-for="item in aioSpecialSettingOptions">
                            <a-select-option
                              v-if="
                                line_item.nowCure != 'frontHoleSlot' ||
                                (line_item.nowCure == 'frontHoleSlot' &&
                                  item.value != 'back')
                              "
                              :value="item.value"
                              :key="item.value"
                              ><t-text
                                :text="translateLang(item.lable)"
                                width="140px"
                              ></t-text
                            ></a-select-option>
                          </template>
                        </a-select>
                      </div>
                      <div
                        v-for="(item, idx) in line_item.aio_hole_slot_process
                          .aio_slots_range"
                        :key="idx + 'slot'"
                        class="slot hole-slot"
                      >
                        <!-- 单槽 -->
                        {{ $t('lineManager.singleSlot') }}{{ idx + 1 }}
                        <div>
                          <!-- 槽宽度范围 -->
                          {{ $t('lineManager.widthRange') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.aio_hole_slot_process.aio_slots_range[
                                idx
                              ].minWidth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.aio_hole_slot_process.aio_slots_range[
                                  idx
                                ],
                                ['minWidth'],
                                '3-2'
                              )
                            "
                            :id="`equipment_line_${line_index}_aio_setting_slot_width_${idx}_start_input`"
                          ></el-input>
                          <!-- 到 -->
                          {{ $t('lineManager.to') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.aio_hole_slot_process.aio_slots_range[
                                idx
                              ].maxWidth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.aio_hole_slot_process.aio_slots_range[
                                  idx
                                ],
                                ['maxWidth'],
                                '3-2'
                              )
                            "
                            :id="`equipment_line_${line_index}_aio_setting_slot_width_${idx}_end_input`"
                          ></el-input>
                        </div>
                        <div>
                          <!-- 槽深度范围 -->
                          {{ $t('lineManager.sDeepRange') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.aio_hole_slot_process.aio_slots_range[
                                idx
                              ].minDepth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.aio_hole_slot_process.aio_slots_range[
                                  idx
                                ],
                                ['minDepth'],
                                '2-3'
                              )
                            "
                            :id="`equipment_line_${line_index}_aio_setting_slot_width_${idx}_start_input`"
                          ></el-input>
                          <!-- 到 -->
                          {{ $t('lineManager.to') }}
                          <el-input
                            size="mini"
                            v-model="
                              line_item.aio_hole_slot_process.aio_slots_range[
                                idx
                              ].maxDepth
                            "
                            @input="
                              verifyInput(
                                $event,
                                line_item.aio_hole_slot_process.aio_slots_range[
                                  idx
                                ],
                                ['maxDepth'],
                                '2-3'
                              )
                            "
                            :id="`equipment_line_${line_index}_aio_setting_slot_deep_${idx}_end_input`"
                          ></el-input>
                        </div>

                        <span
                          class="add"
                          v-if="
                            idx ===
                            line_item.aio_hole_slot_process.aio_slots_range
                              .length -
                              1
                          "
                          @click="
                            addHoleSlot(
                              line_item.aio_hole_slot_process,
                              'aio_slots_range'
                            )
                          "
                          :id="`equipment_line_${line_index}_aio_setting_slot_add_btn`"
                        >
                          <!-- 添加 -->
                          {{ $t('common.add') }}
                        </span>
                        <span
                          class="delete"
                          @click="
                            deleteHoleSlot(
                              line_item.aio_hole_slot_process,
                              'aio_slots_range',
                              idx
                            )
                          "
                          :id="`equipment_line_${line_index}_aio_setting_slot_${idx}_delete_btn`"
                        >
                          <!-- 删除 -->
                          {{ $t('common.delete') }}
                        </span>
                      </div>
                      <p class="tip mt15 mb0">
                        <!-- 注意：使用以上“加工的特殊孔槽”的功能时请将雕刻机“通孔打孔方式”和“通槽打穿方式”设置为
                        一次打穿 -->
                        <t-text
                          :text="$t('lineManager.specialHoleTip2')"
                          width="900px"
                        ></t-text>
                      </p>
                    </div>
                  </div>
                  <!-- TODO 特殊孔槽和异型 -->
                  <div
                    class="cure-setting-box mt15"
                    v-if="
                      false &&
                      line_item.nowCure != 'doubleHoleSlot' &&
                      userInfo.edition_id != 4 &&
                      isShowHoleShunt(line_item)
                    "
                  >
                    <div class="flex">
                      <div class="cure-label ml20 mr14">
                        {{ $t('lineManager.specialHS') }}
                      </div>
                      <div
                        class="cure-box hole-slot-setting pt5 pb5 pr0 pl0"
                        style="border: 0px"
                      >
                        <div
                          v-for="(val, key) in line_item
                            ?.hole_slot_engraving_process
                            ?.engraving_process_setting"
                          :key="key"
                          class="hole-slot-setting_option"
                        >
                          <div v-if="key == 'dock56_drill_still_process'"></div>
                          <a-checkbox
                            v-else-if="key != 'non_hole_slot_of_special_shape'"
                            v-model="
                              line_item.hole_slot_engraving_process
                                .engraving_process_setting[key]
                            "
                            :id="`equipment_line_${line_index}_engraving_setting_process_${key}_checkbox`"
                            ><span
                              :id="`equipment_line_${line_index}_engraving_setting_process_${key}_checkbox_label`"
                              >{{
                                dealKeyName('engraving_process_setting', key)
                              }}</span
                            ></a-checkbox
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    line_item.activeCureEquip === 'side' &&
                    line_item.activeCutEquip === 'engraving'
                  "
                >
                  <div class="cure-label bold">
                    {{ $t('lineManager.sideHoleSetting') }}：
                  </div>
                  <div class="cure-box mt5 pt5 pb5 pr0 pl0">
                    <span class="ml20 mr5"
                      >{{ $t('lineManager.cutHoleSlot') }}:</span
                    >

                    <a-checkbox
                      value="hinge"
                      v-model="line_item.side_machine_hole_slot.HINGE"
                      :id="`equipment_line_${line_index}_engraving_setting_HINGE_checkout`"
                      ><span
                        :id="`equipment_line_${line_index}_engraving_setting_HINGE_checkout_label`"
                        >{{ $t('common.hinge') }}</span
                      ></a-checkbox
                    >
                    <a-checkbox
                      value="strentch"
                      v-model="line_item.side_machine_hole_slot.STRENTCH"
                      :id="`equipment_line_${line_index}_engraving_setting_STRENTCH_checkout`"
                      ><span
                        :id="`equipment_line_${line_index}_engraving_setting_STRENTCH_checkout_label`"
                        >{{ $t('common.stretcher') }}</span
                      ></a-checkbox
                    >
                  </div>
                </div>
              </div>

              <div
                class="cure-setting-box"
                v-if="
                  line_item.nowCure != 'doubleHoleSlot' &&
                  userInfo.edition_id != 4 &&
                  isShowHoleShunt(line_item) &&
                  line_item.activeCureEquip == 'fiveSix'
                "
              >
                <div class="cure-label bold">
                  {{ $t('lineManager.drillSetting') }}：
                </div>
                <div
                  class="cure-box hole-slot-setting w100 mt15 pt5 pb5 pr0 pl0"
                  style="display: block"
                >
                  <div
                    v-for="(val, key) in line_item?.hole_slot_engraving_process
                      ?.engraving_process_setting"
                    :key="key"
                    class="hole-slot-setting_option"
                  >
                    <div v-if="key == 'dock56_drill_still_process'">
                      <span style="font-size: 15px" class="mr10 ml20"
                        ><t-text
                          :text="dealKeyName('engraving_process_setting', key)"
                          width="250px"
                        ></t-text
                        >:</span
                      >

                      <a-radio-group
                        :id="`equipment_line_${line_index}_engraving_setting_process_${key}_radio`"
                        v-model="
                          line_item.hole_slot_engraving_process
                            .engraving_process_setting[key]
                        "
                      >
                        <a-radio
                          v-for="(cure, index) in cuttingSettingOptions"
                          :key="cure.value"
                          :value="cure.value"
                          :id="`equipment_line_${line_index}_engraving_setting_process_${key}_radio_option_${index}`"
                          >{{ translateLang(cure.label) }}</a-radio
                        >
                      </a-radio-group>

                      <p
                        v-if="
                          line_item.hole_slot_engraving_process
                            .engraving_process_setting[key] == '2'
                        "
                        class="warning-tip"
                      >
                        {{ $t('lineManager.cutTip') }}
                      </p>
                    </div>
                  </div>
                  <div class="mt10">
                    <span class="mr10 ml20 fs15"
                      ><t-text
                        :text="translateLang('lineManager.strenchType')"
                        width="250px"
                      ></t-text
                      >:</span
                    >
                    <a-radio-group
                      :id="`equipment_line_${line_index}_engraving_setting_process_specialSlot_radio`"
                      v-model="line_item.is_strentch_as_special_slot"
                    >
                      <a-radio
                        v-for="(cure, index) in specialSlots"
                        :key="cure.value"
                        :value="cure.value"
                        :id="`equipment_line_${line_index}_engraving_setting_process_specialSlot_radio_option_${index}`"
                        >{{ translateLang(cure.label) }}</a-radio
                      >
                    </a-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="combination_setting"
            v-if="line_item.showCombinationSetting"
          >
            <div
              class="combination_setting_title"
              @click="combinSeting(line_item)"
            >
              <a-icon
                :type="line_item.showCombin ? 'caret-down' : 'caret-right'"
              />
              <span>{{ $t('lineManager.combinSetting') }}</span>
            </div>
            <div class="combination_setting_box" v-if="line_item.showCombin">
              <div class="choose_equipment_box">
                <div class="tip mb16">
                  {{ $t('lineManager.warningTip') }}
                </div>
                <div class="choose_equipment">
                  <div>
                    <span>{{ $t('lineManager.equipment1') }}：</span>
                    <a-select
                      style="width: 160px"
                      v-model="
                        line_item.combinationEquip_settings.firstEquipment
                      "
                    >
                      <a-select-option
                        :value="com_item.value"
                        v-for="(com_item, com_index) in com_equipments"
                        :key="com_index"
                        :disabled="
                          line_item.combinationEquip_settings.secondEquipment ==
                            com_item.value ||
                          (line_item.combinationEquip_settings
                            .secondEquipment == 'engraving' &&
                            com_item.value == 'sideHoleDrill') ||
                          (line_item.combinationEquip_settings
                            .secondEquipment == 'sideHoleDrill' &&
                            com_item.value == 'engraving') ||
                          (line_item.activeCutEquip == 'saw' &&
                            com_item.value == 'engraving')
                        "
                      >
                        {{ $t(com_item.name) }}
                      </a-select-option>
                    </a-select>
                    <span
                      class="choose_equipment_setting_btn"
                      @click="
                        toSetting(
                          line_item.combinationEquip_settings.firstEquipment,
                          line_item
                        )
                      "
                      >{{ $t('main.head.setting') }}</span
                    >
                  </div>
                  <span><i class="iconfont icon-add"></i></span>
                  <div>
                    <span>{{ $t('lineManager.equipment2') }}：</span>
                    <a-select
                      style="width: 160px"
                      v-model="
                        line_item.combinationEquip_settings.secondEquipment
                      "
                    >
                      <a-select-option
                        :value="com_item.value"
                        v-for="(com_item, com_index) in com_equipments"
                        :key="com_index"
                        :disabled="
                          line_item.combinationEquip_settings.firstEquipment ==
                            com_item.value ||
                          (line_item.combinationEquip_settings.firstEquipment ==
                            'engraving' &&
                            com_item.value == 'sideHoleDrill') ||
                          (line_item.combinationEquip_settings.firstEquipment ==
                            'sideHoleDrill' &&
                            com_item.value == 'engraving') ||
                          (line_item.activeCutEquip == 'saw' &&
                            com_item.value == 'engraving')
                        "
                      >
                        {{ translateLang(com_item.name) }}
                      </a-select-option>
                    </a-select>
                    <span
                      class="choose_equipment_setting_btn"
                      @click="
                        toSetting(
                          line_item.combinationEquip_settings.secondEquipment,
                          line_item
                        )
                      "
                      >{{ $t('main.head.setting') }}</span
                    >
                  </div>
                  <img
                    src="../../assets/icon/equipment_exchange.png"
                    alt=""
                    @click="changComEquipment(line_item)"
                  />
                </div>
                <div class="choose_equipment_msg">
                  {{ $t('lineManager.noSameTip') }}
                </div>
              </div>
              <div class="equipment_setting">
                <div class="equipment_setting_title">
                  {{ $t('lineManager.workRange') }}
                </div>
                <div class="holes_slots_setting">
                  <a-checkbox
                    v-model="
                      line_item.combinationEquip_settings.specialHoles.enable
                    "
                    >{{ $t('lineManager.singleHole') }}</a-checkbox
                  >
                  <div>
                    <span>{{ $t('lineManager.diameterRange') }}：</span>
                    <a-input-number
                      :disabled="
                        !line_item.combinationEquip_settings.specialHoles.enable
                      "
                      :min="0"
                      v-model="
                        line_item.combinationEquip_settings.specialHoles
                          .minDiameter
                      "
                    />
                    <span
                      style="display: inline-block; padding: 0 5px; color: #999"
                      >{{ $t('lineManager.to') }}</span
                    >
                    <a-input-number
                      :disabled="
                        !line_item.combinationEquip_settings.specialHoles.enable
                      "
                      :min="0"
                      ref="maxHolesWidth"
                      v-model="
                        line_item.combinationEquip_settings.specialHoles
                          .maxDiameter
                      "
                    />
                  </div>
                  <div>
                    <span>{{ $t('lineManager.hDeepRange') }}：</span>
                    <a-input-number
                      :disabled="
                        !line_item.combinationEquip_settings.specialHoles.enable
                      "
                      :min="0"
                      v-model="
                        line_item.combinationEquip_settings.specialHoles
                          .minDepth
                      "
                    />
                    <span
                      style="display: inline-block; padding: 0 5px; color: #999"
                      >{{ $t('lineManager.to') }}</span
                    >
                    <a-input-number
                      :disabled="
                        !line_item.combinationEquip_settings.specialHoles.enable
                      "
                      :min="0"
                      ref="maxHolesDepth"
                      v-model="
                        line_item.combinationEquip_settings.specialHoles
                          .maxDepth
                      "
                    />
                  </div>
                </div>
                <div class="holes_slots_setting">
                  <a-checkbox
                    v-model="
                      line_item.combinationEquip_settings.specialSlots.enable
                    "
                    >{{ $t('lineManager.singleSlot') }}</a-checkbox
                  >
                  <div>
                    <span>{{ $t('lineManager.widthRange') }}：</span>
                    <a-input-number
                      :disabled="
                        !line_item.combinationEquip_settings.specialSlots.enable
                      "
                      :min="0"
                      v-model="
                        line_item.combinationEquip_settings.specialSlots
                          .minWidth
                      "
                    />
                    <span
                      style="display: inline-block; padding: 0 5px; color: #999"
                      >{{ $t('lineManager.to') }}</span
                    >
                    <a-input-number
                      :disabled="
                        !line_item.combinationEquip_settings.specialSlots.enable
                      "
                      :min="0"
                      ref="maxSlotsWidth"
                      v-model="
                        line_item.combinationEquip_settings.specialSlots
                          .maxWidth
                      "
                    />
                  </div>
                  <div>
                    <span>{{ $t('lineManager.sDeepRange') }}：</span>
                    <a-input-number
                      :disabled="
                        !line_item.combinationEquip_settings.specialSlots.enable
                      "
                      :min="0"
                      v-model="
                        line_item.combinationEquip_settings.specialSlots
                          .minDepth
                      "
                    />
                    <span
                      style="display: inline-block; padding: 0 5px; color: #999"
                      >{{ $t('lineManager.to') }}</span
                    >
                    <a-input-number
                      :disabled="
                        !line_item.combinationEquip_settings.specialSlots.enable
                      "
                      :min="0"
                      ref="maxSlotsDepth"
                      v-model="
                        line_item.combinationEquip_settings.specialSlots
                          .maxDepth
                      "
                    />
                  </div>
                </div>
                <div class="choose_equipment_msg">
                  {{ $t('lineManager.rangeWarning') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="h100 flex flex-cross--center flex-main--center">
      <a-empty style="height: 150px"></a-empty>
    </div>
    <g-base-modal
      :visible="showDeleteConfirm"
      v-if="showDeleteConfirm"
      @ok="confirmDelete"
      @cancel="showDeleteConfirm = false"
      :contain="$t('lineManager.deleteWarning')"
    >
    </g-base-modal>
    <div class="back" ref="back" @click="scrollWarp(true)">
      <a-icon type="arrow-up" />
      <span>{{ $t('lineManager.top') }}</span>
    </div>
    <m-lock-modal
      :visible.sync="isShowVisible"
      :status.sync="lockStatus"
      :tempStatus.sync="tempStatus"
      :verifyPassword="verifyPassword"
      :lineName="currentItem?.name"
      @getLockPassword="handleGetLockPassword"
    ></m-lock-modal>
  </div>
</template>

<script>
import { getLockStatus } from '@/apis/productionLineLock'
import GBaseModal from '@/components/g-base-modal.vue'
import store from '@/store'
import { translate } from '@/util/commonFun'
import { buryPointApi, genFinalRecord } from '@/util/commonFuncs'
import {
  genUniId,
  thirdPermission,
  verifyInputNumber,
} from '@/util/commonFuncs.js'
import { operateRecordTableType } from '@/util/configure'
import { webId } from '@/util/configure'
import { equipmentFormTextObj } from '@/util/operateRecordConfigure/equipmentConfigure'
import { saveDataJsonToOssForRecord } from '@/util/saveFile'
import MLockModal from '@/views/equipment/component/m-lock-modal.vue'
import { mapMutations } from 'vuex'

const keyName = {
  special_hole_slot: {
    HINGESCREW: '铰链孔',
    HINGE: '铰链孔',
    HANGER: '衣通孔',
    STRENTCH: '拉直器槽',
    BP: '背板槽',
    ALS: '暗拉手槽',
    METAL: '门板金属条槽',
    DMBS: '抽屉金属条槽',
    in_range_all: '允许短边尺寸',
    KUCHOU: '裤抽孔',
    YXJ: '隐形件',
    holes_in_yixing: '异形板上所有孔',
    slots_in_yixing: '异形板所有槽',
    bull_horn_handle: '牛角拉手',
  },
  special_hole_slot_way: {
    HINGESCREW: '铰链孔',
    HINGE: '铰链孔',
    HANGER: '衣通孔',
    STRENTCH: '拉直器槽',
    BP: '背板槽',
    ALS: '暗拉手槽',
    METAL: '门板金属条槽',
    DMBS: '抽屉金属条槽',
    in_range_all: '允许短边尺寸',
    KUCHOU: '裤抽孔',
    YXJ: '隐形件',
    holes_in_yixing: '异形板上所有孔',
    slots_in_yixing: '异形板所有槽',
    bull_horn_handle: '牛角拉手',
  },
  engraving_process_setting: {
    allow_turn_plate_process: 'lineManager.specialHS',
    dock56_drill_still_process: 'lineManager.engravingCuted',
    non_hole_slot_of_special_shape: 'lineManager.engravingPath',
    short_edge_range: 'lineManager.maxShortSize',
    long_edge_range: 'lineManager.maxLongSize',
  },
}
export default {
  computed: {
    canEdit() {
      return (lists, value) => {
        return !lists.includes(value)
      }
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    isAddNewLine() {
      let flag = false
      switch (this.userInfo.edition_id) {
        case 5:
          flag = true
          break
        case 6:
          flag = true
          break
        case 12:
          if (this.produceLine.length < 4) {
            flag = true
          }
          break
      }
      return flag
    },
    dealKeyName() {
      return (obj, key) => {
        return keyName[obj][key] ? translate(keyName[obj][key]) : 'error'
      }
    },
    isShowHoleShunt() {
      return (lineItem) => {
        const d = lineItem.cureList.some(
          (item) =>
            item.show && (item.value == 'fiveSix' || item.value == 'ptp')
        )
        return d
      }
    },
  },
  data() {
    return {
      verifyPassword: false,
      isShowVisible: false,
      lockStatus: 1, // 锁状态
      tempStatus: 1, //默认状态
      initLockStatus: 0,
      isShowToast: false,
      showPageLoading: false,
      com_equipments: [
        {
          value: 'dock56FDrill',
          name: 'common.FSDrill',
        },
        {
          value: 'engraving',
          name: 'common.engraving2',
        },
        {
          value: 'sideHoleDrill',
          name: 'common.sideHoleDrill',
        },
        {
          value: 'ptpHoleDrill',
          name: 'common.PTP',
        },
      ],
      // 生产线数据
      produceLine: [],
      // 通过接口获取到的生产线原始数据
      oriProduceLine: [],
      cureCheckList: [
        {
          name: 'lineManager.onlyCut',
          value: 'noHoleSlot',
        },
        {
          name: 'lineManager.onlyFrontHoleSlot',
          value: 'frontHoleSlot',
        },
        {
          name: 'lineManager.onlyHoleSlot',
          value: 'doubleHoleSlot',
        },
      ],
      // 记录当前点击生产线
      nowLine: -1,
      // 记录当前点击的生产线数据
      currentLine: {},
      // 显示删除确认框
      showDeleteConfirm: false,
      // 记录获取到的生产线数据
      productLineList: [],
      // 记录新建的生产线编号
      lineIndex: 0,
      // 记录是否已经出发修改名字的事件, 防止失焦事件和回车事件同时触发
      isEditName: true,
      // 是否是从天工官网进入
      showTiangongPage: false,
      // 记录是否隐藏添加生产线
      showNewProBtn: true,
      // 当前选中的生产线
      currentProductLine: {},
      currentSaveLineId: '', // 当前保存生产线的id
      // 开料机设置-加工设置生成与否选项
      isDock56DrillStillProcess: 0,
      currentItem: null,
      currentLineIndex: null,

      cuttingSettingOptions: [
        {
          value: 0,
          label: 'lineManager.noGen',
        },
        {
          value: 1,
          label: 'lineManager.genCut',
        },
        {
          value: 2,
          label: 'lineManager.genNoCut',
        },
      ],
      specialSlots: [
        {
          value: false,
          label: 'lineManager.normalSlot',
        },
        {
          value: true,
          label: 'lineManager.specialSlot',
        },
      ],
      holeSlotKeys: [
        {
          key: 'HINGESCREW',
          label: 'common.hinge',
        },
        {
          key: 'HANGER',
          label: 'common.clothole',
        },
        {
          key: 'STRENTCH',
          label: 'common.stretcher',
        },
        {
          key: 'BP',
          label: 'common.backPlank',
        },
        {
          key: 'ALS',
          label: 'common.ALS',
        },
        {
          key: 'METAL',
          label: 'common.doorSlot',
        },
        {
          key: 'DMBS',
          label: 'common.drawerSlot',
        },

        {
          key: 'KUCHOU',
          label: 'common.KC',
        },
        {
          key: 'YXJ',
          label: 'common.stealth',
        },
        {
          key: 'gratingSlot',
          label: 'common.gratingSlot',
        },
        {
          key: 'holes_in_yixing',
          label: 'common.allHole',
        },
        {
          key: 'slots_in_yixing',
          label: 'common.allSlot',
        },
        {
          key: 'bull_horn_handle',
          label: 'common.millInfo',
        },
      ],
      aioHoleSlotKeys: [
        {
          key: 'ALS',
          label: 'common.ALS',
        },
        {
          key: 'BP',
          label: 'common.backPlank',
        },
        {
          key: 'METAL',
          label: 'common.doorSlot',
        },
        {
          key: 'DMBS',
          label: 'common.drawerSlot',
        },
        {
          key: 'STRENTCH',
          label: 'common.stretcher',
        },
        {
          key: 'gratingSlot',
          label: 'common.gratingSlot',
        },
      ],
      specialSettingOptions: [
        { lable: 'lineManager.onlyFrontSide', value: 'front' },
        { lable: 'lineManager.onlyBackSide', value: 'back' },
        { lable: 'lineManager.bothFrontBack', value: 'front_back' },
      ],
      aioSpecialSettingOptions: [
        { lable: 'lineManager.onlyFrontSide', value: 'front' },
        // { lable: '仅加工反面', value: 'back' },
        // { lable: '加工正面和反面', value: 'front_back' },
      ],
      // 是否加载数据中
      isLoading: false,
      isQT: window.webToQt,
      isAio: true,
    }
  },
  methods: {
    ...mapMutations(['setProLineList']),
    translateLang(key) {
      return translate(key)
    },
    async handleGetLockPassword(password) {
      this.lockPassword = password
      await this.saveLine(this.currentItem, this.currentLineIndex)
    },
    // 验证输入框输入, 新增需求长边限制允许输入为空，兼容其他使用地方，传入默认参数
    verifyInput(
      val,
      obj,
      keys,
      rule,
      isMinus = false,
      min = 0,
      isEmpty = false
    ) {
      verifyInputNumber(val, obj, keys, rule, isMinus, min, isEmpty)
    },
    changComEquipment(line) {
      let str = line.combinationEquip_settings.firstEquipment
      line.combinationEquip_settings.firstEquipment =
        line.combinationEquip_settings.secondEquipment
      line.combinationEquip_settings.secondEquipment = str
    },
    toSetting(val, line) {
      let path = ''
      switch (val) {
        case 'dock56FDrill':
          path = 'dockFiveSix'
          break
        case 'engraving':
          path = 'engraving'
          break
        case 'sideHoleDrill':
          path = 'sideHole'
          break
        case 'ptpHoleDrill':
          path = 'bluenPtp'
          break
      }
      buryPointApi('equipment_dock', 'combination')
      this.$router.push({
        path,
        query: {
          setting_id: line.id,
          line: line.name,
        },
      })
    },
    // 检测组合设备输入框值
    detectComValue(item) {
      let specialHoles = item.combinationEquip_settings.specialHoles
      let specialSlots = item.combinationEquip_settings.specialSlots
      let req = {
        msg: '',
        flag: false,
        ref: '',
      }
      if (specialHoles.enable) {
        if (specialHoles.minDiameter > specialHoles.maxDiameter) {
          req.flag = true
          req.msg = this.$t('lineManager.diameterRange')
          req.ref = 'maxHolesWidth'
          return req
        }
        if (specialHoles.minDepth > specialHoles.maxDepth) {
          req.flag = true
          req.msg = this.$t('lineManager.hDeepRange')
          req.ref = 'maxHolesDepth'
          return req
        }
      }
      if (specialSlots.enable) {
        if (specialSlots.minWidth > specialSlots.maxWidth) {
          req.flag = true
          req.msg = this.$t('lineManager.widthRange')
          req.ref = 'maxSlotsWidth'
          return req
        }
        if (specialSlots.minDepth > specialSlots.maxDepth) {
          req.flag = true
          req.msg = this.$t('lineManager.sDeepRange')
          req.ref = 'maxSlotsDepth'
          return req
        }
      }
      return req
    },
    // 生成比较组合设备对应的form值
    genCombinedEquipmentSettingsForm(item, oriItem) {
      const currentCombinationEquipSettings = item.combinationEquip_settings
      const currentSpecialHoles = currentCombinationEquipSettings.specialHoles
      const currentSpecialSlots = currentCombinationEquipSettings.specialSlots
      const currentEngravingProcess =
        item.hole_slot_engraving_process.engraving_process_setting
      const currentShortEdgeRange =
        item.hole_slot_engraving_process.special_hole_slot.short_edge_range
      const currentLongEdgeRange =
        item.hole_slot_engraving_process.special_hole_slot.long_edge_range

      // 生成current，只包含必要的参数
      const current = {
        firstEquipment: currentCombinationEquipSettings.firstEquipment,
        secondEquipment: currentCombinationEquipSettings.secondEquipment,
        specialHoles_enable: currentSpecialHoles.enable,
        specialHoles_diameter: [
          currentSpecialHoles.minDiameter,
          currentSpecialHoles.maxDiameter,
        ],
        specialHoles_deep: [
          currentSpecialHoles.minDepth,
          currentSpecialHoles.maxDepth,
        ],

        specialSlots_enable: currentSpecialSlots.enable,
        specialSlots_width: [
          currentSpecialSlots.minWidth,
          currentSpecialSlots.maxWidth,
        ],
        specialSlots_deep: [
          currentSpecialSlots.minDepth,
          currentSpecialSlots.maxDepth,
        ],
        allow_turn_plate_process:
          currentEngravingProcess.allow_turn_plate_process,
        dock56_drill_still_process:
          currentEngravingProcess.dock56_drill_still_process,
        non_hole_slot_of_special_shape:
          currentEngravingProcess.non_hole_slot_of_special_shape,
        short_edge_range: currentShortEdgeRange,
        long_edge_range: currentLongEdgeRange,
        is_strentch_as_special_slot:
          currentEngravingProcess.is_strentch_as_special_slot,
      }
      const oriCombinationEquipSettings = oriItem.combinationEquip_settings
      const oriSpecialHoles = oriCombinationEquipSettings.specialHoles
      const oriSpecialSlots = oriCombinationEquipSettings.specialSlots
      const oriEngravingProcess =
        oriItem.hole_slot_engraving_process.engraving_process_setting
      const oriShortEdgeRange =
        oriItem.hole_slot_engraving_process.special_hole_slot.short_edge_range
      const oriLongEdgeRange =
        oriItem.hole_slot_engraving_process.special_hole_slot.long_edge_range
      // 生成ori，只包含必要的参数
      const ori = {
        firstEquipment: oriCombinationEquipSettings.firstEquipment,
        secondEquipment: oriCombinationEquipSettings.secondEquipment,
        specialHoles_enable: oriSpecialHoles.enable,
        specialHoles_diameter: [
          oriSpecialHoles.minDiameter,
          oriSpecialHoles.maxDiameter,
        ],
        specialHoles_deep: [oriSpecialHoles.minDepth, oriSpecialHoles.maxDepth],

        specialSlots_enable: oriSpecialSlots.enable,
        specialSlots_width: [
          oriSpecialSlots.minWidth,
          oriSpecialSlots.maxWidth,
        ],
        specialSlots_deep: [oriSpecialSlots.minDepth, oriSpecialSlots.maxDepth],
        allow_turn_plate_process: oriEngravingProcess.allow_turn_plate_process,
        dock56_drill_still_process:
          oriEngravingProcess.dock56_drill_still_process,
        non_hole_slot_of_special_shape:
          oriEngravingProcess.non_hole_slot_of_special_shape,
        short_edge_range: oriShortEdgeRange,
        long_edge_range: oriLongEdgeRange,
        is_strentch_as_special_slot:
          oriEngravingProcess.is_strentch_as_special_slot,
      }
      return {
        current,
        ori,
      }
    },
    genCompareTableData(current, ori) {
      // debugger
      const newHoleList =
        current.hole_slot_engraving_process.engraving_holes_range
      const newAioHoleList = current.aio_hole_slot_process.aio_holes_range

      newHoleList.forEach((item, index) => {
        item.name = `单孔${index + 1}`
      })
      newAioHoleList.forEach((item, index) => {
        item.name = `单孔${index + 1}`
      })

      // 原始孔列表
      const oriHoleList = ori.hole_slot_engraving_process.engraving_holes_range
      const oriAioHoleList = ori.aio_hole_slot_process.aio_holes_range

      oriHoleList.forEach((item) => {
        item.name = `单孔${item.id + 1}`
      })
      oriAioHoleList.forEach((item) => {
        item.name = `单孔${item.id + 1}`
      })

      // 新的槽列表
      const newSlotList =
        current.hole_slot_engraving_process.engraving_slots_range
      const newAioSlotList = current.aio_hole_slot_process.aio_slots_range

      newSlotList.forEach((item, index) => {
        item.name = `单槽${index + 1}`
      })
      newAioSlotList.forEach((item, index) => {
        item.name = `单槽${index + 1}`
      })

      // 原始槽列表
      const oriSlotList = ori.hole_slot_engraving_process.engraving_slots_range
      const oriAioSlotList = ori.aio_hole_slot_process.aio_slots_range

      oriSlotList.forEach((item) => {
        // 给删除项确定名字
        item.name = `单槽${item.id + 1}`
      })
      oriAioSlotList.forEach((item) => {
        // 给删除项确定名字
        item.name = `单槽${item.id + 1}`
      })

      return {
        type: 'table',
        list: current.aio_hole_slot_process.enable_integration_device
          ? [...newAioHoleList, ...newAioSlotList]
          : [...newHoleList, ...newSlotList],
        oriList: current.aio_hole_slot_process.enable_integration_device
          ? [...oriAioHoleList, ...oriAioSlotList]
          : [...oriHoleList, ...oriSlotList],
        keys: [
          'minDepth',
          'maxDepth',
          'minDiameter',
          'maxDiameter',
          'minWidth',
          'maxWidth',
          'short_edge_range',
          'long_edge_range',
        ], // 列表要比较的keys
        tableType: operateRecordTableType.HOLE_SLOT_TABLE, // table 的 type
        key: '开料机设置-在开料机上加工的孔槽', // 表格对应的key
      }
    },
    genCompareFormData(current, ori) {
      // 开料设备对应的value值
      const activeCutEquipValueObj = {}
      current.cutList.forEach((child) => {
        activeCutEquipValueObj[child.value] = child.name
      })
      // 孔槽加工设备对应的value值
      const activeCureEquipValueObj = {}
      current.cureList.forEach((child) => {
        activeCureEquipValueObj[child.value] = child.name
      })
      // 开料机加工方式对应的value值
      const nowCureValueObj = {}
      this.cureCheckList.forEach((child) => {
        nowCureValueObj[child.value] = child.name
      })
      // 设备一和设备二对应的value值
      const equipValueObj = {}
      this.com_equipments.forEach((child) => {
        equipValueObj[child.value] = child.name
      })
      const compareMsg = [
        {
          title: '',
          keys: [
            'activeCutEquip',
            'activeCureEquip',
            'nowCure',
            'special_hole_slot',
            'special_hole_slot_way',
            'aio_hole_slot_shunt',
            'aio_hole_slot_shunt_way',
            // 'engraving_process_setting',
            'firstEquipment',
            'secondEquipment',
            'side_machine_hole_slot',
          ],
        },
        {
          title: '设备一可加工孔槽范围',
          keys: [
            'specialHoles_enable',
            'specialHoles_diameter',
            'specialHoles_deep',
            'specialSlots_enable',
            'specialSlots_width',
            'specialSlots_deep',
          ],
        },
        {
          title: '开料机设置-加工设置',
          keys: [
            'non_hole_slot_of_special_shape',
            'dock56_drill_still_process',
            'allow_turn_plate_process',
            'short_edge_range',
            'long_edge_range',
            'is_strentch_as_special_slot',
          ],
        },
      ]

      return {
        type: 'form',
        current,
        ori,
        compareMsg,
        formTextObj: equipmentFormTextObj,
        formValueObj: {
          activeCutEquip: activeCutEquipValueObj,
          activeCureEquip: activeCureEquipValueObj,
          nowCure: nowCureValueObj,
          firstEquipment: equipValueObj,
          secondEquipment: equipValueObj,
          dock56_drill_still_process: {
            0: '不生成',
            1: '生成并加工',
            2: '生成但不加工',
          },
          is_strentch_as_special_slot: {
            false: '普通槽',
            true: '特殊槽',
          },
          aio_hole_slot_shunt: {
            HINGESCREW: '铰链孔',
            HINGE: '铰链孔',
            HANGER: '衣通孔',
            STRENTCH: '拉直器槽',
            BP: '背板槽',
            ALS: '暗拉手槽',
            METAL: '门板金属条槽',
            DMBS: '抽屉金属条槽',
            in_range_all: '允许短边尺寸',
            KUCHOU: '裤抽孔',
            YXJ: '隐形件',
            holes_in_yixing: '异形板上所有孔',
            slots_in_yixing: '异形板所有槽',
            bull_horn_handle: '牛角拉手',
          },
          aio_hole_slot_shunt_way: {
            ALS_way: '暗拉手槽',
            BP_way: '背板槽',
            DMBS_way: '抽屉金属条槽',
            METALINNER_way: '门板金属条槽',
            METAL_way: '门板金属条槽',
            MIDSTRENTCH_way: '拉直器槽',
            STRENTCHTWO_way: '拉直器槽',
            STRENTCH_way: '拉直器槽',
            aio_holes_range_way: '单孔',
            aio_slots_range_way: '单槽',
            gratingSlot_way: '格栅槽',
            normalSlot_way: '格栅槽',
          },
        },
      }
    },
    // 生成最终的操作记录
    genFinalRecordFn(current, ori) {
      // debugger
      let recordData = {
        key: '', // 操作记录最外层的名字，比如：雕刻机
        operateArr: [
          // 应对多个操作页面时，比如雕刻机的操作页面
          {
            key: '',
            dataArr: [
              // 分为表单和table两种
              this.genCompareFormData(current, ori),
              this.genCompareTableData(current, ori),
            ],
          },
        ],
      }
      genFinalRecord(
        recordData,
        'equipment_docking',
        this.currentSaveLineId,
        () => {
          // 更新原始数据
          current.data.hole_slot_engraving_process.engraving_holes_range.forEach(
            (child, index) => {
              delete child.operate_type
              child.id = index
            }
          )
          current.data.hole_slot_engraving_process.engraving_slots_range.forEach(
            (child, index) => {
              delete child.operate_type
              child.id = index
            }
          )
          current.data.aio_hole_slot_process.aio_holes_range.forEach(
            (child, index) => {
              delete child.operate_type
              child.id = index
            }
          )
          current.data.aio_hole_slot_process.aio_slots_range.forEach(
            (child, index) => {
              delete child.operate_type
              child.id = index
            }
          )
          this.oriProduceLine = JSON.parse(JSON.stringify(this.produceLine))
        }
      )
    },

    genObjTrueValue(obj, type) {
      let keys = Object.keys(obj)
      const arr = []
      keys.forEach((key) => {
        if (obj[key] && keyName[type][key]) {
          arr.push(keyName[type][key])
        }
      })
      return arr
    },
    // 当前生产线 开料设备和孔槽设备的当前显示
    getCutCurShow(lineItem) {
      const cureObj = lineItem.cureList.find((item) => {
        if (item.show) {
          return item.value
        }
      })
      const cutObj = lineItem.cutList.find((item) => {
        if (item.show) {
          return item.value
        }
      })
      const cutCurshowArr = [cutObj.value, cureObj.value]
      return cutCurshowArr
    },
    // 保存设置
    saveLine(item, lineIndex) {
      const dock56FDrillShow = item.cureList.find(
        (v) => v.value == 'fiveSix'
      ).show
      // 进行权限验证
      const selectEquipment = [
        {
          show: dock56FDrillShow,
          isVerifyPath: '/dockFiveSix',
        },
      ]
      const isPass = selectEquipment.some(
        (it) => it.show && !thirdPermission(it.isVerifyPath)
      )
      if (isPass) return

      this.currentSaveLineId = item.id
      // 组合设备时判断输入框值，最大值不能小于最小值
      if (item.activeCureEquip == 'combination') {
        let detectResult = this.detectComValue(item)
        if (detectResult.flag) {
          this.$message({
            message: this.$t('lineManager.inputCheck', {
              msg: detectResult.msg,
            }),
            type: 'error',
            duration: 2000,
          })
          this.$refs[detectResult.ref][0].focus()
          return
        }
      }
      // 如果保存的时候选择的是加工双面孔槽, 则清空正其余选项的设置
      if (
        item.nowCure == 'doubleHoleSlot' ||
        item.nowCure == 'DoubleHoleSlot'
      ) {
        item.cuttingSetting = []
      }
      let equip = ''
      item.cutList.forEach((v) => {
        if (v.value == 'engraving' && v.show == true) {
          equip = 'engraving'
        } else if (v.value == 'saw' && v.show == true) {
          equip = 'electronicSaw'
        } else if (v.value == 'glassEquip' && v.show == true) {
          equip = 'glassEquip'
        }
      })
      equip = equip ? equip : 'engraving'
      let obj = {
        STRENTCHTWO:
          item.hole_slot_engraving_process.special_hole_slot.STRENTCH,
        MIDSTRENTCH:
          item.hole_slot_engraving_process.special_hole_slot.STRENTCH,
        METALINNER: item.hole_slot_engraving_process.special_hole_slot.METAL,

        // 格栅前端使用的gratingSlot，后端要求再传入一个normalSlot字段与gratingSlot值相同
        normalSlot:
          item.hole_slot_engraving_process.special_hole_slot.gratingSlot,
      }
      item.hole_slot_engraving_process.special_hole_slot = Object.assign(
        item.hole_slot_engraving_process.special_hole_slot,
        obj
      )
      let wayObj = {
        STRENTCHTWO_way:
          item.hole_slot_engraving_process.special_hole_slot_way.STRENTCH_way,
        MIDSTRENTCH_way:
          item.hole_slot_engraving_process.special_hole_slot_way.STRENTCH_way,
        METALINNER_way:
          item.hole_slot_engraving_process.special_hole_slot_way.METAL_way,
        normalSlot_way:
          item.hole_slot_engraving_process.special_hole_slot_way
            .gratingSlot_way,
      }
      item.hole_slot_engraving_process.special_hole_slot_way = Object.assign(
        item.hole_slot_engraving_process.special_hole_slot_way,
        wayObj
      )

      let aioObj = {
        STRENTCHTWO: item.aio_hole_slot_process.aio_hole_slot_shunt.STRENTCH,
        MIDSTRENTCH: item.aio_hole_slot_process.aio_hole_slot_shunt.STRENTCH,
        METALINNER: item.aio_hole_slot_process.aio_hole_slot_shunt.METAL,

        // 格栅前端使用的gratingSlot，后端要求再传入一个normalSlot字段与gratingSlot值相同
        normalSlot: item.aio_hole_slot_process.aio_hole_slot_shunt.gratingSlot,
      }
      item.aio_hole_slot_process.aio_hole_slot_shunt = Object.assign(
        item.aio_hole_slot_process.aio_hole_slot_shunt,
        aioObj
      )
      let aiowayObj = {
        STRENTCHTWO_way:
          item.aio_hole_slot_process.aio_hole_slot_shunt_way.STRENTCH_way,
        MIDSTRENTCH_way:
          item.aio_hole_slot_process.aio_hole_slot_shunt_way.STRENTCH_way,
        METALINNER_way:
          item.aio_hole_slot_process.aio_hole_slot_shunt_way.METAL_way,
        normalSlot_way:
          item.aio_hole_slot_process.aio_hole_slot_shunt_way.gratingSlot_way,
      }
      item.aio_hole_slot_process.aio_hole_slot_shunt_way = Object.assign(
        item.aio_hole_slot_process.aio_hole_slot_shunt_way,
        aiowayObj
      )

      let data = {
        ...this.$store.state.saveInfo,
        setting_id: item.id + '',
        platform: 'bluen',
        settings: {
          dock6FDrill_partHole: item.cuttingSetting.includes(
            'dock6FDrill_partHole'
          ),
          dock56FDrill_partHole: item.cuttingSetting.includes(
            'dock56FDrill_partHole'
          ),
          dock56FDrill_partSlots: item.cuttingSetting.includes(
            'dock56FDrill_partSlots'
          ),
          dock56FDrill_partSlot: item.cuttingSetting.includes(
            'dock56FDrill_partSlot'
          ),
          dock56FDrill_excludeSlots: item.cuttingSetting.includes(
            'dock56FDrill_excludeSlots'
          ),
          cuttingEquipment: equip,
          side_machine_hole_slot: {
            HINGE: item.side_machine_hole_slot.HINGE,
            STRENTCH: item.side_machine_hole_slot.STRENTCH,
            horn_slot: item.side_machine_hole_slot.horn_slot,
          },
          // dock5FDrill: item.cureList.find(v => v.value == "five").show,
          // dock6FDrill: item.cureList.find(v => v.value == "six").show,
          dock56FDrill: dock56FDrillShow,
          ncHoleSlot: item.nowCure,
          ptpHoleDrill: item.cureList.find((v) => v.value == 'ptp').show,
          sideHoleDrill: item.haveSetted
            ? item.cureList.find((v) => v.value == 'side').show
            : false,
          combinationEquip_settings: item.combinationEquip_settings,
          combinationEquip:
            item.activeCureEquip == 'combination' ? true : false,
          hole_slot_engraving_process: item.hole_slot_engraving_process,
          aio_hole_slot_process: item.aio_hole_slot_process,
          new_engraving_set_flag:
            item.cutList.find((item) => item.value == 'engraving').show &&
            (item.cureList.find((item) => item.value == 'fiveSix').show ||
              item.cureList.find((item) => item.value == 'ptp').show),
          is_strentch_as_special_slot: item.is_strentch_as_special_slot,
        },
      }
      let equipments = [
        item.combinationEquip_settings.firstEquipment,
        item.combinationEquip_settings.secondEquipment,
      ]
      if (data.settings.combinationEquip) {
        if (equipments.includes('dock56FDrill')) {
          data.settings.dock56FDrill = true
        }
        if (equipments.includes('sideHoleDrill')) {
          data.settings.sideHoleDrill = true
        }
        if (equipments.includes('ptpHoleDrill')) {
          data.settings.ptpHoleDrill = true
        }
        if (equipments.includes('engraving')) {
          data.settings.cuttingEquipment = 'engraving'
        }
      }
      const [cut, cure] = this.getCutCurShow(item)
      if (
        data.settings.sideHoleDrill &&
        !(data.settings.dock56FDrill || data.settings.ptpHoleDrill)
      ) {
        if (cut === 'engraving' && item.nowCure == 'noHoleSlot') {
          this.$message({
            message: '切换成侧孔机时, 开料机设置无法选择仅下料, 请确认!',
            type: 'error',
            offset: 60,
          })
          return
        }
        if (cut === 'engraving' && item.nowCure == 'frontHoleSlot') {
          this.$message({
            message: '切换成侧孔机时, 开料机设置无法选择加工正面孔槽, 请确认!',
            type: 'error',
            offset: 60,
          })
          return
        }
      }
      this.showPageLoading = true

      this.$token('/save_production_line_setting', data, (res) => {
        if (res.status == 1) {
          this.isShowVisible = false
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 1500,
            offset: 70,
          })
          let oriItem = this.oriProduceLine[lineIndex]
          this.oriItem = oriItem
          //这如果返回了手机号和ip --- 添加操作记录
          if (res.data.phone && res.data.request_ip) {
            let recordData = {
              key: '保存设置',
              operateArr: [
                {
                  key: '生产线设置',
                  dataArr: [
                    {
                      type: 'form',
                      current: {
                        phone: res.data.phone,
                        time: new Date().toLocaleString(),
                        id: store.state.userInfo.id,
                        ip: res.data.request_ip,
                      },
                      ori: {
                        phone: '',
                        time: '',
                        id: '',
                        ip: '',
                      },
                      compareMsg: [
                        {
                          title: '',
                          keys: ['phone', 'time', 'id', 'ip'],
                        },
                      ],
                      formTextObj: {
                        phone: '手机号',
                        time: '保存时间',
                        id: '用户id',
                        ip: '物理地址',
                      },
                      formValueObj: {},
                    },
                  ],
                },
              ],
            }
            genFinalRecord(recordData, 'equipment_docking', '', () => {})
          }
          try {
            let combinedData = this.genCombinedEquipmentSettingsForm(
              item,
              oriItem
            )

            // 生成最后的操作记录
            this.genFinalRecordFn(
              {
                ...item,
                data: item,
                special_hole_slot: this.genObjTrueValue(
                  item?.hole_slot_engraving_process?.special_hole_slot,
                  'special_hole_slot'
                ), // 重新生成在开料机上加工的孔槽，转换成对应的显示值
                special_hole_slot_way:
                  item?.hole_slot_engraving_process?.special_hole_slot_way,
                aio_hole_slot_shunt:
                  item?.aio_hole_slot_process?.aio_hole_slot_shunt, // 重新生成在开料机上加工的孔槽，转换成对应的显示值
                aio_hole_slot_shunt_way:
                  item?.aio_hole_slot_process?.aio_hole_slot_shunt_way,
                engraving_process_setting: this.genObjTrueValue(
                  item?.hole_slot_engraving_process?.engraving_process_setting,
                  'engraving_process_setting'
                ), // 重新生成加工设置的孔槽，转换成对应的显示值
                ...combinedData.current,
              },
              {
                ...oriItem,
                data: oriItem,
                special_hole_slot: this.genObjTrueValue(
                  oriItem?.hole_slot_engraving_process?.special_hole_slot,
                  'special_hole_slot'
                ),
                special_hole_slot_way:
                  oriItem?.hole_slot_engraving_process?.special_hole_slot_way,
                aio_hole_slot_shunt:
                  oriItem?.aio_hole_slot_process?.aio_hole_slot_shunt, // 重新生成在开料机上加工的孔槽，转换成对应的显示值
                aio_hole_slot_shunt_way:
                  oriItem?.aio_hole_slot_process?.aio_hole_slot_shunt_way,
                engraving_process_setting: this.genObjTrueValue(
                  oriItem?.hole_slot_engraving_process
                    ?.engraving_process_setting,
                  'engraving_process_setting'
                ),
                ...combinedData.ori,
              }
            )
          } catch (error) {
            console.error('error', error)
          }
          // 刷新原始数据
          // this.refreshOriData()
        } else if (res.status == -1) {
          if (this.$store.state.saveInfo.phone) {
            this.$message({
              message: res.msg,
              type: 'error',
              duration: 1500,
              offset: 70,
            })
          }
          this.showSaveModal(item, lineIndex)
        } else {
          this.$message({
            message: res?.msg || '身份信息已过期,请重新从柜柜打开此页面!',
            type: 'error',
            duration: 1500,
            offset: 70,
          })
        }
        store.commit('setSaveInfo', {})
        // 保存后删除不需要要显示的信息，避免显示错误
        Reflect.deleteProperty(
          item.hole_slot_engraving_process.special_hole_slot,
          'STRENTCHTWO'
        )
        Reflect.deleteProperty(
          item.hole_slot_engraving_process.special_hole_slot,
          'MIDSTRENTCH'
        )
        Reflect.deleteProperty(
          item.hole_slot_engraving_process.special_hole_slot,
          'METALINNER'
        )
      })
    },
    showSaveModal(item, lineIndex) {
      this.lockStatus = this.tempStatus
      this.verifyPassword = true
      this.currentItem = item
      this.currentLineIndex = lineIndex
      this.isShowVisible = true
      return
    },
    // 修改生产线名字
    modifyLineName(item, index) {
      this.lockStatus = this.tempStatus
      if (this.lockStatus === 1) {
        this.$message({
          message: this.$t('lineManager.lockTip'),
          type: 'error',
          duration: 1500,
          offset: 70,
        })
        return
      }
      this.currentProductLine = JSON.parse(JSON.stringify(item))
      if (index == 0) {
        this.$message({
          type: 'info',
          message: this.$t('lineManager.reNameTip'),
          duration: 1000,
          offset: 70,
        })
        return
      }
      this.nowLine = index
      this.$nextTick(() => {
        this.$refs.line_name[0].focus()
      })
    },
    // 确认修改名字
    confirmLineName(item, index) {
      const oriItem = this.oriProduceLine[index]
      if (this.isEditName) {
        this.nowLine = -1
        this.isEditName = false
        this.$token(
          '/update_production_line_name',
          {
            setting_id: item.id + '',
            name: item.name,
          },
          (res) => {
            if (res.status == 1) {
              this.$message({
                message: this.$t('common.editSuccess'),
                type: 'success',
                duration: 1500,
                offset: 70,
              })
              if (this?.currentProductLine?.name !== item.name) {
                this.saveDataJsonToOssForRecordFn(
                  [
                    {
                      key: '重命名',
                      value: `${oriItem.name}->${item.name}`,
                    },
                  ],
                  { id: item.id, name: item.name }
                )
              }
              this.$store.commit('setCurrentProductLineName', item.name)
              // 刷新原始数据
              this.refreshOriData()

              // 重置状态, 防止下一次不会触发
              this.isEditName = true
            } else {
              this.$message({
                message: this.$t('lineManager.permissonTip'),
                type: 'info',
                duration: 1500,
                offset: 70,
              })
            }
          }
        )
      } else {
        this.isEditName = true
      }
    },
    // 删除生产线
    deleteLine(item) {
      this.lockStatus = this.tempStatus
      if (this.lockStatus === 1) {
        this.$message({
          message: this.$t('lineManager.lockTip'),
          type: 'error',
          duration: 1500,
          offset: 70,
        })
        return
      }
      this.showDeleteConfirm = true
      this.currentLine = item
    },
    cancelDelete() {
      this.showDeleteConfirm = false
    },
    confirmDelete() {
      this.$token(
        '/delete_production_line',
        {
          setting_id: this.currentLine.id,
        },
        (res) => {
          this.showDeleteConfirm = false
          if (res.status == 1) {
            this.$message({
              message: this.$t('common.deleteSuccess'),
              type: 'success',
              duration: 1500,
              offset: 70,
            })
            this.saveDataJsonToOssForRecordFn(
              [
                {
                  key: '删除',
                  value: this.currentLine.name,
                },
              ],
              { id: this.currentLine.id, name: this.currentLine.name }
            )
            this.getProductList()
          } else {
            this.$message({
              message: this.$t('lineManager.permissonTip'),
              type: 'info',
              duration: 1500,
              offset: 70,
            })
          }
        }
      )
    },
    saveDataJsonToOssForRecordFn(record, option, cb) {
      saveDataJsonToOssForRecord(record, 'equipment_docking', option, cb)
    },
    scrollWarp(back) {
      this.$nextTick(() => {
        const element = this.$refs['breadCrumb']
        element.scrollTo({
          top: back ? 0 : element.scrollHeight,
          behavior: 'smooth',
        })
      })
    },
    // 添加生产线
    addNewLine() {
      // if(this.showTiangongPage){
      //   this.$message({
      //     message: '当前为免费版, 无法添加',
      //     type: 'info',
      //     duration: 1500,
      //     offset: 60
      //   })
      //   return
      // }
      // if(this.userInfo.edition_id != 5 && this.userInfo.edition_id != 6 && this.userInfo.edition_id != 12){
      //   this.$message({
      //     message: '暂无此权限, 请升级账号版本!',
      //     type: 'info',
      //   })
      //   return
      // }
      // if(this.userInfo.edition_id === 12 && this.produceLine.length === 4) {
      //   this.$message({
      //     message: '当前版本添加已达上限, 请升级账号版本!',
      //     type: 'info',
      //     duration: 1500,
      //     offset: 60
      //   })
      //   return
      // }
      this.lockStatus = this.tempStatus
      if (this.lockStatus === 1) {
        this.$message({
          message: this.$t('lineManager.lockTip'),
          type: 'error',
          duration: 1500,
          offset: 70,
        })
        return
      }
      let name = this.$t('lineManager.newLine', { idx: this.lineIndex })
      // 防止生产线名字重复
      let flag = false
      this.produceLine.forEach((v) => {
        if (v.name == name) {
          flag = true
        }
      })
      if (flag) {
        this.lineIndex++
        this.addNewLine()
        return
      }
      const newName = this.$t('lineManager.newLine', { idx: this.lineIndex })
      this.$token(
        '/create_production_line_setting',
        {
          name: newName,
        },
        (res) => {
          if (res.status == 1) {
            this.lineIndex++
            _hmt.push([
              '_trackEvent',
              '添加生产线',
              'click',
              '',
              this.lineIndex,
            ])
            this.saveDataJsonToOssForRecordFn([
              {
                key: '添加生产线',
                value: newName,
              },
            ])
            this.getProductList(null, true)
            this.$message({
              message: this.$t('common.formatSuccess', { type: newName }),
              type: 'success',
              duration: 1500,
              offset: 60,
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              duration: 1500,
              offset: 60,
            })
          }
        }
      )
    },
    // 跳转柜柜试生产房间
    handleGoGuiguiTrail() {
      if (window.webToQt) {
        window.webToQt.openSampleRoom()
      }
    },
    // 添加孔槽
    addHoleSlot(item, key) {
      let obj
      if (
        (key == 'aio_holes_range' || key == 'aio_slots_range') &&
        !(item[key].length < 20)
      ) {
        this.$message.error('已达添加上限(20条)')
        return
      }
      if (key == 'engraving_holes_range' || key == 'aio_holes_range') {
        obj = {
          minDiameter: 0.0,
          maxDiameter: 0.0,
          minDepth: 0.0,
          maxDepth: 0.0,
          item_type: 'hole',
          [webId]: genUniId(),
        }
      } else {
        obj = {
          minWidth: 0.0,
          maxWidth: 0.0,
          minDepth: 0.0,
          maxDepth: 0.0,
          item_type: 'slot',
          [webId]: genUniId(),
        }
      }
      item[key].push(obj)
    },
    // 删除孔槽
    deleteHoleSlot(item, key, idx) {
      if (item[key].length <= 1) {
        return this.$message.error(this.$t('lineManager.deleteCheck'))
      }
      item[key].splice(idx, 1)
    },
    getIsPermission(equipmentName) {
      let flag = true
      if (equipmentName == 'ptp' || equipmentName == 'combination') {
        if (![5, 6, 14].includes(this.userInfo.edition_id)) {
          this.$message({
            message: this.$t('lineManager.permissionErr'),
            type: 'info',
            duration: 1000,
            offset: 70,
          })
          flag = false
        }
      }
      return flag
    },
    // 显示开料机
    cureSetting(item) {
      item.showCure = !item.showCure
    },
    // 显示组合设备设置
    combinSeting(item) {
      const permission = this.getIsPermission('combination')
      if (!permission) return
      item.showCombin = !item.showCombin
    },
    // 前往开料机设置
    gotoSetting(val, line) {
      const { value } = val
      switch (value) {
        case 'engraving':
          buryPointApi('equipment_dock', 'engraving')
          break
        case 'saw':
          buryPointApi('equipment_dock', 'saw')
          break
        case 'glassEquip':
          buryPointApi('equipment_dock', 'glass')
          break
        case 'side':
          buryPointApi('equipment_dock', 'side_hole')
          break
        case 'fiveSix':
          buryPointApi('equipment_dock', 'five_six')
          break
        case 'ptp':
          buryPointApi('equipment_dock', 'ptp')
          break
      }
      this.$store.commit('setCurrentProductLineName', line.name)
      const permission = this.getIsPermission(val.value)
      if (!permission) return
      // 权限验证
      if (!thirdPermission(val.path)) return
      if (val.path == '') {
        this.$message({
          message: this.$t('lineManager.deving'),
          type: 'info',
          duration: 1000,
          offset: 70,
        })
        return
      } else if (val.path == 'current') {
        this.$message({
          message: this.$t('lineManager.combinTip'),
          type: 'info',
          duration: 1500,
          offset: 70,
        })
        return
      } else {
        if (this.showTiangongPage) {
          this.$router.push(val.path)
        } else {
          if (val.type == 'cut') {
            _hmt.push(['_trackEvent', '开料设备选择', 'click', '', val.name])
          } else {
            _hmt.push([
              '_trackEvent',
              '孔槽加工设备选择',
              'click',
              '',
              val.name,
            ])
          }
          if (val.type !== 'cut' && line.haveSetted) {
            this.$router.push({
              path: val.path,
              query: {
                setting_id: line.id,
                line: line.name,
                equipType: val.value,
              },
            })
          } else if (val.type === 'cut') {
            this.$router.push({
              path: val.path,
              query: {
                setting_id: line.id,
                line: line.name,
                equipType: val.value,
              },
            })
          } else {
            this.$message({
              message: this.$t('lineManager.noUseEquip'),
              type: 'info',
              duration: 1500,
              offset: 70,
            })
          }
        }
      }
      // debugger
    },
    // 切换菜单
    changeNowLineSetting(line, indexs, val, type) {
      line.haveSetted = true
      let index = indexs - 1
      if (type == 'cut') {
        // 若当前选择了组合设备，同时组合设备里选择了开料机
        if (
          line.activeCureEquip == 'combination' &&
          (line.combinationEquip_settings.firstEquipment == 'engraving' ||
            line.combinationEquip_settings.secondEquipment == 'engraving')
        ) {
          this.$message({
            message: this.$t('lineManager.equipmentSelectTip'),
            type: 'warning',
            duration: 2000,
          })
          return
        }
        for (let i = 0; i < line.cutList.length; i++) {
          line.cutList[i].show = false
        }
        if (val == 'last') {
          // 左箭头按钮
          // 如果是第一个就切换到最后一个, 如果不是则减一, 切换成上一个
          if (index > 0) {
            line.cutList[index - 1].show = true
          } else {
            line.cutList[line.cutList.length - 1].show = true
          }
        } else {
          // 右箭头按钮
          // 如果是最后一个则切换成第一个, 如果不是则切换成下一个
          if (index == line.cutList.length - 1) {
            line.cutList[0].show = true
          } else {
            line.cutList[index + 1].show = true
          }
        }
        // 记录选中的开料设备
        for (let i = 0; i < line.cutList.length; i++) {
          let item = line.cutList[i]
          if (item.show) {
            line.activeCutEquip = item.value
          }
        }
        // 判断是否为雕刻机, 如果是则显示开料机设置, 如果不是则不显示
        if (line.activeCutEquip == 'saw') {
          line.showExtraSetting = false
        } else {
          line.showExtraSetting = true
        }
      } else {
        line.cureList.forEach((v) => {
          v.show = false
        })
        if (val == 'last') {
          if (index > 0) {
            line.cureList[index - 1].show = true
          } else {
            line.cureList[line.cureList.length - 1].show = true
          }
        } else {
          if (index == line.cureList.length - 1) {
            line.cureList[0].show = true
          } else {
            line.cureList[index + 1].show = true
          }
        }
        // let flags = true
        // if(line.cureList[3].show == true){
        //   if(line.cureList[0].show == true){
        //     line.cureList[0].recombination = true
        //     flags = false
        //   }else if(line.cureList[1].show == true){
        //     line.cureList[1].recombination = true
        //     flags = false
        //   }else if(line.cureList[2].show == true){
        //     line.cureList[2].recombination =  true
        //     flags = false
        //   }
        // }
        line.cureList.forEach((v) => {
          v.recombination = v.show
        })
        // if(flags){
        // }
        // 记录选中的孔槽加工设置
        for (let i = 0; i < line.cureList.length; i++) {
          let item = line.cureList[i]
          // if(item.show && item.value == 'side'){
          //   line.nowCure =  'doubleHoleSlot'
          // }
          if (item.show) {
            line.activeCureEquip = item.value
          }
        }
        if (line.activeCureEquip != 'combination') {
          line.showCombinationSetting = false
        } else {
          line.showCombinationSetting = true
        }
      }
    },
    // 处理生产线数据
    dealLineData(justRefreshOriList) {
      // this.produceLine = []
      let temp = []
      this.productLineList.forEach((v) => {
        // 处理孔槽自定义数据
        let arr = []
        if (v.data.dock6FDrill_partHole) {
          arr.push('dock6FDrill_partHole')
        }

        if (v.data.dock56FDrill_partHole) {
          arr.push('dock56FDrill_partHole')
        }
        if (v.data.dock56FDrill_partSlots) {
          arr.push('dock56FDrill_partSlots')
        }
        if (v.data.dock56FDrill_partSlot) {
          arr.push('dock56FDrill_partSlot')
        }
        if (v.data.dock56FDrill_excludeSlots) {
          arr.push('dock56FDrill_excludeSlots')
        }
        if (v.data.aio_hole_slot_process) {
          arr.push('aio_hole_slot_process')
        }
        let obj = {
          name: v.name,
          id: v.id,
          side_machine_hole_slot: v.data.side_machine_hole_slot,
          nowCure: v.data.ncHoleSlot,
          showNowCure: v.data.ncHoleSlot,
          showCure: false,
          showExtraSetting: v.data.cuttingEquipment == 'engraving',
          showCombinationSetting: v.data.combinationEquip
            ? v.data.combinationEquip
            : false,
          showCombin: false,
          cuttingSetting: arr,
          // activeCutEquip: v.data.cuttingEquipment == "electronicSaw" ? 'saw' : 'engraving',
          activeCutEquip:
            v.data.cuttingEquipment == 'electronicSaw'
              ? 'saw'
              : v.data.cuttingEquipment == 'engraving'
              ? 'engraving'
              : 'glassEquip',
          combinationEquip_settings: v.data.combinationEquip_settings,
          hole_slot_engraving_process: v.data.hole_slot_engraving_process,
          aio_hole_slot_process: v.data.aio_hole_slot_process,
          is_strentch_as_special_slot:
            v.data.is_strentch_as_special_slot ?? false,
          cutList: [
            {
              index: 1,
              name: 'common.electronicSaw',
              value: 'saw',
              img: 'assets/image/electronicSaw.svg',
              path: '/electronicSaw',
              show: v.data.cuttingEquipment == 'electronicSaw',
              type: 'cut',
            },
            {
              index: 2,
              name: 'common.engraving',
              value: 'engraving',
              img: 'assets/image/materialMachine.svg',
              path: '/engraving',
              show: v.data.cuttingEquipment == 'engraving',
              type: 'cut',
            },
            {
              index: 3,
              name: 'common.glassEquip',
              value: 'glassEquip',
              img: 'assets/image/glassEquip.png',
              path: '/equipmentSetting',
              show: v.data.cuttingEquipment == 'glassEquip',
              type: 'cut',
            },
          ],
          cureList: [
            {
              index: 1,
              name: 'common.PTP',
              value: 'ptp',
              img: 'assets/image/ptpMachine.svg',
              path: '/bluenPtp',
              show: v.data.combinationEquip ? false : v.data.ptpHoleDrill,
              recombination: false,
              type: 'cure',
            },
            // {
            //   index: 2,
            //   name: "五面钻",
            //   value: 'five',
            //   img: "assets/image/electronicSaw.svg",
            //   path: "/fiveFace",
            //   show: v.data.dock5FDrill,
            //   recombination: false,
            // },
            // {
            //   index: 3,
            //   name: "六面钻",
            //   value:  'six',
            //   img: "assets/image/electronicSaw.svg",
            //   path: "/sixFace",
            //   show: v.data.dock6FDrill,
            //   recombination: false,
            // },
            {
              index: 2,
              name: 'common.FSDrill',
              value: 'fiveSix',
              img: 'assets/image/fiveFace.svg',
              path: '/dockFiveSix',
              show: v.data.combinationEquip ? false : v.data.dock56FDrill,
              recombination: false,
              type: 'cure',
            },
            {
              index: 3,
              name: 'common.sideHoleDrill',
              value: 'side',
              img: 'assets/image/sideHole.svg',
              path: '/sideHole',
              show: v.data.combinationEquip ? false : v.data.sideHoleDrill,
              recombination: false,
            },
            {
              index: 4,
              name: 'common.combination',
              value: 'combination',
              img: 'assets/image/combinationEquip.png',
              path: 'current',
              show: v.data.combinationEquip,
              recombination: false,
              type: 'cure',
            },
          ],
          haveSetted:
            v.data.sideHoleDrill ||
            v.data.ptpHoleDrill ||
            v.data.dock56FDrill ||
            v.data.combinationEquip
              ? true
              : false,
          // sideHoleDrill: v.data.sideHoleDrill, //侧孔机
          // ptpHoleDrill: v.data.ptpHoleDrill, //ptp
          // dock56FDrill: v.data.dock56FDrill, //五六面钻
          // combinationEquip: v.data.combinationEquip, //组合设备
        }

        // 如果获取到的数据都为false, 则默认展示侧孔机
        let flag = true
        obj.cureList.forEach((v) => {
          if (v.show == true) {
            flag = false
          }
        })
        if (flag) {
          obj.cureList[2].show = true
          obj.cureList[2].recombination = true
        }
        // 如果同时选中了侧孔机和其他设备, 则展示其他设备, 否则展示侧孔机, 如果展示侧孔机, 则开料机设置选中双面孔槽
        if (obj.cureList[2].show) {
          if (obj.cureList[0].show == true) {
            obj.cureList[0].recombination = true
            obj.activeCureEquip = 'ptp'
          } else if (obj.cureList[1].show == true) {
            obj.activeCureEquip = 'fiveSix'
            obj.cureList[1].recombination = true
          } else {
            obj.cureList[2].recombination = obj.cureList[2].show
            obj.activeCureEquip = 'side'
          }
        } else {
          for (let i = 0; i < obj.cureList.length; i++) {
            obj.cureList[i].recombination = obj.cureList[i].show
            if (obj.cureList[i].recombination) {
              obj.activeCureEquip = obj.cureList[i].value
            }
          }
        }
        // debugger
        if (this.userInfo.edition_id == 4) {
          delete obj.cureList
          obj.nowCure = 'noHoleSlot'
        }
        // 删除加工孔槽不需要显示的属性
        Reflect.deleteProperty(
          obj.hole_slot_engraving_process.special_hole_slot,
          'STRENTCHTWO'
        )
        Reflect.deleteProperty(
          obj.hole_slot_engraving_process.special_hole_slot,
          'MIDSTRENTCH'
        )
        Reflect.deleteProperty(
          obj.hole_slot_engraving_process.special_hole_slot,
          'METALINNER'
        )
        // // 如果存在HINGESCREW字段则需要将字段修改成HINGE,后续HINGESCREW字段都会被覆盖
        // if (obj.hole_slot_engraving_process.special_hole_slot['HINGESCREW']) {
        //   obj.hole_slot_engraving_process.special_hole_slot['HINGE'] =
        //     obj.hole_slot_engraving_process.special_hole_slot['HINGESCREW']
        //   Reflect.deleteProperty(
        //     obj.hole_slot_engraving_process.special_hole_slot,
        //     'HINGESCREW'
        //   )
        // }

        // 孔槽数量为0 添加默认值
        if (!obj?.hole_slot_engraving_process?.engraving_holes_range?.length) {
          obj.hole_slot_engraving_process.engraving_holes_range = [
            {
              minDiameter: 0.0,
              maxDiameter: 0.0,
              minDepth: 0.0,
              maxDepth: 0.0,
              item_type: 'hole',
              id: 0,
              [webId]: genUniId(),
            },
          ]
        } else {
          // 如果有孔，给每条数据添加上id
          obj?.hole_slot_engraving_process?.engraving_holes_range.forEach(
            (item, index) => {
              item.id = index
              item[webId] = genUniId()
              item.item_type = 'hole'
            }
          )
        }
        if (!obj?.hole_slot_engraving_process?.engraving_slots_range?.length) {
          obj.hole_slot_engraving_process.engraving_slots_range = [
            {
              minWidth: 0.0,
              maxWidth: 0.0,
              minDepth: 0.0,
              maxDepth: 0.0,
              item_type: 'slot',
              id: 0,
              [webId]: genUniId(),
            },
          ]
        } else {
          obj?.hole_slot_engraving_process?.engraving_slots_range.forEach(
            (item, index) => {
              item.id = index
              item[webId] = genUniId()
              item.item_type = 'slot'
            }
          )
        }

        if (!obj?.aio_hole_slot_process?.aio_holes_range?.length) {
          obj.aio_hole_slot_process.aio_holes_range = [
            {
              minDiameter: 0.0,
              maxDiameter: 0.0,
              minDepth: 0.0,
              maxDepth: 0.0,
              item_type: 'hole',
              id: 0,
              [webId]: genUniId(),
            },
          ]
        } else {
          // 如果有孔，给每条数据添加上id
          obj?.aio_hole_slot_process?.aio_holes_range.forEach((item, index) => {
            item.id = index
            item[webId] = genUniId()
            item.item_type = 'hole'
          })
        }
        if (!obj?.aio_hole_slot_process?.aio_slots_range?.length) {
          obj.aio_hole_slot_process.aio_slots_range = [
            {
              minWidth: 0.0,
              maxWidth: 0.0,
              minDepth: 0.0,
              maxDepth: 0.0,
              item_type: 'slot',
              id: 0,
              [webId]: genUniId(),
            },
          ]
        } else {
          obj?.aio_hole_slot_process?.aio_slots_range.forEach((item, index) => {
            item.id = index
            item[webId] = genUniId()
            item.item_type = 'slot'
          })
        }

        // 开启一体机时开料机加工方式默认为加工板件正反面孔槽
        if (obj?.aio_hole_slot_process.enable_integration_device) {
          obj.nowCure = 'doubleHoleSlot'
          obj.showNowCure = 'doubleHoleSlot'
        }

        // 需求改动，判断是否有格栅字段normalSlot,没有则为老用户，设置为默认值false
        if (
          obj.hole_slot_engraving_process?.special_hole_slot?.normalSlot ===
          undefined
        ) {
          obj.hole_slot_engraving_process.special_hole_slot.gratingSlot = false
        }
        temp.push(obj)
      })
      if (!justRefreshOriList) {
        this.produceLine = temp
      }
      this.oriProduceLine = JSON.parse(JSON.stringify(temp))
    },
    // 刷新原始数据
    refreshOriData() {
      this.getProductList(true)
    },
    // 获取生产线列表
    getProductList(justRefreshOriList, isScroll) {
      this.isLoading = true
      this.$getByToken('/get_production_line_setting', '', (res) => {
        if (res.status == 1) {
          this.productLineList = res.data
          this.lineIndex = this.productLineList.length
          this.dealLineData(justRefreshOriList)
          this.setProLineList(res.data)
          if (isScroll) this.scrollWarp()
          this.isLoading = false
        } else if (res.result) {
          this.$message({
            type: 'info',
            message: this.$t('lineManager.permissonTip'),
            duration: 1500,
            offset: 70,
          })
          this.isLoading = false
        }
      })
    },
    handleChangeWay(cure, lineIndex) {
      if (cure == 'frontHoleSlot') {
        let currentLine =
          this.produceLine[lineIndex].hole_slot_engraving_process
            .special_hole_slot_way
        for (let current in currentLine) {
          if (currentLine[current] == 'back') {
            currentLine[current] = 'front'
          }
        }
      }
    },
    // 显示天工
    showTiangong() {
      this.showTiangongPage = true
      this.produceLine = [
        {
          name: 'common.defaultProduction',
          id: 0,
          nowCure: 'doubleHoleSlot',
          showCure: false,
          showExtraSetting: true,
          cuttingSetting: [],
          activeCutEquip: 'engraving',
          activeCureEquip: 'side',
          cutList: [
            {
              index: 1,
              name: 'common.electronicSaw',
              value: 'saw',
              img: 'assets/image/electronicSaw.svg',
              path: '/electronicSaw?from=tiangong',
              show: false,
              type: 'cut',
            },
            {
              index: 2,
              name: 'common.engraving',
              value: 'engraving',
              img: 'assets/image/materialMachine.svg',
              path: '/engraving?from=tiangong',
              show: true,
              type: 'cut',
            },
          ],
          cureList: [
            {
              index: 1,
              name: 'common.PTP',
              value: 'ptp',
              img: 'assets/image/ptpMachine.svg',
              path: '/bluenPtp?from=tiangong',
              show: false,
              recombination: false,
              type: 'cure',
            },
            // {
            //   index: 2,
            //   name: "五面钻",
            //   value: 'five',
            //   img: "assets/image/electronicSaw.svg",
            //   path: "/fiveFace?from=tiangong",
            //   show: false,
            //   recombination: false,
            // },
            // {
            //   index: 3,
            //   name: "六面钻",
            //   value: 'six',
            //   img: "assets/image/electronicSaw.svg",
            //   path: "/sixFace?from=tiangong",
            //   show: false,
            //   recombination: false,
            // },

            {
              index: 2,
              name: 'common.FSDrill',
              value: 'fiveSix',
              img: 'assets/image/fiveFace.svg',
              path: '/dockFiveSix?from=tiangong',
              show: false,
              recombination: false,
              type: 'cure',
            },
            {
              index: 3,
              name: 'common.sideHoleDrill',
              value: 'side',
              img: 'assets/image/sideHole.svg',
              path: '',
              show: true,
              recombination: true,
              type: 'cure',
            },
          ],
        },
      ]
    },

    handleHoleSlotChange(setItem, lineItem, isChangeWay = false) {
      // 如果是格栅，埋点记录
      if (setItem.key === 'gratingSlot') {
        isChangeWay
          ? buryPointApi('equipment_dock', 'toggle_grille_way')
          : buryPointApi('equipment_dock', 'toggle_grille')
      } else {
        isChangeWay
          ? buryPointApi('equipment_dock', `${setItem.key}_way`)
          : buryPointApi('equipment_dock', setItem.key)
      }
      if (isChangeWay) return
      // 铰链孔
      if (setItem.key == 'HINGESCREW') {
        lineItem.hole_slot_engraving_process.special_hole_slot['HINGE'] =
          lineItem.hole_slot_engraving_process.special_hole_slot['HINGESCREW']
      }
      if (
        (setItem.key === 'holes_in_yixing' ||
          setItem.key === 'slots_in_yixing') &&
        lineItem.activeCureEquip === 'fiveSix'
      ) {
        if (
          lineItem.hole_slot_engraving_process.special_hole_slot[setItem.key]
        ) {
          let dom = document.querySelector(
            `#toast_${setItem.key}_${lineItem.id}`
          )
          dom.style.display = 'block'
          if (setItem.key === 'holes_in_yixing') {
            dom.style.left = '-100px'
          }
          let timer = setTimeout(() => {
            dom.style.display = 'none'
            clearTimeout(timer)
          }, 4000)
        }
      }
    },

    handleOpenUnlock() {
      this.verifyPassword = false
      this.isShowVisible = true
      this.lockStatus = this.tempStatus
    },
    handleGetLockStatus() {
      //TODO 进入页面，获取生产线设置锁的状态
      getLockStatus().then((res) => {
        // 拿到的锁状态赋值，当前是锁定还是解锁状态
        this.lockStatus = res?.data?.is_lock || 0
        this.tempStatus = res?.data?.is_lock || 0
      })
    },
  },
  mounted() {
    if (window?.webToQt?.loadIndicatorHide) {
      window.webToQt.loadIndicatorHide()
    }
    if (window.sessionStorage.getItem('thinkerx_material')) {
      this.showNewProBtn = false
    }
    if (this.$route.query.from) {
      if (this.$route.query.from == 'tiangong') {
        this.showTiangong()
      }
    } else {
      this.showTiangongPage = false
      this.getProductList()
    }
    const breadCrumb = this.$refs['breadCrumb']
    const back = this.$refs['back']
    breadCrumb.addEventListener('scroll', (e) => {
      const { scrollTop } = e.target
      if (scrollTop > 350) {
        back.style.right = '80px'
      } else {
        back.style.right = '-80px'
      }
    })
    if (
      JSON.parse(window.sessionStorage.getItem('guiguiJumpEquipmentParams'))
        ?.guigui_equipment === '1'
    ) {
      buryPointApi('guigui', 'jumpEquipment')
    }
  },
  created() {
    this.handleGetLockStatus()
    if (this.$route.query.guigui_token) {
      window.sessionStorage.setItem('first', 1)
      if (!window.localStorage.getItem('debugger')) {
        window.history.replaceState('', '', '#' + this.$route.path)
      }
    }
  },
  components: { GBaseModal, MLockModal },
}
</script>

<style scoped lang="less">
.bread-crumb {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: scroll;

  span {
    cursor: default;
  }

  .trail-mark {
    top: -15px;
    right: -20px;
    z-index: 1;
    width: 55px;
    height: 25px;
    line-height: 27px;
    background-color: #0063ff;
    transform: scale(0.8);
  }

  .back {
    position: fixed;
    right: -80px;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    color: #fff;
    background-color: #18a8c7;
    border-radius: 50%;
    transition: all 0.5s;

    .anticon-arrow-up {
      font-size: 2rem;
    }

    span {
      font-size: 12px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .wrap {
    box-sizing: border-box;
    width: 1136px;
    min-height: calc(100vh - 84px);
    margin: 0 auto;
    padding-bottom: 30px;
    .tip {
      color: red;
    }
    .add-produce-line {
      margin: 24px 0 0;
      justify-content: space-between;
      .lock-icon {
        font-size: 30px;
        .anticon {
          display: block;
        }
      }
    }

    .produce-line {
      width: 100%;
      margin-top: 107px;

      .produce-setting {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .line-name {
          display: inline-block;
          margin-bottom: 16px;
          color: #8c8c8c;
          font-size: 20px;
        }

        .ant-input {
          width: 200px;
          // height: 20px;
          margin-bottom: 16px;
        }

        .produce-line-icon {
          display: flex;
          align-items: center;

          .anticon {
            margin-right: 32px;
            color: #fff;
            font-size: 15px;
            cursor: pointer;
          }

          .ant-btn {
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
              color: #fff;
              background: #18a8c7;
            }
          }
        }
      }

      .content {
        position: relative;
        width: 100%;
        height: 107px;
        background: #e3e3e3;
        border-radius: 6px 6px 0 0;

        & > div {
          position: absolute;
          top: -76px;
          left: 255px;
          display: flex;

          .cutting {
            margin-right: 110px;
          }

          .common-setting {
            position: relative;
            display: flex;
            align-items: center;
            user-select: none;

            .common-setting-title {
              position: absolute;
              top: -43px;
              left: 50%;
              display: inline-block;
              width: 100%;
              color: #000;
              font-weight: 500;
              font-size: 20px;
              font-family: PingFangSC-Medium, 'PingFang SC';
              text-align: center;
              transform: translateX(-50%);
            }

            .common-content {
              width: 152px;
              height: 152px;
              margin: 0 22px;
              text-align: center;
              background: #fff;
              border-radius: 8px;
              border: 1px solid;
              cursor: pointer;
              transition: all 0.5s;

              &:hover {
                box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.09);
              }

              &:active {
                box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.09);
              }

              & > div {
                width: 76px;
                height: 76px;
                margin: 16px auto;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              span {
                color: #5a5a5a;
                line-height: 22px;

                &::after {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  display: block;
                  width: 21px;
                  height: 5px;
                  margin: 0 auto;
                  background: #18a8c7;
                  content: '';
                }
              }
            }

            .anticon {
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
      }

      .cutting-setting {
        .cutting-setting-title {
          height: 48px;
          padding-left: 19px;
          line-height: 48px;
          background: #dadada;
          cursor: pointer;

          span {
            color: #5c5c5c;
            font-weight: 500;
          }
        }

        .cutting-checkbox {
          box-sizing: border-box;
          padding: 24px;
          border: 2px solid #eee;
          border-top: none;
          background: #fff;
          // display: flex;
          // align-items: flex-start;
          // .cure-setting{
          //   .ant-radio-group{
          //     display: flex;
          //     flex-direction: column;
          //     label{
          //       margin-bottom: 32px;
          //       font-size: 15px;
          //       &:last-child{
          //         margin-bottom: 0px;
          //       }
          //     }
          //   }
          // }
          .cure-setting-box {
            .cure-label {
              flex-shrink: 0;
            }
            .cure-box {
              border: 2px solid gainsboro;
              // /deep/ .ant-radio-wrapper {
              //   display: flex;
              //   justify-items: center;
              // }

              .slot {
                margin-top: 15px;
              }

              .hole {
                margin-top: 15px;
              }
              .aio-tip {
                color: #18a8c7;
              }
              .aio-item {
                margin-left: 90px;
              }

              .hole-slot {
                display: flex;
                align-items: center;
                margin-left: 50px;

                .el-input {
                  width: 60px;
                }

                > div {
                  margin-left: 20px;
                }

                .add {
                  margin-left: 20px;
                  color: blue;
                  cursor: pointer;
                }

                .delete {
                  margin-left: 20px;
                  color: red;
                  cursor: pointer;
                }
              }

              label {
                font-size: 15px;
              }
              .hole-slot_setting {
                .ant-checkbox-wrapper + .ant-checkbox-wrapper {
                  margin-left: 0;
                }
                .ant-checkbox-wrapper:nth-child(n + 7) {
                  margin-top: 10px;
                }
              }
            }
            .hole-slot-setting {
              display: flex;
              align-items: center;
              margin-top: -6px;
              &_option {
                position: relative;
                margin-right: 30px;
                /deep/.ant-checkbox + span {
                  padding-right: 0;
                }
                .warning-tip {
                  position: absolute;
                  bottom: -13px;
                  left: 610px;
                  color: red;
                }
                .setting-option-select {
                  width: 120px;
                  height: 28px;
                  /deep/ .ant-select-selection--single {
                    height: 28px;
                    .ant-select-selection__rendered {
                      margin-left: 5px;
                      line-height: 28px;
                    }
                  }
                }
              }
            }
            .short-side-setting {
              :deep(.ant-checkbox + span) {
                padding-right: 0;
              }
              .el-input {
                width: 75px;
              }
            }
          }
        }
      }

      .combination_setting {
        .combination_setting_title {
          height: 48px;
          padding-left: 19px;
          line-height: 48px;
          background: #dadada;
          cursor: pointer;

          span {
            color: #5c5c5c;
            font-weight: 500;
            cursor: pointer;
            user-select: none;
          }
        }

        .combination_setting_box {
          padding: 22px 8px 25px;
          background-color: #fff;

          .choose_equipment_box {
            padding: 0 16px;
            border-bottom: 1px solid rgb(233, 233, 233);
            .choose_equipment {
              display: flex;
              align-items: center;

              > span {
                display: inline-block;
                margin: 0 24px;
              }

              > img {
                width: 16px;
                height: 16px;
                margin-left: 24px;
                cursor: pointer;
              }

              .choose_equipment_setting_btn {
                display: inline-block;
                margin-left: 10px;
                color: rgb(24, 168, 199);
                cursor: pointer;
                user-select: none;
              }
            }

            .choose_equipment_msg {
              width: 219px;
              height: 35px;
              margin: 22px 0 20px;
              padding-left: 24px;
              color: rgb(243, 101, 65);
              font-size: 12px;
              line-height: 35px;
              background: #fff5f5;
            }
          }

          .equipment_setting {
            padding: 0 24px;

            .equipment_setting_title {
              padding: 20px 0;
              font-weight: bold;
              font-size: 16px;
            }

            .holes_slots_setting {
              display: flex;
              align-items: center;
              margin-bottom: 15px;

              > div {
                margin-right: 30px;
              }
            }

            .choose_equipment_msg {
              width: 633px;
              height: 35px;
              margin-top: 22px;
              padding-left: 24px;
              color: rgb(243, 101, 65);
              font-size: 12px;
              line-height: 35px;
              background: #fff5f5;
            }
          }
        }
      }
    }
  }

  .confirm-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);

    .content {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      box-sizing: border-box;
      width: 400px;
      height: 200px;
      margin: auto;
      padding: 24px;
      background: #fff;
      border-radius: 8px;

      .dialog-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-weight: 600;
        font-size: 18px;
      }

      .dialog-content {
        margin-top: 24px;
        color: #000;
        font-size: 16px;

        span {
          display: inline-block;
          text-indent: 2em;
        }
      }

      .dialog-operation {
        position: absolute;
        bottom: 24px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        padding: 0 24px;
      }
    }
  }
  .tick-box {
    display: inline-block;
  }
  .toast-message {
    background-color: rgb(255, 213, 220);
    color: red;
    width: 230px;
    text-align: center;
    border-radius: 5px;
    z-index: 999;
    top: -25px;
    left: -20px;
    display: none;
    line-height: 22px;
  }
  .special-way {
    width: 140px;
    margin-right: 24px;
  }
}
</style>
