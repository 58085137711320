import { httpRequest } from '@/apis/request'
import { IRootState } from '@/store/types'
import { bigPlankTemp } from '@/util/bigPlankTemp'
import EventBus from '@/util/bus'
import { EventBusKey } from '@/util/bus'
import {
  canvas2Image,
  compare,
  cvs2image,
  getOriginRect,
} from '@/util/commonFuncs'
import { JxTagData, countTagDataByDir } from '@/util/countTagData'
import { createLaveTag, createTag, roomNameToChartMap } from '@/util/createTag'
import { getDeviceXDPI, pxTomm } from '@/util/exportFuncs'
import { convertJpegToBmp } from '@/util/file/convert'
import { _translateFileNameFn, dealTransFileName } from '@/util/saveFile'
import { genFirstLevelFileName, getSurplusSize } from '@/util/tag'
import { getGraghEdge } from '@/util/tag'
import createPdf from '@/views/printTag/utils/createPdf'
import { timeSlice_ } from '@/views/printTag/utils/map-printTag'
import axios from 'axios'
import JSZip from 'jszip'
import Vue from 'vue'

import { changeDataStartPoint } from '../NCGenerator'
import { exportBmp1Bit } from '../jimp'
import { jsPdfTool } from '../tag/pdfFont'

type StoreType = { state: IRootState }
type SettingType = {
  file_setting: { label: string; value: string }[]
  file_setting_delimiter: string
  file_setting_extra: { stock_num_length: number }
  nc_file_settings: {
    front: string
    labelMachineName: string
    machineCenterName: string
    stockNumLength: number
    customizeSetting: {
      headerIds: any
    }
  }
}
type Func = (...args: any) => any
type LifeFuncType = Partial<{
  loadSetting: Func
  loadPreferences: Func
  dealTemp: Func
  initialData: Func
  dealDrawData: Func
  genTagImg: Func
  genTagPdf: Func
  done: Func
}>

let _ncFileList: Array<{ file_display_name: string; [key: string]: any }> = []
/** 获取nc设置, 生产文件设置，只用于标签，从标签页抽取的逻辑 1 **/
export function getFileSetting(store: StoreType): Promise<SettingType | any> {
  return new Promise((resolve) => {
    const { ncSetting, userInfo } = store.state
    const params = {
      process_setting_id: ncSetting.process_setting_id || -1,
      uid: userInfo.id,
    }
    Vue.prototype.$token('/get_nc_file_settings', params, (res: any) => {
      if (!res.status) {
        throw new Error('错误')
      }
      const label_dict = res.data.label_dict
      const nc_file_settings = res.data.nc_file_settings
      const header_ids = nc_file_settings.customizeSetting.headerIds
      // 如果true，则使用_,这是为了兼容旧数据
      const file_setting_delimiter = nc_file_settings.hasDelimiter
        ? '_'
        : nc_file_settings.hasDelimiter
      const file_setting_extra = {
        stock_num_length: nc_file_settings.stockNumLength,
      }

      const ids: string[] = []
      let template_string = nc_file_settings.customizeSetting.templateString
      if (template_string) {
        if (nc_file_settings.hasDelimiter) {
          const spit = nc_file_settings.hasDelimiter
          template_string = template_string.split(spit).map((item: any) => {
            return item.match(/\{([^)]*)\}/)[1]
          })
        } else {
          template_string = template_string.slice(1, -1)
          template_string = template_string.split('}{')
        }
      } else template_string = []

      template_string.forEach((item: any) => {
        ids.push(header_ids[item])
      })
      const file_setting: any[] = []
      ids.forEach((id) => {
        const item = label_dict[String(id)]
        let label = '',
          value = ''
        if (item.label_name == 'direction') {
          label = 'front'
          value = nc_file_settings.front
        } else if (item.label_name == 'labelMachineName') {
          label = 'labelMachineName'
          value = nc_file_settings.labelMachineName
        } else if (item.label_name == 'machineCenterName') {
          label = 'labelMachineName'
          value = nc_file_settings.machineCenterName
        } else {
          label = item.label_name
        }
        file_setting.push({ label, value })
      })
      resolve({
        file_setting,
        file_setting_delimiter,
        file_setting_extra,
        nc_file_settings,
      })
    })
  })
}
// 获取nc文件设置 2
function getEngravingSetting(
  store: { state: IRootState },
  currentTemplate: any
): Promise<{ ncNameSuffix: string; ncOutputSetting: any }> {
  return new Promise((resolve) => {
    Vue.prototype.$token(
      '/load_engraving_setting',
      { setting_id: store.state.ncSetting.process_setting_id },
      (res: any) => {
        if (!res.status) {
          throw new Error('错误')
        }
        const ncNameSuffix =
          res.data.ncOutputSetting.fileType.fileNameType.toLowerCase()

        const ncOutputSetting = res.data.ncOutputSetting

        const [pt1, pt2] =
          res.data.labelingSetting && res.data.labelingSetting.label_resolution
        // 如果有设置分辨率则不采用尺寸
        if (pt1 && pt2) {
          currentTemplate.tag_width = pxTomm(pt1 / 4, getDeviceXDPI())
          currentTemplate.tag_height = pxTomm(pt2 / 4, getDeviceXDPI())
        }
        resolve({
          ncNameSuffix,
          ncOutputSetting,
        })
      }
    )
  })
}
// 获取偏好设置 3
export function getPreferencesSetting(): Promise<any> {
  return new Promise((resolve) => {
    const params: any = {}
    if (sessionStorage.getItem('thinkerx_material')) {
      params.production_from = 'guimen'
    }
    Vue.prototype.$getByToken(
      '/get_preferences_setting',
      params,
      (res: any) => {
        if (res.status === 1) {
          const data = res.data
          const obj = {
            ...data,
            need_lave: data.surplusLabel,
            // bigPlankLabel 生成大板信息标签
            gen_big_plank_tag: data.bigPlankLabel,
            // labelCoord 大板标签贴标坐标
            xCoordinate: data.labelCoord.X,
            yCoordinate: data.labelCoord.Y,
            // mirrorLabel 	标签镜像
            jx_checked: data.mirrorLabel,
            // // boardBenchmark 生成五六面钻放板基准标识
            five_six_dir: data.boardBenchmark,
            // labelSortWay 标签排序适应自动上下料
            sort_value: data.labelSortWay == 'default' ? 1 : data.labelSortWay,
            print_setting: {
              // labelColumnNum 标签列数
              cols: data.labelColumnNum,
              // labelColumnGap 多列标签列间距
              labelColumnGap: data.col_space,
              // 特殊标记透明度
              opacity: data.opacity ?? 0.24,
              // 特殊标记大小
              sizeAuto: data.sizeAuto ?? 1,
              // 特殊标记自适应
              size: data.size ?? true,
            },
            cutNeedLave: data.cutNeedLave ?? true,
          }
          resolve(obj)
        }
      }
    )
  })
}
function showPartSize(part: any, field: string, needShowField: string) {
  const rect = getOriginRect(part, field)
  return (rect?.[needShowField] ?? 0).toFixed(2)
}
// 初始化数据 4
export function initTagData(
  data: any[],
  store: StoreType,
  setting: SettingType
) {
  const { file_setting, file_setting_delimiter, file_setting_extra } = setting
  const { ncSetting } = store.state
  data = JSON.parse(JSON.stringify(data))
  // 正反面条码填充值
  const front_file_identifier = ncSetting.front_file_identifier
  const opposite_file_identifier = ncSetting.opposite_file_identifier
  // 记录有几个柜子
  const wardrobe_names: any = {}
  let record_wardrobe_num = 0
  data.forEach((item, index) => {
    item.parts.forEach((part: any) => {
      part.oriPlankNumF = `${part.plankNum}${opposite_file_identifier}`
      if (front_file_identifier) {
        part.plankNum = `${part.plankNum}${front_file_identifier}`
        part.oriPlankNum = part.plankNum
      }
      let label_img_name = ''
      if (ncSetting.labelFormat === 'jingmei') {
        label_img_name += `${item.parts[0].address}_`
        file_setting.forEach((set) => {
          if (
            set.label === 'front' ||
            set.label === 'labelMachineName' ||
            set.label === 'machineCenterName'
          ) {
            if (set.value) {
              if (file_setting_delimiter) label_img_name += `${set.value}_`
              else label_img_name += `${set.value}`
            }
          } else {
            if (file_setting_delimiter) {
              if (set.label === 'stockNum') {
                if (
                  String(index + 1).length < file_setting_extra.stock_num_length
                )
                  label_img_name += `${prefixInteger(
                    index + 1,
                    file_setting_extra.stock_num_length
                  )}_`
                else label_img_name += `${index + 1}_`
              } else label_img_name += `${part[set.label]}_`
            } else {
              if (set.label === 'stockNum') {
                if (
                  String(index + 1).length < file_setting_extra.stock_num_length
                )
                  label_img_name += `${prefixInteger(
                    index + 1,
                    file_setting_extra.stock_num_length
                  )}`
                else label_img_name += `${index + 1}`
              } else label_img_name += `${part[set.label]}`
            }
          }
        })
      }
      part.lableImgName =
        label_img_name +
        (ncSetting.genSimpleLabelBarcode
          ? part.simplePlankNum
          : part.oriPlankNum)

      if (wardrobe_names.hasOwnProperty(`${part.loc}_${part.roomID}`))
        part.wardrobeNum = wardrobe_names[`${part.loc}_${part.roomID}`]
      else {
        record_wardrobe_num++
        wardrobe_names[`${part.loc}_${part.roomID}`] = record_wardrobe_num
        part.wardrobeNum = wardrobe_names[`${part.loc}_${part.roomID}`]
      }
      // 将宽高恢复成默认顺序
      let specHeight
      let specWidth
      if (part.srcTexDir !== 'reverse') {
        specHeight = part.specHeight
        specWidth = part.specWidth
      } else {
        specHeight = part.specWidth
        specWidth = part.specHeight
      }
      const oSizeRect = { width: specWidth, height: specHeight }
      const oSizeCutRect = getOriginRect(part, 'realRect')
      const copyOSizeCutRect = JSON.parse(JSON.stringify(oSizeCutRect))
      if (part.srcTexDir == 'reverse') {
        oSizeCutRect.width = copyOSizeCutRect.height
        oSizeCutRect.height = copyOSizeCutRect.width
      }
      // 定义特殊字段
      // 成品尺寸
      part.oSize = `${(+oSizeRect.height).toFixed(
        2
      )}*${(+oSizeRect.width).toFixed(2)}*${part.thick}`
      // 切割尺寸
      part.cutSize = `${oSizeCutRect.height.toFixed(
        2
      )}*${oSizeCutRect.width.toFixed(2)}*${part.thick}`
      // 材质
      part.matCode_texture = `${part.matCode}-${part.texture}`

      part.handle_name = ''
      if (
        part.handle &&
        part.handle.partsChildren &&
        part.handle.partsChildren.length
      )
        part.handle_name =
          part.handle.partsChildren[0].maskPartName ||
          part.handle.partsChildren[0].matSpec
            ? `${
                part.handle.partsChildren[0].maskPartName ||
                part.handle.partsChildren[0].matSpec
              }_${part.handle.partsChildren[0].name}`
            : ''

      if (Array.isArray(part.partsChildren)) {
        part.partsChildren.forEach((p_c: any) => {
          if (p_c.name.includes('铰链'))
            part.hinge_label = p_c.maskPartName || p_c.matSpec || ''
          else if (p_c.name.includes('拉直器'))
            part.straightener_label = p_c.maskPartName || p_c.matSpec || ''
        })
      }
    })
  })
  let tagLength = 0
  for (let i = 0; i < data.length; i++) {
    const plank = data[i]
    plank.parts = plank.parts.sort(compare('priority', 'up', 'priority'))
    tagLength += plank.parts.length
  }
  return {
    data,
    tagLength,
  }
}
// 初始化余料数据 5
export function initLaveData(sourceData: any[], store: StoreType) {
  const { ncSetting } = store.state
  const lave_data: any[] = []
  sourceData.forEach((item) => {
    const supplus = [item.parts]
    if (!supplus.length) return
    supplus.forEach((sup) => {
      const panel = {
        texture: item.texture,
        orderNo: sup[0].orderNo,
        roomName: sup[0].roomName,
        matCode: sup[0].matCode,
        parts: sup,
        thick: sup[0].thick,
        plankHeight: item.plankHeight,
        plankWidth: item.plankWidth,
        plankSize: '',
        lableImgName: new Date().getTime(),
      }
      if (sup[0].specialType === 'supplus') {
        const paths = sup[0].surplusPath[0]
        panel.plankSize = getSurplusSize(paths, ncSetting, sup[0].stockKey).str
      }
      lave_data.push(panel)
    })
  })
  return lave_data
}
// 标签图片绘制前的处理 6
export function tagDrawPreDeal(
  sourceData: any,
  ncNameSuffix: string,
  setting: SettingType,
  preSetting: any,
  ncSetting: any,
  ncOutputSetting?: any,
  preferencesSetting?: any
) {
  const { gen_big_plank_tag, cutNeedLave } = preSetting
  const { file_setting, file_setting_delimiter, nc_file_settings } = setting
  const tagListSourceData = []
  const bigTagList = []
  for (let index = 0; index < sourceData.length; index++) {
    const plank = sourceData[index]
    const parts = sourceData[index].parts

    const { size, graghEdge } = getGraghEdge(plank, ncSetting)
    let partNCName = ''
    const headersIds = nc_file_settings.customizeSetting.headerIds
    // 大板信息
    const plankObj: any = {
      plank,
      plank_index: index + 1, // 第几张大板
      part: {
        ...parts[0],
        page: tagListSourceData.length + 1, //第几页
      },
      type: 'plank',
    }
    let ncName = file_setting
      .map((item) => {
        if (item.value) {
          return item.value
        } else {
          const stockNumLength = nc_file_settings.stockNumLength
          if (item.label === 'stockNum' && stockNumLength) {
            const value = plankObj.plank_index + '' // 序号用第几块板，如果不对，就把sourceData用stockNum进行排序
            return value.padStart(stockNumLength, '0')
          } else if (item.label === 'front') {
            return nc_file_settings.front || plankObj.part.front || '正面'
          } else {
            return plankObj.part[item.label]
          }
        }
      })
      .join(file_setting_delimiter)

    // 如果没有大板序号，手动加上
    if (!headersIds['大板序号']) {
      ncName = plankObj.plank_index + file_setting_delimiter + ncName
    }

    // nc导出设置里选择了正反面且开启了正反面合并，去掉正反面相关字段
    if (
      headersIds['正面/反面'] &&
      ncOutputSetting.fileMerge.doubleEdgeCombine
    ) {
      const frontStr = ncOutputSetting.fileNameSetting.front_info
        ? ncOutputSetting.fileNameSetting.front_info
        : '正面'
      const backStr = ncOutputSetting.fileNameSetting.back_info
        ? ncOutputSetting.fileNameSetting.back_info
        : '反面'
      const reg = new RegExp(
        `\\${file_setting_delimiter}${frontStr}|\\${file_setting_delimiter}${backStr}`,
        'gi'
      )
      ncName = ncName.replace(reg, '')
    }
    // 给ncName加上后缀
    ncName = `${ncName}.${ncNameSuffix}`

    partNCName = ncName

    if (gen_big_plank_tag) {
      // 大板绘制信息
      const normalPart = plank.parts.find((part: any) => !part.specialType)
      const plankDrawMsg = {
        programAddress: normalPart
          ? normalPart.address
          : plank.parts[0].address,
        orderNo: normalPart ? normalPart.orderNo : plank.parts[0].orderNo,
        material: plank.thick + plank.matCode + plank.texture,
        size,
        ncName,
        qrCode: ncName,
        plank_index: `第${plankObj.plank_index}张大板`,
        lable_index: ``,
      }
      // 把 plankDrawMsg、graghEdge 放到 plank 里面
      plankObj.plank = {
        ...plankObj.plank,
        plankDrawMsg,
        graghEdge,
        isGenNcSuffix: preSetting.isGenNcSuffix,
      }
      tagListSourceData.push(plankObj)
      bigTagList.push(plankObj)
    }

    for (let p_index = 0; p_index < parts.length; p_index++) {
      if (!parts[p_index].surplusInfo) {
        parts[p_index].surplusInfo = plank?.surplusInfo
      }
      const obj: any = {
        plank: {
          ...sourceData[index],
          graghEdge,
        },
        part: {
          ...(JSON.parse(JSON.stringify(parts[p_index])) || {}),
          page: tagListSourceData.length + 1,
          ncName: partNCName,
        },
        plank_index: index + 1,
        part_index: p_index,
        currentPlank: parts[p_index],
        type: 'tag',
      }
      if (!(!cutNeedLave && parts[p_index].specialType == 'supplus')) {
        tagListSourceData.push(obj)
      }
    }
  }
  const all_page =
    tagListSourceData.length +
    (preferencesSetting.need_lave ? bigTagList.length : 0)
  if (bigTagList.length) {
    // 修改每个属性的label_index信息
    bigTagList.forEach((item) => {
      item.plank.plankDrawMsg.lable_index = `第${item.part.page}/${all_page}页`
    })
  }
  return { tagListSourceData, bigTagList, all_page }
}
// 余料标签图片绘制前的处理 7
export function laveTagDrawPreDeal(laveData: any) {
  const laveTagListSourceData = []
  for (let index = 0; index < laveData.length; index++) {
    const obj = {
      plank: laveData[index],
      plank_index: index + 1,
      type: 'laveTag',
    }
    laveTagListSourceData.push(obj)
  }
  return laveTagListSourceData
}
// 获取collect数据
// 生成图片标签 8
export async function generateImageTag(
  tagListSourceData: any[],
  laveTagListSourceData: any[],
  currentTemplate: any,
  store: StoreType,
  setting: any,
  drawData: any[]
): Promise<{
  collect: any[]
  collectSvg: any[]
  bigplantLableList: any[]
  LaveTagBase64List: any[]
}> {
  const { ncSetting } = store.state
  const {
    all_page,
    jx_checked,
    five_six_dir,
    scale,
    sort_value,
    cutNeedLave,
    need_lave,
    isGenNcSuffix,
  } = setting
  return new Promise((resolve) => {
    const isTemp = false
    // 收集所有图片
    const collect: {
      fileName: string
      url: string
      stock_key: string
      size: string
    }[] = []
    const collectSvg: { url: string }[] = []
    const bigplantLableList: { url: string }[] = []
    const LaveTagBase64List: { url: string }[] = []
    // 收集余料图片
    // 获取板件是否需要逆时针旋转
    let isRotatePlank = false
    const typographyKlass = currentTemplate.tem_data.find(
      (it: any) => it.type === 'Typography'
    )
    if (typographyKlass) {
      isRotatePlank = typographyKlass.data.plankRotate
    }
    const finalData = need_lave
      ? [...tagListSourceData, ...laveTagListSourceData]
      : [...tagListSourceData]
    // 根据finalData统计房间，柜体编号
    const tagDataList = finalData.filter((item: any) => item.type === 'tag')
    const roomNameMap = roomNameToChartMap(tagDataList)

    const len = finalData.length

    let idx = 0
    let ncFileList: any[] = []
    async function next() {
      await new Promise((resolve) => setTimeout(resolve, 50))
      if (idx >= len) {
        resolve({
          collect,
          collectSvg,
          bigplantLableList,
          LaveTagBase64List,
        })
        return
      }
      const current = finalData[idx++]
      if (!current) {
        return
      }
      let svgUrl = null
      const stock_key = current.plank.stockKey
        ? current.plank.stockKey.split(':').slice(0, -1).join(':')
        : `${current.plank.texture}:${current.plank.matCode}:${current.plank.thick}`
      if (current.plank.parts[0].surplusInfo && !current.plank.surplusInfo) {
        current.plank.surplusInfo = current.plank.parts[0].surplusInfo
      }
      let { size: folderSize } = getGraghEdge(current.plank, ncSetting)
      const folderSizeList = folderSize.split('*').splice(0, 2)
      folderSize = `${folderSizeList[1]}*${folderSizeList[0]}`
      if (current.type === 'tag') {
        // 当是标签时(收集标签时要满足收集余料标签时是开启了)
        if (
          current.part.specialType !== 'supplus' ||
          (current.part.specialType === 'supplus' && cutNeedLave)
        ) {
          svgUrl = await createTag(
            { ...currentTemplate, roomNameMap },
            current.plank,
            current.part,
            current.plank_index,
            current.part_index,
            finalData.length,
            jx_checked,
            five_six_dir,
            { scale },
            ncSetting,
            current.currentPlank,
            collect,
            sort_value,
            isTemp,
            cutNeedLave,
            isRotatePlank,
            idx - 1
          )
          const { fileName, ncFileList: ncFileArr } =
            await dealExportTagFileName(current, store, ncFileList, drawData)
          ncFileList = ncFileArr

          _ncFileList = ncFileList.filter((i) => {
            if (i.filetype === 'nc' && i.direction === 'front') {
              return i
            }
          })
          dealMergeFileNameOrUrl(
            fileName,
            collect,
            ncSetting,
            stock_key,
            folderSize
          )
        }
      } else if (current.type === 'plank') {
        const { size } = getGraghEdge(current.plank, ncSetting)
        current.plank.plankSize = size
        // 大板标签
        svgUrl = await createTag(
          {
            ...bigPlankTemp,
            tag_width: currentTemplate.tag_width,
            tag_height: currentTemplate.tag_height,
          },
          {
            ...current.plank,
            isGenNcSuffix,
          },
          current.part,
          current.plank_index,
          null,
          finalData.length,
          jx_checked,
          false,
          { scale },
          ncSetting,
          current.currentPlank,
          collect,
          sort_value,
          isTemp,
          false,
          isRotatePlank,
          idx - 1
        )
        //
        const base64 = svgToBase64(svgUrl)
        bigplantLableList.push({ url: base64 })
        const { fileName, ncFileList: ncFileArr } = await dealExportTagFileName(
          current,
          store,
          ncFileList,
          drawData
        )

        ncFileList = ncFileArr
        dealMergeFileNameOrUrl(
          fileName,
          collect,
          ncSetting,
          stock_key,
          folderSize
        )
      } else {
        // 当是余料标签时
        svgUrl = await createLaveTag(
          currentTemplate,
          current.plank,
          current.plank_index,
          { scale },
          ncSetting,
          collect,
          isRotatePlank,
          // 参数设置
          finalData.length,
          tagListSourceData.length,
          idx - 1
        )
        const base64 = svgToBase64(svgUrl)
        LaveTagBase64List.push({ url: base64 })
        const fileName = dealExportLaveTagFileName(current, store)
        dealMergeFileNameOrUrl(
          fileName,
          collect,
          ncSetting,
          stock_key,
          folderSize
        )
      }
      collectSvg.push({
        url: svgUrl,
      })
      next()
    }
    // 执行
    next()
  })
}
// 生产pdf标签 9
export async function generatePdfTag(
  currentTemplate: any,
  templateName: string,
  sourceData: any,
  tagListSourceData: any,
  laveData: any[],
  store: StoreType,
  setting: any
) {
  const { need_lave, five_six_dir, sort_value, cutNeedLave, jx_checked } =
    setting
  const { ncSetting } = store.state
  const isTemp = false
  // 加载字体
  await jsPdfTool.loadFontByTagTemp([
    ...(currentTemplate.tem_data ?? []),
    ...(currentTemplate.surplus_data ?? []),
  ])
  /** pdf下载 */
  const downloadPdf = new createPdf(currentTemplate)
  laveData = need_lave ? laveData : []
  const fun = timeSlice_(downloadPdf.create)
  // 获取板件是否需要逆时针旋转
  let isRotatePlank = false
  const typographyKlass = currentTemplate.tem_data.find(
    (it: any) => it.type === 'Typography'
  )
  if (typographyKlass) {
    isRotatePlank = typographyKlass.data.plankRotate
  }
  // 根据finalData统计房间，柜体编号
  const tagDataList = tagListSourceData.filter(
    (item: any) => item.type === 'tag'
  )
  const roomNameMap = roomNameToChartMap(tagDataList)
  await fun(
    sourceData,
    tagListSourceData,
    laveData,
    { roomNameMap },
    five_six_dir,
    downloadPdf.getInstance(),
    sort_value,
    isTemp,
    ncSetting,
    cutNeedLave,
    isRotatePlank,
    jx_checked
  )
  const data = downloadPdf.output('blob')
  return {
    name: templateName,
    data,
  }
}
// 生成图片zip
export async function createTagImageZip(
  target: any,
  store: StoreType,
  templateName: string,
  paibanData: any[],
  flag: boolean
) {
  const {
    collect: collectTagList,
    bigplantLableList,
    LaveTagBase64List,
  } = target
  const { ncSetting } = store.state
  const { label_img_big_plank_folder, isMergeFile } = ncSetting
  // 一级文件名
  let firstLevelFileName = ''
  let originFileNameData: any = {}
  // 收集贴标图信息(二维数组是为了统一遍历是否分文件夹)
  const labelImgList = [[]] as {
    name: string
    url: string
    stock_key: string
    size: string
  }[][]
  try {
    const obj = await genFirstLevelFileName(true)
    firstLevelFileName = obj.firstLevelFileName
    originFileNameData = obj.data
  } catch (error) {
    console.error('err', error)
  }
  const imgFileName = originFileNameData.label_image_folder || templateName
  const preferencesSetting = await getPreferencesSetting()

  const zip = new JSZip()
  let currentZip = zip
  let idx = 0
  //  没有开启开料机文件合并功能并且勾选了根据大板分为不同文件夹
  if (!isMergeFile && label_img_big_plank_folder) {
    const folders = zip.folder('')
    const time = Date.now() + 8 * 3600 * 1000
    for (let j = 0; j < paibanData.length; j++) {
      const item = paibanData[j]
      labelImgList[j] = []
      const idy = _ncFileList.findIndex((i) => i.stock_key === item.stockKey)
      let list: any[] = []
      // 处理当开启了自动余料裁剪但是没有勾选生成余料的情况
      if (!preferencesSetting.cutNeedLave) {
        item.parts = item.parts.filter((i: any) => i.specialType !== 'supplus')
      }
      // 生成的每个文件夹中图片的数量
      if (!!bigplantLableList.length && !!LaveTagBase64List.length) {
        list = [bigplantLableList[j], ...item.parts, LaveTagBase64List[j]]
      } else if (!bigplantLableList.length && !!LaveTagBase64List.length) {
        list = [...item.parts, LaveTagBase64List[j]]
      } else if (!!bigplantLableList.length && !LaveTagBase64List.length) {
        list = [bigplantLableList[j], ...item.parts]
      } else {
        list = [...item.parts]
      }
      const dirname = _ncFileList[idy].file_display_name.slice(0, -3)
      if (firstLevelFileName) {
        const folder = await folders?.folder(`${dirname}`)
        // 这一步是手动修改文件的更新时间
        zip.file(dirname + '/', '', { date: new Date(time) })
        if (folder) currentZip = folder
      }
      list.forEach((itey, indey) => {
        let it = collectTagList[idx].url.split(',')[1]
        let filename = collectTagList[idx].fileName
        if (indey === list.length - 1 && LaveTagBase64List.length) {
          // 当前文件夹余料标签的索引 = 图片列表的长度-文件夹的数量+当前生成的第几个文件夹
          const currentIndex = collectTagList.length - paibanData.length + j
          it = collectTagList[currentIndex].url.split(',')[1] // 获取到是对应的余料标签
          filename = collectTagList[currentIndex].fileName
          idx--
        }
        const time = Date.now() + 8 * 3600 * 1000
        currentZip.file(
          `${filename}.${ncSetting.labelImageFormat || 'jpg'}`,
          it,
          { base64: true, date: new Date(time) }
        )
        const labelName =
          // '标签图/' +
          imgFileName +
          '/' +
          firstLevelFileName +
          `/${dirname}/` +
          `${filename}.${ncSetting.labelImageFormat || 'jpg'}`
        labelImgList[j].push({
          name: labelName,
          url: it,
          stock_key: collectTagList[idx].stock_key,
          size: collectTagList[idx].size,
        })
        idx++
      })
    }
  } else {
    if (firstLevelFileName) {
      const folders = zip.folder(firstLevelFileName)
      const folder = folders?.folder(`${imgFileName}`)
      if (folder) currentZip = folder
    }
    collectTagList.forEach((item: any) => {
      const url = item.url.split(',')[1]

      currentZip.file(
        `${item.fileName}.${ncSetting.labelImageFormat || 'jpg'}`,
        url,
        { base64: true }
      )
      let labelName: string = _translateFileNameFn(
        '标签图',
        ncSetting.customizeFolder,
        'label_image_folder'
      )
      labelName = labelName ? labelName + '/' : ''
      const flagFolderName: string = !flag
        ? firstLevelFileName + `/${imgFileName}(${templateName})/`
        : ''
      let finalFilePath =
        labelName +
        flagFolderName +
        `${item.fileName}.${ncSetting.labelImageFormat || 'jpg'}`
      const {
        merge_folder_list,
        platePropertyMergeFile,
        merge_folder_display_name,
      } = ncSetting.customizeFolder
      if (
        merge_folder_list.length > 1 &&
        merge_folder_list.includes('贴标图片文件') &&
        platePropertyMergeFile
      ) {
        const path = dealTransFileName(item, false)
        finalFilePath = `${merge_folder_display_name}/${path}${item.fileName}.${
          ncSetting.labelImageFormat || 'jpg'
        }`
      }
      labelImgList[0].push({
        name: finalFilePath,
        url: url,
        stock_key: item.stock_key,
        size: item.size,
      })
    })
  }
  const data = await zip.generateAsync({ type: 'blob' })
  return {
    data,
    zip,
    labelImgList,
  }
}
// 处理
function prefixInteger(num: number, n: number) {
  return (Array(n).join('0') + num).slice(-n)
}
// 处理导出板件文件的名称
async function dealExportTagFileName(
  drawData: any,
  store: StoreType,
  ncFileList: any[],
  plantData: any[]
) {
  let ncFileArr = ncFileList
  const { ncSetting } = store.state
  const { type, plank_index, part } = drawData
  const separator = ncSetting.label_image_name_separator
  const ncCustomLabel = ncSetting.label_image_name_type ?? []
  let ncFileName = ''
  let labelFileName = ''
  let ncAutoIndex = ''
  if (
    (ncCustomLabel.some((type: any) =>
      ['labeling_filename', 'nc_filename', 'auto_index'].includes(type)
    ) ||
      ncSetting.label_img_big_plank_folder) &&
    !ncFileList.length
  ) {
    const paibanData = JSON.parse(JSON.stringify(plantData))
    paibanData.forEach((item: any) => {
      item.parts.sort((a: any, b: any) => a.priority - b.priority)
    })
    // TODO wc newNC调用两次的优化
    // store.state.newNCRes ??
    const nc = await httpRequest.post('new_nc', {
      uid: store.state.userInfo.id,
      data: JSON.stringify(changeDataStartPoint(paibanData)),
      id:
        ncSetting.process_setting_name == '默认生产线'
          ? -1
          : ncSetting.process_setting_id,
      keepKnifeOrigin: true,
      from: 'web',
      embedded: sessionStorage.getItem('embedded') ? 1 : 0,
      guigui_version: sessionStorage.getItem('guigui_version'),
      version: 'v1',
      longPlankCuttingSetting: ncSetting.longPlankCuttingSetting,
    })
    ncFileArr = nc.data
    if (nc.data.knife_error) {
      // 用于临时处理找不到刀错误
      EventBus.$emit(EventBusKey.KNIVES_ERR, nc.data.data, true)
      throw '找不到刀报错'
    }
    // 另外的刀相关的错误信息抛出在外面进行处理
    if (nc.status === 0) {
      EventBus.$emit(EventBusKey.OTHER_KNIVES_ERR, nc.msg)
      throw '刀错误信息'
    }
  }
  ncFileName = dealFileArrfunc(part, ncFileArr, 'nc')
  labelFileName = dealFileArrfunc(part, ncFileArr, 'label')
  ncAutoIndex = dealFileArrLabel(part, ncFileArr)
  const fileNameMap = new Map([
    ['address', part['address']],
    ['orderNo', part['orderNo']],
    ['nc_filename', ncFileName],
    ['labeling_filename', labelFileName],
    [
      'plankNum',
      ncSetting.genSimpleLabelBarcode ? part.simplePlankNum : part['plankNum'],
    ],
    ['auto_index', ncAutoIndex],
    ['stockNum', plank_index],
    ['priority', part['priority']],
    ['labelId', part['labelId']],
  ])
  let fileName = ''
  if (type === 'plank') {
    fileName = `${plank_index}`
  } else {
    fileName = ncCustomLabel.length
      ? ncCustomLabel.map((type: any) => fileNameMap.get(type)).join(separator)
      : `${part.lableImgName}${separator}${plank_index}${separator}${part['priority']}`
  }
  return { fileName, ncFileList: ncFileArr }
}
// 文件数组的处理
export function dealFileArrfunc(plank: any, fileList: any[], fileType: any) {
  const labelFiles = fileList
    .filter(
      (file) => file.filetype == fileType && file.stock_key == plank['stockKey']
    )
    .map((f) => {
      const nameArr = f.file_display_name.split('.')
      return nameArr.slice(0, nameArr.length - 1).join('.')
    })
  const ncFileName =
    labelFiles.length > 1
      ? labelFiles[0].endsWith('Z')
        ? labelFiles[1]
        : labelFiles[0]
      : labelFiles[0]
  return ncFileName
}
export function dealFileArrLabel(plank: any, fileList: any[]) {
  const ncAutoIndexList = Array.from(
    new Set(
      fileList
        .filter((file) => file.labeling_auto_index)
        .map((item) => {
          return {
            [item.stock_key]: item.labeling_auto_index,
          }
        })
    )
  )
  for (const item of ncAutoIndexList) {
    if (item[plank.stockKey]) {
      // 拿到的是数组
      return item[plank.stockKey][plank.stockKey][plank.priority - 1]
    }
  }
  return ''
}
// 处理导出余料板件文件的名称
function dealExportLaveTagFileName(drawData: any, store: StoreType) {
  const { plank, plank_index } = drawData
  const { ncSetting } = store.state
  const separator =
    ncSetting.label_picture_delimiter == 'underline'
      ? ncSetting.label_image_name_separator
      : ''
  const fileName = `${plank.lableImgName}${separator}${plank_index}`
  return fileName
}
function timer(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
// 将文件名称和文件url重新组合并收集
async function dealMergeFileNameOrUrl(
  fileName: string,
  collect: any[],
  ncSetting: any,
  stock_key?: string,
  size?: string
) {
  const idx = collect.length ? collect.length - 1 : 0
  let url = collect[idx]
  // 导出图片后缀和编码格式均为bmp时，支持位深1导出
  if (
    ncSetting.bmp_color_style &&
    ncSetting.labelImageFormat == 'bmp' &&
    ncSetting.labelImageFormatNew == 'bmp'
  ) {
    const newBase64 = await exportBmp1Bit(url)
    url = newBase64
  } else {
    if (ncSetting.labelImageFormatNew == 'bmp') {
      url = await convertJpegToBmp.convertJpegToBmp(url)
    } else {
      const oCanvas = cvs2image(url)
      await timer(20)
      const { width, height } = oCanvas
      const imgDom: any = canvas2Image.convertToImage(
        oCanvas,
        width,
        height,
        ncSetting.labelImageFormatNew
      )
      url = imgDom.src
    }
  }
  collect[idx] = {
    fileName,
    url,
    stock_key,
    size,
  }
}
// 生成文件 10
export async function generateTagFile(
  data: any[],
  template: any,
  store: StoreType,
  load: { isTag: boolean; isPdf: boolean } = { isTag: true, isPdf: true },
  lifeFunc: LifeFuncType = {},
  ncLifeFuncObj: any
) {
  try {
    // 周期函数
    const {
      loadSetting,
      loadPreferences,
      dealTemp,
      initialData,
      dealDrawData,
      genTagImg,
      genTagPdf,
      done,
    } = lifeFunc
    const { ncloadPreferences, ncdealTemp } = ncLifeFuncObj
    // eslint-disable-next-line prefer-const
    let { data: currentTemplate, name: templateName, render_url } = template
    if (!currentTemplate && !render_url) {
      done && done('end')
      return
    }
    currentTemplate && (currentTemplate = JSON.parse(currentTemplate))
    // 获取设置
    loadSetting && loadSetting('start')
    ncloadPreferences && ncloadPreferences('start')
    if (render_url) {
      render_url = render_url.replace(/http:/g, 'https:')
      currentTemplate = await axios
        .get(render_url)
        .then((result) => result.data)
        .catch(() => {
          done && done('end')
        })
    }
    const setting = await getFileSetting(store)
    loadSetting && loadSetting('end')
    ncloadPreferences && ncloadPreferences('end')
    // 获取偏好设置
    loadPreferences && loadPreferences('start')
    const preferencesSetting = await getPreferencesSetting()
    loadPreferences && loadPreferences('end')
    // 处理模板,获取文件后缀
    dealTemp && dealTemp('start')
    ncdealTemp && ncdealTemp('start')
    const { ncNameSuffix, ncOutputSetting } = await getEngravingSetting(
      store,
      currentTemplate
    )
    dealTemp && dealTemp('end')
    ncdealTemp && ncdealTemp('end')
    // 初始化数据
    initialData && initialData('start')
    let { data: sourceData } = initTagData(data, store, setting)
    // 初始化余料数据
    let laveData = initLaveData(sourceData, store)
    // 镜像处理
    if (preferencesSetting.jx_checked) {
      laveData = JxTagData(laveData)
      sourceData = JxTagData(sourceData)
    }
    // 默认数据
    const default_sort_source_data = sourceData
    // 自动上下料
    if (preferencesSetting.sort_value) {
      if (preferencesSetting.sort_value === 'default') {
        preferencesSetting.sort_value = 1
      }
      sourceData =
        preferencesSetting.sort_value === 1
          ? default_sort_source_data
          : (countTagDataByDir(
              preferencesSetting.sort_value,
              sourceData,
              store.state.ncSetting.xyReverse
            ).tagItemArr as any)
    }
    initialData && initialData('end')
    // 处理绘制数据
    dealDrawData && dealDrawData('start')
    const { tagListSourceData, bigTagList, all_page } = tagDrawPreDeal(
      sourceData,
      ncNameSuffix,
      setting,
      preferencesSetting,
      store.state.ncSetting,
      ncOutputSetting,
      preferencesSetting
    )
    const laveTagListSourceData = laveTagDrawPreDeal(laveData)
    dealDrawData && dealDrawData('end')
    let tagData = null
    let pdfData = null
    if (load.isTag) {
      genTagImg && genTagImg('start')
      tagData = await generateImageTag(
        tagListSourceData,
        laveTagListSourceData,
        currentTemplate,
        store,
        {
          ...preferencesSetting,
          scale: 2,
          all_page,
        },
        data
      )
      genTagImg && genTagImg('end')
    }
    if (load.isPdf) {
      genTagPdf && genTagPdf('start')
      pdfData = await generatePdfTag(
        currentTemplate,
        templateName,
        sourceData,
        tagListSourceData,
        laveData,
        store,
        preferencesSetting
      )
      genTagPdf && genTagPdf('end')
    }
    done && done('end')
    return {
      tagData,
      pdfData,
      tagListSourceData,
      laveTagListSourceData,
    }
  } catch (error) {
    console.error(error)
    return false
  }
}
function svgToBase64(svgString: string) {
  return `data:image/svg+xml;charset=utf-8,${encodeURI(svgString)}`
}
